import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Alert, Card, Col, Row, Skeleton, Space, Typography } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
const { Text, Title } = Typography;
import TicketList from 'components/ticket/TicketList/TicketList';
import TicketMap from 'components/ticket/TicketMap/TicketMap';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import useIsMobile from 'layouts/responsive/useIsMobile';
import {
  RetailerListItemFragmentDoc,
  RetailerPermissionsFullFragmentDoc,
  useGetRetailerPageQuery
} from 'generated/types';
import tabMessages from 'pages/matrix/tabMessages';
import { useIntl } from 'react-intl';
import { Category, categoryMessages } from 'layouts/matrix/category';
import RetailerBrandingCard from 'components/retailer/RetailerBrandingCard/RetailerBrandingCard';
import RetailerPvmSettingsCard from 'components/retailer/RetailerSettingsCard/RetailerPvmSettingsCard';
import Flag from 'components/i18n/Flag/Flag';
import RetailerBlockedPaymentCardsCard from 'components/retailer/RetailerBlockedPaymentCardsCard/RetailerBlockedPaymentCardsCard';
import useFormatTools from 'i18n/useFormatTools';
import RetailerLogo from 'components/retailer/RetailerLogo/RetailerLogo';
import RetailerMapCard from 'components/retailer/RetailerMapCard/RetailerMapCard';
import DataTransferList from 'components/datatransfer/DataTransferList';
import { useMatrixCategory } from 'layouts/matrix/useMatrixCategory';
import retailerMessages from 'components/retailer/retailerMessages';
import { isRetailerTab } from 'layouts/matrix/matrix';
import parseNumber from 'util/parseNumber';
import RetailerProductCard from 'components/retailer/RetailerProductCard/RetailerProductCard';
import commonMessages from 'components/i18n/commonMessages';
import ReDeployParameterButton from 'components/retailer/ReDeployParametersButton/ReDeployParameterButton.tsx';
import { gql } from '@apollo/client/core';
import LastPredictionCard from 'components/prediction/LastPredictionCard.tsx';
import OrderLineTool from 'components/ticket/TicketAccounting/OrderLineTool/OrderLineTool.tsx';
import EventList from 'components/events/EventList/EventList.tsx';
import { TypedPageTab } from 'components/lib/page/PageTabs/typedPageTab.ts';
import PageTab from 'components/lib/page/PageTabs/PageTab.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

const LogoAndTitleContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
`;

const LogoContainer = styled.div<{ $isMobile?: boolean }>`
  flex: 0 0 ${(props) => (props.$isMobile ? '32px' : '80px')};
  width: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
  height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.ant.colorText};
`;

const TitleAndLocationContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  && {
    .ant-typography {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const TitleMobile = styled.h2`
  margin-bottom: 0; // .5em default
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  padding-right: 8px;
`;

gql`
  query GetRetailerPage($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      ...RetailerListItem
      permissions {
        ...RetailerPermissionsFull
      }
    }
  }
  ${RetailerListItemFragmentDoc}
  ${RetailerPermissionsFullFragmentDoc}
`;

const RetailerPage: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { formatCountry } = useFormatTools();
  const isMobile = useIsMobile();
  const category = useMatrixCategory();

  const { retailerId: retailerIdRaw, tab: tabRaw } = useParams();
  const activeTab = tabRaw || 'operational';
  const retailerId = parseNumber(retailerIdRaw);
  const isValidRetailerId = retailerId !== undefined;
  const tab = isRetailerTab(activeTab) ? activeTab : undefined;
  const isValidRetailerTab = !!tab;

  const { data, error } = useGetRetailerPageQuery({
    variables: retailerId
      ? {
          retailerId: retailerId
        }
      : undefined,
    fetchPolicy: 'cache-first',
    skip: !retailerId
  });

  const { ticketDisplayMode } = useTicketDisplayMode();

  const handleTabChange = useCallback(
    (tabKey: string) => {
      navigate(`/retailer/${retailerId}/${tabKey === 'operational' ? '' : tabKey}`);
    },
    [navigate, retailerId]
  );

  if (!category || !isValidRetailerTab) {
    return <Navigate to={`/retailer/${retailerId}`} />;
  }

  const permissions = data?.retailer.permissions;

  const tabs: { [key in Category]: TypedPageTab[] } = {
    operational: [
      {
        key: 'operational',
        active: activeTab === 'operational',
        label: intl.formatMessage(categoryMessages.operational),
        children: (
          <PageTab>
            <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 16 }}>
              <Col xs={24} sm={{ span: 8, offset: 16 }}>
                <RetailerMapCard retailerId={retailerId} />
              </Col>
            </Row>
            {permissions?.canRedeployParameters && (
              <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
                <Col
                  xs={24}
                  xl={{
                    offset: 16,
                    span: 8
                  }}
                >
                  <Card title={intl.formatMessage(commonMessages.actions)}>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                      <ReDeployParameterButton
                        retailerId={retailerId}
                        canRedeployParameters={permissions.canRedeployParameters}
                      />
                    </Space>
                  </Card>
                </Col>
              </Row>
            )}
          </PageTab>
        )
      },
      {
        key: 'tickets',
        disabled: permissions && !permissions.tabs.operational.tickets,
        active: activeTab === 'tickets',
        label: intl.formatMessage(tabMessages.tickets),
        children: (
          <PageTab fullscreen={ticketDisplayMode === 'map'}>
            {ticketDisplayMode === 'list' && (
              <TicketList
                contextFilter={{ retailerId }}
                canCreateTicket={permissions?.canCreateTicket || false}
              />
            )}
            {ticketDisplayMode === 'map' && <TicketMap contextFilter={{ retailerId }} />}
            {ticketDisplayMode === 'order-lines' && (
              <OrderLineTool contextFilter={{ retailerId }} />
            )}
          </PageTab>
        )
      },
      {
        key: 'events',
        disabled: permissions && !permissions.tabs.operational.events,
        active: activeTab === 'events',
        label: intl.formatMessage(tabMessages.events),
        children: (
          <PageTab>
            {activeTab === 'events' && (
              <EventList parentScrollKey={'events'} contextFilter={{ retailerId }} />
            )}
          </PageTab>
        )
      },
      {
        key: 'properties',
        disabled: permissions && !permissions.tabs.operational.properties,
        active: activeTab === 'properties',
        label: intl.formatMessage(tabMessages.properties),
        children: (
          <PageTab>
            <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
              <Col xs={24} lg={16}>
                <Row gutter={[16, 16]} wrap={true}>
                  <Col xs={24}>
                    <RetailerBrandingCard retailerId={retailerId} />
                  </Col>
                  <Col xs={24}>
                    <RetailerBlockedPaymentCardsCard retailerId={retailerId} />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={8}>
                <RetailerPvmSettingsCard retailerId={retailerId} />
              </Col>
            </Row>
          </PageTab>
        )
      },
      {
        key: 'integrations',
        disabled: permissions && !permissions.tabs.operational.integrations,
        active: activeTab === 'integrations',
        label: intl.formatMessage(tabMessages.integrations),
        children: (
          <PageTab>
            <DataTransferList retailerId={retailerId} />
          </PageTab>
        )
      },
      {
        key: 'products',
        disabled: permissions && !permissions.tabs.operational.products,
        active: activeTab === 'products',
        label: intl.formatMessage(tabMessages.products),
        children: (
          <PageTab>
            <RetailerProductCard retailerId={retailerId} />
          </PageTab>
        )
      },
      {
        key: 'predictions',
        disabled: permissions && !permissions.tabs.operational.predictions,
        active: activeTab === 'predictions',
        label: intl.formatMessage(tabMessages.predictions),
        children: (
          <PageTab>
            <LastPredictionCard retailerId={retailerId} />{' '}
          </PageTab>
        )
      }
    ],
    commercial: [
      {
        key: 'commercial',
        active: activeTab === 'commercial',
        label: intl.formatMessage(categoryMessages.commercial),
        children: <PageTab>{''}</PageTab>
      }
    ],
    logistics: [
      {
        key: 'logistics',
        active: activeTab === 'logistics',
        label: intl.formatMessage(categoryMessages.logistics),
        children: <PageTab>{''}</PageTab>
      }
    ]
  };

  return (
    <Page
      showBreadcrumbs={true}
      title={{
        pageTitle: data?.retailer.name
      }}
      header={
        <LogoAndTitleContainer $isMobile={isMobile}>
          <LogoContainer $isMobile={isMobile}>
            <RetailerLogo width={isMobile ? 40 : 80} retailerId={retailerId} />
          </LogoContainer>
          <TitleAndLocationContainer>
            <Skeleton
              loading={!data}
              title={false}
              active={true}
              paragraph={{ rows: 1, style: { width: 350 } }}
            />

            {error && <Alert message={error.message} type={'error'} />}
            {!isValidRetailerId && (
              <Alert
                message={intl.formatMessage(retailerMessages.invalidRetailerId)}
                type={'error'}
              />
            )}

            {isMobile && <TitleMobile>{data?.retailer.name}</TitleMobile>}

            {!isMobile && (
              <Title style={{ marginBottom: 0, width: '100%' }} level={2}>
                {data?.retailer.name}
              </Title>
            )}

            {!isMobile && (
              <Space>
                {data && data.retailer.country.countryCode && (
                  <Flag country={data.retailer.country} />
                )}
                {data?.retailer.country.nameEnglish && (
                  <Text>{formatCountry(data.retailer.country.nameEnglish)}</Text>
                )}
              </Space>
            )}
          </TitleAndLocationContainer>
        </LogoAndTitleContainer>
      }
      tabs={{
        onChange: handleTabChange,
        defaultActiveKey: category,
        activeKey: activeTab,
        items: category ? tabs[category] : []
      }}
    />
  );
};

export default RetailerPage;
