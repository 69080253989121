import React, { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { Typography, Form, Button, Space, message, Switch } from 'antd';
import MyTimezoneSelect from 'components/i18n/TimezoneSelect/MyTimezoneSelect';
import { GlobalOutlined, LogoutOutlined } from '@ant-design/icons';
import {
  ThousandAndDecimalSeparator,
  useGetUserAndSettingsQuery,
  useUpdateMyGlobalTicketWatchMutation,
  useUpdateMyThousandAndDecimalSeparatorMutation
} from 'generated/types';
import { logout } from 'auth/authSlice';
import { useAppDispatch } from 'redux/store';
import MyLanguageSelect from 'components/user/LanguageSelect/LanguageSelect';
import ThemeSelect from 'components/user/ThemeSelect/ThemeSelect';
import Select from 'components/lib/Select/Select';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';
import { SelectOptionData } from 'components/lib/Select/selectTypes';
import { isArray } from 'lodash-es';
import Link from 'components/lib/Link/Link';
import commonMessages from 'components/i18n/commonMessages';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { appVersion } from 'common/appVersion.ts';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import Page from 'components/lib/page/Page/Page.tsx';
import PageContentRow from 'components/lib/page/rows/PageContentRow.tsx';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';

const messages = defineMessages({
  theme: {
    id: 'preferences_page.theme',
    defaultMessage: 'Theme'
  },
  language: {
    id: 'preferences_page.language',
    defaultMessage: 'Language'
  },
  timezone: {
    id: 'preferences_page.timezone',
    defaultMessage: 'Timezone'
  },
  exampleDate: {
    id: 'preferences_page.exampleDate',
    defaultMessage: 'Example date'
  },
  loggedInAs: {
    id: 'preferences_page.loggedInAs',
    defaultMessage: 'Logged in as {name}'
  },
  signOut: {
    id: 'preferences_page.signOut',
    defaultMessage: 'Sign out'
  },
  pageTitle: {
    id: 'preferences_page.pageTitle',
    defaultMessage: 'Preferences'
  },
  home: {
    id: 'preferences_page.home',
    defaultMessage: 'Home'
  },
  separatorDecimal: {
    id: 'preferences_page.separatorDecimal',
    defaultMessage: 'Number format'
  },
  updateSeparatorSuccessful: {
    id: 'preferences_page.updateDecimalSuccessful',
    defaultMessage: 'Updated successfully'
  },
  updateSeparatorFailed: {
    id: 'preferences_page.updateDecimalFailed',
    defaultMessage: 'Failed to update'
  },
  dot: {
    id: 'preferences_page.dot',
    defaultMessage: 'Dot'
  },
  comma: {
    id: 'preferences_page.comma',
    defaultMessage: 'Comma'
  },
  space: {
    id: 'preferences_page.space',
    defaultMessage: 'Space'
  },
  globalTicketWatch: {
    id: 'preferences_page.globalTicketWatch',
    defaultMessage: 'Watch all tickets'
  }
});

const PreferencesPage: React.FC = () => {
  const intl = useConnectIntl();
  const dispatch = useAppDispatch();
  const { screenMap: breakpoint } = useScreenInfo();

  const handleSignOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const { data, loading } = useGetUserAndSettingsQuery();

  const options = useEnumSelectOptions({
    enumObject: ThousandAndDecimalSeparator,
    keyPrefix: 'preferences_page.separator',
    customLabelMessages: {
      COMMA_AND_DOT: {
        id: 'preferences_page.separator.commaAndDot',
        defaultMessage: '1,000.00'
      },
      DOT_AND_COMMA: {
        id: 'preferences_page.separator.dotAndComma',
        defaultMessage: '1.000,00'
      },
      SPACE_AND_COMMA: {
        id: 'preferences_page.separator.spaceAndComma',
        defaultMessage: '1 000,00'
      },
      SPACE_AND_DOT: {
        id: 'preferences_page.separator.spaceAndDot',
        defaultMessage: '1 000.00'
      },
      NONE_AND_COMMA: {
        id: 'preferences_page.separator.noneAndComma',
        defaultMessage: '1000,00'
      },
      NONE_AND_DOT: {
        id: 'preferences_page.separator.noneAndDot',
        defaultMessage: '1000.00'
      }
    }
  });

  const [updateTicketWatch, { loading: savingTicketWatch }] =
    useUpdateMyGlobalTicketWatchMutation();

  const handleChangeTicketWatch = useCallback(
    async (checked: boolean) => {
      try {
        await updateTicketWatch({
          variables: {
            input: {
              watch: checked
            }
          }
        });
      } catch (e: unknown) {
        message.error(getFriendlyApolloErrorMessage(e));
      }
    },
    [updateTicketWatch]
  );

  const [updateSeparator, { loading: savingSeparator }] =
    useUpdateMyThousandAndDecimalSeparatorMutation();

  const handleChangeSeparator = useCallback(
    async (
      value: string,
      option:
        | SelectOptionData<ThousandAndDecimalSeparator>
        | SelectOptionData<ThousandAndDecimalSeparator>[]
    ) => {
      if (!data) {
        return;
      }

      if (isArray(option)) {
        throw new Error('Unexpected option type');
      }

      try {
        await updateSeparator({
          variables: {
            input: {
              separator: option.data
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateMyThousandAndDecimalSeparator: {
              __typename: 'UpdateMyThousandAndDecimalSeparatorPayload',
              user: {
                ...data.me,
                thousandAndCommaSeparator: option.data
              }
            }
          }
        });
        message.success(intl.formatMsg(messages.updateSeparatorSuccessful));
      } catch (e) {
        message.error(intl.formatMsg(messages.updateSeparatorFailed));
      }
    },
    [updateSeparator, intl, data]
  );

  return (
    <Page
      title={{
        pageTitle: intl.formatMsg(messages.pageTitle)
      }}
      showBreadcrumbs={[
        {
          key: 'root',
          title: (
            <Link to={'/'}>
              <Space>
                <GlobalOutlined />
                <div>{intl.formatMsg(commonMessages.global)}</div>
              </Space>
            </Link>
          )
        },
        {
          key: 'preferences',
          title: <Link to={'/preferences'}>{intl.formatMsg(messages.pageTitle)}</Link>
        }
      ]}
      header={intl.formatMsg(messages.pageTitle)}
    >
      <PageContentRow style={{ paddingTop: 16, paddingBottom: 64 }}>
        <ResponsiveListCard>
          <Form layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 8 }}>
            <Form.Item label={intl.formatMsg(messages.theme)}>
              <ThemeSelect />
            </Form.Item>
            <Form.Item label={intl.formatMsg(messages.language)}>
              <MyLanguageSelect />
            </Form.Item>
            <Form.Item label={intl.formatMsg(messages.timezone)}>
              <MyTimezoneSelect />
            </Form.Item>

            <Form.Item label={intl.formatMsg(messages.separatorDecimal)}>
              <Select
                options={options}
                value={data?.me.thousandAndCommaSeparator}
                onChange={handleChangeSeparator}
                loading={loading || savingSeparator}
              />
            </Form.Item>

            {data?.me.permissions.canSetGlobalTicketSubscription && (
              <Form.Item label={intl.formatMsg(messages.globalTicketWatch)}>
                <Switch
                  checked={data?.me.globalTicketWatch}
                  onChange={handleChangeTicketWatch}
                  loading={loading || savingTicketWatch}
                />
              </Form.Item>
            )}

            {!breakpoint.md && (
              <Form.Item
                label={intl.formatMsg(messages.loggedInAs, {
                  name: data?.me.name
                })}
              >
                <Button onClick={handleSignOut}>
                  <Space>
                    <LogoutOutlined />
                    <span>{intl.formatMsg(messages.signOut)}</span>
                  </Space>
                </Button>
              </Form.Item>
            )}

            {!breakpoint.md && (
              <Form.Item label={'Version'}>
                <Typography.Text>Connect v{appVersion.version}</Typography.Text>
              </Form.Item>
            )}
          </Form>
          <br />
          <br />
        </ResponsiveListCard>
      </PageContentRow>
    </Page>
  );
};

export default PreferencesPage;
