import { StreetViewService, useGoogleMap } from '@react-google-maps/api';
import React, { DragEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { StreetViewManIcon } from 'components/icons/Icons.tsx';
import styled from 'styled-components';

const DraggableButtonMan = styled.div<{ $isDragging: boolean }>`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  border-radius: 6px;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

interface Props {
  onDropStreetView?: (position: { lat: number; lng: number }) => void;
}

const DraggableStreetViewButton: React.FC<Props> = ({ onDropStreetView }) => {
  const map = useGoogleMap();
  if (!map) {
    throw new Error('DraggableStreetViewButton cannot be used outside the map');
  }

  const coverageLayer = useMemo(() => {
    return new google.maps.StreetViewCoverageLayer();
  }, []);

  const [isDragging, setIsDragging] = useState(false);

  const streetViewServiceRef = useRef<google.maps.StreetViewService | null>(null);

  // Create a reference to store overlay view
  const overlayViewRef = useRef<google.maps.OverlayView | null>(null);

  useEffect(() => {
    if (!map) return;

    const overlay = new google.maps.OverlayView();
    overlay.onAdd = () => {};
    overlay.draw = () => {};
    overlay.onRemove = () => {};

    overlay.setMap(map);
    overlayViewRef.current = overlay;

    return () => {
      overlay.setMap(null);
    };
  }, [map]);

  const handleDragEnd: DragEventHandler<unknown> = (event) => {
    if (
      onDropStreetView === undefined ||
      streetViewServiceRef.current === null ||
      overlayViewRef.current == null
    ) {
      return;
    }

    const projection = overlayViewRef.current.getProjection();
    const pixelPosition = new google.maps.Point(event.clientX, event.clientY);
    const latLng = projection.fromContainerPixelToLatLng(pixelPosition)?.toJSON();

    if (latLng === null) {
      return;
    }

    coverageLayer.setMap(null);
    setIsDragging(false);

    streetViewServiceRef.current.getPanorama({ location: latLng, radius: 50 }, (data, status) => {
      console.log('drop result', data, status);
      if (status === google.maps.StreetViewStatus.OK && latLng) {
        onDropStreetView?.(latLng);
      } else {
        console.log('No Street View available at this location.');
      }
    });
  };

  return (
    <>
      <DraggableButtonMan
        draggable={true}
        onDragStart={() => {
          setIsDragging(true);
          coverageLayer.setMap(map);
        }}
        onDragEnd={handleDragEnd}
        $isDragging={isDragging}
      >
        <StreetViewManIcon />
      </DraggableButtonMan>
      <StreetViewService
        onLoad={(service) => {
          streetViewServiceRef.current = service;
        }}
      />
    </>
  );
};
export default DraggableStreetViewButton;
