import React, { CSSProperties } from 'react';
import { EventLevel } from 'generated/types.tsx';
import Tag from 'components/lib/Tag/Tag.tsx';
import styled, { css } from 'styled-components';

const StyledTag = styled(Tag)<{ $mute: boolean }>`
  ${(props) =>
    props.$mute &&
    css`
      && {
        .anticon {
          color: ${(props) => props.theme.ant.colorTextSecondary};
        }
      }
    `}
`;

interface Props {
  level: EventLevel;
  style?: CSSProperties;
  mute?: boolean;
}

const EventLevelTag: React.FC<Props> = ({ level, style, mute }) => {
  return (
    <StyledTag bordered={true} style={style} $mute={mute || false}>
      {level}
    </StyledTag>
  );
};

export default EventLevelTag;
