import React from 'react';
import { theme } from 'antd';
const { useToken } = theme;

const Rainbow = () => {
  const { token } = useToken(); // This is the complete token after theme algorithm is run

  const presetColors: { [key: string]: string } = {};
  const colors: { [key: string]: string } = {};
  const other: { [key: string]: string } = {};

  // eslint-disable-next-line
  const hack: any = token;

  Object.keys(token).forEach((key) => {
    const isColor = key.indexOf('color') > -1;

    const isPreset = typeof hack[key] === 'string' && hack[key].indexOf('#') > -1;
    if (isColor) {
      colors[key] = hack[key];
    } else if (isPreset) {
      presetColors[key] = hack[key];
    } else {
      other[key] = hack[key];
    }
  });

  return (
    <div style={{ padding: 16, overflowY: 'auto' }}>
      {Object.keys(colors).map((key) => {
        return (
          <div
            key={key}
            style={{
              backgroundColor: hack[key],
              padding: '2px'
            }}
          >
            {key}: {hack[key]}
          </div>
        );
      })}
    </div>
  );
};

export default Rainbow;
