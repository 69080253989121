import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { gql } from '@apollo/client/core';
import {
  EventFilterInput,
  EventListItemFragmentDoc,
  EventSortField,
  FullPageInfoFragmentDoc,
  GetEventListQuery,
  GetEventListQueryVariables,
  OnEventCreatedDocument,
  OnEventCreatedSubscription,
  OnEventCreatedSubscriptionVariables,
  SortDirection,
  useGetEventListQuery
} from 'generated/types.tsx';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import EventListFilter from 'components/events/EventList/EventListFilter/EventListFilter.tsx';
import useEventListFilter from 'components/events/EventList/EventListFilter/useEventListFilter.ts';
import VirtualList from 'components/lib/List/VirtualList.tsx';
import ErrorResult from 'components/lib/ErrorResult/ErrorResult.tsx';
import { parseISO } from 'date-fns';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import EventListItem from 'components/events/EventList/EventListItem.tsx';
import getEventLevelBackground from 'components/events/EventList/getEventLevelBackground.ts';
import { EventListItemData } from 'components/events/EventList/eventListItemData.ts';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import getMachineIcon from 'components/icons/getMachineIcon.tsx';
import getEventLevelIcon from 'components/events/getEventLevelIcon.tsx';
import useOnWebsocketReconnected from 'components/lib/List/useOnWebsocketReconnected.ts';
import useOnTabRefocused from 'components/lib/List/useOnTabRefocused.ts';

gql`
  fragment EventListItem on Event {
    entityId
    eventTypeId
    level
    timestamp
    i18nKey
    mute
    machine {
      ...MachineListItem
      retailer {
        ...RetailerListItem
      }
    }
  }
`;

gql`
  query GetEventList(
    $cursor: String
    $sortField: EventSortField!
    $sortDirection: SortDirection!
    $filter: EventFilterInput!
  ) {
    allEvents(
      after: $cursor
      first: 50
      sortField: $sortField
      sortDirection: $sortDirection
      filter: $filter
    ) {
      edges {
        node {
          ...EventListItem
        }
        cursor
      }
      pageInfo {
        ...FullPageInfo
      }
    }
  }
  ${EventListItemFragmentDoc}
  ${FullPageInfoFragmentDoc}
`;

gql`
  subscription OnEventCreated($machineId: Int, $retailerId: Int, $filter: EventFilterInput!) {
    onEventCreated(machineId: $machineId, retailerId: $retailerId, filter: $filter) {
      ...EventListItem
    }
  }
`;

type EventListContextFilter = Pick<EventFilterInput, 'retailerId' | 'machineId'>;

interface Props {
  contextFilter?: EventListContextFilter;
  parentScrollKey: string;
}

const EventList: React.FC<Props> = ({ contextFilter, parentScrollKey }) => {
  const isMobile = useIsMobile();
  const intl = useConnectIntl();
  const { formatDate } = useDateFormatTools();
  const { getUrlToRetailer, getUrlToMachine } = useMatrixNav();
  const { eventFilter } = useEventListFilter();
  const variables = useMemo<GetEventListQueryVariables>(() => {
    return {
      cursor: undefined,
      sortDirection: SortDirection.Descending,
      sortField: EventSortField.Timestamp,
      filter: {
        ...contextFilter,
        ...eventFilter
      }
    };
  }, [contextFilter, eventFilter]);

  const { data, fetchMore, loading, error, subscribeToMore, refetch } = useGetEventListQuery({
    variables
  });
  const [fetchMoreError, setFetchMoreError] = useState<Error | undefined>(undefined); // Local error state for fetchMore

  const [live, setLive] = useState(true);

  const handleChangeLive = useCallback(
    (value: boolean) => {
      setLive(value);
      if (value) {
        refetch();
      }
    },
    [refetch]
  );

  useOnWebsocketReconnected({
    callback: refetch,
    skip: !data || loading
  });

  const { isFocused } = useOnTabRefocused(refetch);

  useEffect(() => {
    if (!live) {
      return;
    }

    if (!isFocused) {
      console.log('tab lost focus, pause subscription');
      return;
    }

    console.log('subscribing to more data!');

    return subscribeToMore<OnEventCreatedSubscription, OnEventCreatedSubscriptionVariables>({
      document: OnEventCreatedDocument,
      variables: {
        machineId: contextFilter?.machineId,
        retailerId: contextFilter?.retailerId,
        filter: eventFilter
      },
      onError: (error) => {
        console.log('error in subscribe to more', error);
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        if (!prev.allEvents || !prev.allEvents.pageInfo) {
          return prev;
        }

        const incomingEvent = subscriptionData.data.onEventCreated;
        const updatedEdges = [
          {
            node: incomingEvent,
            __typename: 'AllEventsEdge' as const,
            cursor: incomingEvent.entityId // NOTE: mini hack, we don't have a cursor available
          },
          ...(prev.allEvents?.edges || [])
        ];
        const sortedEdges = updatedEdges.sort((a, b) => {
          const dateA = parseISO(a.node.timestamp);
          const dateB = parseISO(b.node.timestamp);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        });

        const temp: GetEventListQuery = {
          allEvents: {
            __typename: 'AllEventsConnection',
            pageInfo: prev.allEvents.pageInfo,
            edges: sortedEdges
          }
        };
        return temp;
      }
    });
  }, [
    contextFilter?.machineId,
    contextFilter?.retailerId,
    eventFilter,
    eventFilter.eventLevel,
    isFocused,
    live,
    subscribeToMore
  ]);

  const pageInfo = data?.allEvents?.pageInfo;
  const hasNextPage = pageInfo?.hasNextPage;
  const endCursor = pageInfo?.endCursor;

  // NOTE: We moved "heavy" calculation to this memoized function outside the ListItem component
  const events: EventListItemData[] | undefined = useMemo(() => {
    if (!data || !data.allEvents || !data.allEvents.edges) {
      return undefined;
    }

    const mapped = data.allEvents.edges?.map((edge) => {
      const title = edge.node.i18nKey ? intl.formatMsg({ id: edge.node.i18nKey }) : '';
      const parsedTimestamp = parseISO(edge.node.timestamp);
      const item: EventListItemData = {
        entityId: edge.node.entityId,
        title: `${edge.node.eventTypeId} ${title}`,
        background: getEventLevelBackground(edge.node.level, edge.node.mute),
        level: edge.node.level,
        icon: getEventLevelIcon(edge.node.level),
        muted: edge.node.mute,
        formattedDate: formatDate(parsedTimestamp, { representation: 'complete' }),
        timestamp: parsedTimestamp,
        retailerLink:
          edge.node.machine?.retailer && !contextFilter?.retailerId && !contextFilter?.machineId
            ? {
                href: getUrlToRetailer(edge.node.machine.retailer.retailerId),
                text: edge.node.machine.retailer.name
              }
            : undefined,
        machineLink:
          edge.node.machine && !contextFilter?.machineId
            ? {
                href: getUrlToMachine(edge.node.machine.machineId),
                text: edge.node.machine.location
                  ? `${edge.node.machine.location} (${edge.node.machine.serialNo})`
                  : edge.node.machine.serialNo,
                icon: getMachineIcon(edge.node.machine.machineType)
              }
            : undefined
      };
      return item;
    });

    return mapped.sort((a, b) => {
      return a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0;
    });
  }, [
    contextFilter?.machineId,
    contextFilter?.retailerId,
    data,
    formatDate,
    getUrlToMachine,
    getUrlToRetailer,
    intl
  ]);

  const loadMoreItems = useCallback(async () => {
    if (hasNextPage) {
      try {
        await fetchMore({
          variables: {
            cursor: endCursor, // NOTE: We just fetch the next page, no need to look up cursor by index
            sortDirection: SortDirection.Descending,
            sortField: EventSortField.Timestamp,
            filter: {
              ...contextFilter,
              ...eventFilter
            }
          }
        });
        setFetchMoreError(undefined);
      } catch (err) {
        if (err instanceof Error) {
          setFetchMoreError(err);
        }
      }
    }
  }, [contextFilter, endCursor, eventFilter, fetchMore, hasNextPage]);

  const [expandedItems, setExpandedItems] = useState(new Set<string>());

  const handleToggleExpanded = useCallback((entityId: string) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = new Set(prevExpandedItems);
      if (newExpandedItems.has(entityId)) {
        newExpandedItems.delete(entityId);
      } else {
        newExpandedItems.add(entityId);
      }
      return newExpandedItems;
    });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} style={{ flexShrink: 0 }} wrap={true} align={'stretch'}>
        <Col xs={24}>
          <ResponsiveListCard>
            <EventListFilter live={live} onChangeLive={handleChangeLive} />
          </ResponsiveListCard>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{ flexGrow: 1, marginTop: 16, marginBottom: isMobile ? 0 : 16 }}
      >
        <Col xs={24}>
          <ResponsiveListCard
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 162
            }}
            styles={{
              body: {
                flex: '1 1 auto'
              }
            }}
            noPaddingDesktop={true}
            noPaddingMobile={true}
          >
            {error && <ErrorResult error={error} />}
            <VirtualList<EventListItemData>
              parentScrollKey={parentScrollKey}
              style={{ height: '100%' }}
              data={events}
              loading={loading}
              renderItem={(index, item) => {
                return (
                  <EventListItem
                    index={index}
                    item={item}
                    expanded={expandedItems.has(item.entityId)}
                    onToggleExpanded={() => handleToggleExpanded(item.entityId)}
                  />
                );
              }}
              // this can be used to decide when to render something else when scrolling fast
              // scrollSeekConfiguration={{
              //   enter: (velocity) => velocity > 50,
              //   exit: (velocity) => velocity < 50
              // }}
              onLoadMoreData={loadMoreItems}
              showSeparatorLines={true}
              addPadding={false}
              increaseViewportBy={500} // 200px from example in docs, not sure what works best
              overscan={20}
            />
            {fetchMoreError && (
              <ErrorResult
                error={fetchMoreError}
                title={'Failed to load more events'}
                action={
                  <Button type={'primary'} onClick={loadMoreItems}>
                    Retry
                  </Button>
                }
              />
            )}
          </ResponsiveListCard>
        </Col>
      </Row>
    </>
  );
};
export default EventList;
