import React from 'react';
import { EventItemDetailsFragmentDoc, useGetEventQuery } from 'generated/types.tsx';
import { gql } from '@apollo/client/core';
import styled from 'styled-components';
import { Typography, Button, Descriptions, Flex } from 'antd';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import JsonViewer from 'components/lib/JsonViewer/JsonViewer.tsx';
import Alert from 'components/lib/Alert/Alert.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import commonMessages from 'components/i18n/commonMessages.ts';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';
import useFormatTools from 'i18n/useFormatTools.ts';
import TraceViewer from 'components/lib/TraceViewer/TraceViewer.tsx';
import { DescriptionsItemType } from 'antd/es/descriptions';

gql`
  fragment EventItemDetails on Event {
    entityId
    eventTypeId
    timestampReceived
    valuesRaw
    trace
    mute
    sessionId
    byUser {
      ...UserListItem
      ...DeletedUserListItem
    }
  }
`;

gql`
  query GetEvent($id: UUID!) {
    event(id: $id) {
      ...EventItemDetails
    }
  }
  ${EventItemDetailsFragmentDoc}
`;

interface Props {
  eventId: string;
}

const EventListItemDetails: React.FC<Props> = ({ eventId }) => {
  const { data, loading, error, refetch } = useGetEventQuery({
    variables: {
      id: eventId
    },
    fetchPolicy: 'cache-first'
  });

  const intl = useConnectIntl();
  const { formatDate } = useDateFormatTools();
  const { formatUserName } = useFormatTools();

  if (loading) {
    return (
      <Flex justify={'center'} style={{ paddingTop: 64, paddingBottom: 64 }}>
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    const friendlyErrorMessages = error ? getFriendlyApolloErrorMessages(error) : undefined;

    return (
      <Alert
        type={'error'}
        message={
          <Flex vertical={true} gap={2}>
            {friendlyErrorMessages?.map((line) => <div key={line}>{line}</div>)}
          </Flex>
        }
        banner={true}
        showIcon={true}
        action={
          <Button type={'dashed'} onClick={() => refetch({ id: eventId })}>
            {intl.formatMsg(commonMessages.retry)}
          </Button>
        }
      />
    );
  }

  const items: Array<DescriptionsItemType | undefined> = [
    {
      key: 'identifier',
      label: 'Identifier',
      children: <Typography.Text>{data?.event.entityId}</Typography.Text>
    },
    data?.event.byUser
      ? {
          key: 'user',
          label: 'User',
          children: data.event.byUser && (
            <Flex gap={4} align={'center'}>
              <Typography.Text>
                {formatUserName(data.event.byUser, { capitalize: true, includeEmail: true })}
              </Typography.Text>
            </Flex>
          )
        }
      : undefined,
    {
      key: 'processed',
      label: 'Processed',
      children: data?.event.timestampReceived
        ? formatDate(parseISO(data?.event.timestampReceived), { representation: 'complete' })
        : null
    },
    data?.event.sessionId
      ? {
          key: 'session',
          label: 'Session',
          span: 1,
          children: (
            <>
              {data?.event.sessionId && <Typography.Text>{data.event.sessionId}</Typography.Text>}
            </>
          )
        }
      : undefined,
    data?.event.valuesRaw
      ? {
          key: 'value',
          label: 'Value',
          span: { xs: 1, lg: 2 },
          children: data.event.valuesRaw && (
            <JsonContainer>
              <JsonViewer
                json={data.event.valuesRaw}
                fallbackRender={(invalidJson) => <Typography.Text>{invalidJson}</Typography.Text>}
              />
            </JsonContainer>
          )
        }
      : undefined,
    data?.event.trace
      ? {
          key: 'trace',
          label: 'Trace',
          span: { xs: 1, lg: 2 },
          children: <TraceViewer data={data.event.trace} />
        }
      : undefined
  ];
  const items2 = items.filter((item): item is DescriptionsItemType => item !== undefined);

  return (
    <Container>
      {data && (
        <Descriptions
          bordered={true}
          column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
          layout={'horizontal'}
          size={'small'}
          items={items2}
        />
      )}
    </Container>
  );
};

// NOTE: Memo here improves performance by preventing unnecessary re-renders (tested)
export default React.memo(EventListItemDetails);

const JsonContainer = styled.div`
  font-size: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-top: 12px;
  padding-bottom: 12px;

  && {
    .ant-descriptions-view {
      max-width: 100%;
      overflow: auto;
    }
  }

  // horizontal scroll when needed
  overflow-x: auto;
`;
