import React, { Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MachineNav from 'layouts/components/MachineNav/MachineNav';
import MainNav from 'layouts/MainLayout/MainNav/MainNav';
import FooterMobile from 'layouts/MainLayout/FooterMobile/FooterMobile';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useIsInMatrix } from 'layouts/matrix/useIsInMatrix';
import * as Sentry from '@sentry/react';
import ScopeDrawerMobile from 'layouts/MainLayout/ScopeDrawerMobile/ScopeDrawerMobile';
import fallbackRender from 'layouts/components/fallbackRender.tsx';
import Fallback from 'components/lib/Fallback/Fallback.tsx';
import OfflineOverlay from 'layouts/components/OfflineOverlay/OfflineOverlay.tsx';
import HeaderMobile from 'layouts/MainLayout/HeaderMobile/HeaderMobile.tsx';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo.ts';
import { useScrollContext } from 'layouts/MainLayout/ScrollContext.tsx';
import ScrollToTop from 'layouts/components/ScrollToTop/ScrollToTop.tsx';

const RootContainer = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${(props) => props.theme.ant.colorBgLayout};
  overflow: hidden; // no scroll here
`;

const Content = styled.div<{ $isInMatrix: boolean; $isMobile: boolean }>`
  margin-left: ${(props) =>
    props.$isMobile
      ? 0
      : props.$isInMatrix
      ? `calc(${props.theme.connect.layout.mainNavWidth} + ${props.theme.connect.layout.machineNavWidth})`
      : props.theme.connect.layout.mainNavWidth};

  z-index: 1;
  position: relative;
  box-sizing: border-box;

  // TODO: These don't apply to mobile..
  min-height: 100vh;
  height: 100%;

  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  background: ${(props) => props.theme.ant.colorBgContainer};
`;

// On mobile this is the scrollable area
const ScrollContainerMobile = styled.div<{ $isInMatrix: boolean }>`
  margin-left: 0; // mobile, no menus on the left
  margin-bottom: ${(props) => props.theme.connect.layout.footerMobileHeight};
  margin-top: ${(props) => props.theme.connect.layout.headerMobileHeight};
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  height: calc(
    100vh - ${(props) => props.theme.connect.layout.footerMobileHeight} -
      ${(props) => props.theme.connect.layout.headerMobileHeight}
  );

  overflow-x: hidden;
  overflow-y: auto;

  @media only print {
    overflow: visible;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const MainLayout: React.FC = () => {
  const isInMatrix = useIsInMatrix();
  const { isDesktop, isMobile } = useScreenInfo();
  const { setScrollRef } = useScrollContext();
  const mobileRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isMobile && mobileRef.current) {
      setScrollRef(mobileRef.current, 'mobile');
    }
  }, [setScrollRef, isMobile]);

  return (
    <RootContainer>
      <OfflineOverlay />
      <ScrollRestoration />
      {isMobile && <HeaderMobile />}
      {isDesktop && (
        <>
          <MainNav />
          <MachineNav />
        </>
      )}
      <Content $isInMatrix={isInMatrix} $isMobile={isMobile}>
        <Sentry.ErrorBoundary showDialog={false} fallback={fallbackRender}>
          <Suspense fallback={<Fallback />}>
            {isMobile && (
              <ScrollContainerMobile $isInMatrix={isInMatrix} ref={mobileRef}>
                <ScrollToTop />
                <Outlet />
              </ScrollContainerMobile>
            )}
            {isDesktop && <Outlet />}
          </Suspense>
        </Sentry.ErrorBoundary>
      </Content>

      {isMobile && (
        <>
          <FooterMobile />
          <ScopeDrawerMobile />
        </>
      )}
    </RootContainer>
  );
};

export default MainLayout;
