import React, { CSSProperties, Suspense } from 'react';
import styled from 'styled-components';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import Fallback from 'components/lib/Fallback/Fallback.tsx';
import ScrollToTop from 'layouts/components/ScrollToTop/ScrollToTop.tsx';
import { pagePadding } from 'components/lib/page/pagePadding.ts';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';

const PaddedContainer = styled.div<{ $breakpoint: ScreenMap }>`
  ${pagePadding};

  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const FullWidthContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

interface Props {
  children: React.ReactNode;
  fullscreen?: boolean;
  style?: CSSProperties;
}

/**
 * PageTab is just a simple div with padding and 100% height
 * for use with our PageTabs component
 * @param children
 * @param fullscreen
 * @param style
 * @constructor
 */
const PageTab: React.FC<Props> = ({ children, fullscreen, style }) => {
  const { screenMap: breakpoint, isDesktop } = useScreenInfo();

  const container = fullscreen ? (
    <FullWidthContainer style={style}>
      {isDesktop && <ScrollToTop />}
      {children}
    </FullWidthContainer>
  ) : (
    <PaddedContainer style={style} $breakpoint={breakpoint}>
      {isDesktop && <ScrollToTop />}
      {children}
    </PaddedContainer>
  );
  return <Suspense fallback={<Fallback />}>{container}</Suspense>;
};

export default PageTab;
