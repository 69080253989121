import { TicketSeverity } from 'generated/types';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ticketSeverityMessages } from 'components/ticket/TicketSeverity/ticketSeverityMessages';
import severityColors from 'components/ticket/TicketSeverity/severityColors';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const CustomTag = styled.div<{ $severity: TicketSeverity }>`
  padding: 0 8px;
  font-size: 12px;
  line-height: 24px;
  border-radius: 16px;
  border: 0;
  white-space: nowrap;
  height: 24px;
  ${severityColors}
`;

interface Props {
  severity: TicketSeverity;
}

type OtherDivProps = HTMLAttributes<HTMLDivElement>;

const TicketSeverityTag: React.FC<Props & OtherDivProps> = (props) => {
  const intl = useConnectIntl();
  const { severity, ...rest } = props;
  const text = severity ? intl.formatMsg(ticketSeverityMessages[severity]) : null;

  return (
    <CustomTag $severity={severity} {...rest}>
      {severity}
      {' - '}
      {text}
    </CustomTag>
  );
};

export default TicketSeverityTag;
