import React, { forwardRef, useCallback } from 'react';
import { gql } from '@apollo/client';
import NotSetValue from 'components/machine/properties/MachinePropertyList/NotSetValue.tsx';
import { Switch, Tooltip } from 'antd';
import {
  MachinePropertyWithValueFragment,
  useGetMachinePropertyHistoryQuery,
  useSetMachinePropertyBooleanValueTestMutation
} from 'generated/types.tsx';
import { useIntl } from 'react-intl';
import machinePropertyMessages from 'components/machine/properties/MachinePropertyList/machinePropertyMessages.ts';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useMessageApi from 'components/global/useMessageApi.ts';
import { useMachinePermissions } from 'auth/RequireMachineAccess.tsx';

gql`
  mutation SetMachinePropertyBooleanValueTest($input: SetBooleanInput!) {
    setMachinePropertyValue(input: { booleanValue: $input }) {
      id
      key
      readOnly
      machinePropertyUpdateType
      group
      machineSerialNo
      machineId
      discriminator
      dataType
      value {
        ...BooleanPropertyValueFull
      }
      valueHistory {
        ...BooleanPropertyValueFull
      }
      machine {
        id
        machineId
        notificationMute # Quick fix, include this to make the cache update (we could include it only for the mute prop)
      }
    }
  }
`;

export interface BooleanMachinePropertyValueEditor {
  enableAndSetToTrue: () => void;
}
interface Props {
  property: MachinePropertyWithValueFragment;
  value: boolean | null | undefined;
}

const BooleanValue = forwardRef<BooleanMachinePropertyValueEditor, Props>(
  ({ property, value }, ref) => {
    const intl = useIntl();
    const messageApi = useMessageApi();
    const { canEditDetails } = useMachinePermissions();

    const [setValue, { loading: saving }] = useSetMachinePropertyBooleanValueTestMutation();

    const { data } = useGetMachinePropertyHistoryQuery({
      variables: {
        machineId: property.machineId,
        serialNo: property.machineSerialNo,
        key: property.key
      },
      fetchPolicy: 'cache-only'
    });

    React.useImperativeHandle(
      ref,
      () => ({
        enableAndSetToTrue: async () => {
          try {
            await setValue({
              variables: {
                input: {
                  value: true,
                  key: property.key,
                  machineId: property.machineId,
                  serialNo: property.machineSerialNo
                }
              }
            });
          } catch (err: unknown) {
            messageApi.error(
              getFriendlyApolloErrorMessage(
                err,
                'Could not enable and set machine property to True. '
              )
            );
          }
        }
      }),
      [setValue, property.key, property.machineId, property.machineSerialNo, messageApi]
    );

    const save = useCallback(
      async (value: boolean) => {
        try {
          const myHistory =
            data?.machineProperty.valueHistory.filter(
              (c) => c.__typename === 'BooleanPropertyValue'
            ) || [];
          await setValue({
            variables: {
              input: {
                machineId: property.machineId,
                serialNo: property.machineSerialNo,
                key: property.key,
                value: value
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setMachinePropertyValue: {
                ...property,
                __typename: 'MachineProperty',
                key: property.key,
                machineId: property.machineId,
                machineSerialNo: property.machineSerialNo,
                valueHistory: [
                  {
                    __typename: 'BooleanPropertyValue',
                    booleanValue: value,
                    active: true,
                    id: 'temp-id-value',
                    changed: new Date().toISOString(),
                    changedByUser: null // This will make the value update optimistically, and the user will pop up when saved to server
                  },
                  ...myHistory
                ],
                value: {
                  __typename: 'BooleanPropertyValue',
                  booleanValue: value,
                  active: true,
                  id: 'temp-id-value',
                  changed: new Date().toISOString(),
                  changedByUser: null
                },
                machine: {
                  __typename: undefined,
                  id: '',
                  machineId: 0,
                  notificationMute: false
                }
              }
            }
          });
        } catch (err) {
          messageApi.error(
            getFriendlyApolloErrorMessage(err, 'Could not set machine property value. ')
          );
        }
      },
      [data?.machineProperty.valueHistory, setValue, property, messageApi]
    );

    if (value === null || value === undefined) {
      return <NotSetValue />;
    }

    const content = (
      <div>
        <Switch
          checked={value || false}
          disabled={property.readOnly || !canEditDetails}
          loading={saving}
          style={{ marginTop: '4px', marginBottom: '8px' }}
          onChange={(checked) => save(checked)}
        />
      </div>
    );

    return property.readOnly ? (
      <Tooltip title={intl.formatMessage(machinePropertyMessages.readonly)} placement={'topLeft'}>
        {content}
      </Tooltip>
    ) : !canEditDetails ? (
      <Tooltip
        title={intl.formatMessage(machinePropertyMessages.notAuthorized)}
        placement={'bottomLeft'}
      >
        {content}
      </Tooltip>
    ) : (
      content
    );
  }
);

BooleanValue.displayName = 'BooleanValue';

export default BooleanValue;
