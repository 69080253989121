import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { Button, Space } from 'antd';
import {
  AimOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import useMessageApi from 'components/global/useMessageApi';
import DraggableStreetViewButton from 'components/lib/ConnectMap/DraggableStreetViewButton.tsx';

export interface MapBasicControlsSettings {
  mapType: boolean;
  fullscreen: boolean;
  locate: boolean;
  streetView: boolean;
  zoom: boolean;
}

const defaultBasicControls: MapBasicControlsSettings = {
  fullscreen: true,
  locate: true,
  streetView: true,
  mapType: true,
  zoom: true
};

interface Props {
  controls?: Partial<MapBasicControlsSettings>;
  onEnterFullscreen?: () => void;
  onExitFullscreen?: () => void;
  onPositionFound?: (position: GeolocationPosition) => void;
  onDropStreetView?: (position: { lat: number; lng: number }) => void;
}

const MapBasicControls: React.FC<Props> = (props) => {
  const { controls, onEnterFullscreen, onExitFullscreen, onPositionFound, onDropStreetView } =
    props;
  const map = useGoogleMap();
  if (map === null) {
    throw new Error('Cannot be used outside a google map context');
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  const intl = useIntl();
  const message = useMessageApi();

  const ctrl: MapBasicControlsSettings = {
    ...defaultBasicControls,
    ...controls
  };

  useEffect(() => {
    const element = map.getDiv();
    const handleFull = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
        onEnterFullscreen?.();
      } else {
        setIsFullscreen(false);
        onExitFullscreen?.();
      }
    };
    element.addEventListener('fullscreenchange', handleFull);
    return () => {
      element.removeEventListener('fullscreenchange', handleFull);
    };
  }, [map, onEnterFullscreen, onExitFullscreen]);

  const handleZoomIn = () => {
    const currentZoom = map.getZoom() || 4;
    map.setZoom(currentZoom + 1);
  };

  const handleZoomOut = () => {
    const currentZoom = map.getZoom() || 4;
    map.setZoom(currentZoom - 1);
  };

  const handleUserLocated = useCallback(
    (position: GeolocationPosition) => {
      onPositionFound?.(position);
      // TODO: make configurable?
      map?.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    },
    [onPositionFound, map]
  );

  const handleGetLocation = async () => {
    message.loading({
      key: 'locate',
      content: intl.formatMessage({
        id: 'map_basic_controls.locate',
        defaultMessage: 'Finding your position...'
      })
    });

    window.navigator?.geolocation?.getCurrentPosition(
      (position) => {
        message.success({
          key: 'locate',
          content: intl.formatMessage({
            id: 'map_basic_controls.locate_success',
            defaultMessage: 'Position found'
          })
        });
        handleUserLocated(position);
      },
      async (positionError) => {
        console.error(positionError);
        message.error({
          key: 'locate',
          content: intl.formatMessage({
            id: 'map_basic_controls.locate_fail',
            defaultMessage:
              'Could not get your position. Please check your browser settings and try again.'
          })
        });
      },
      {
        enableHighAccuracy: true, // ok? I think this is GPS on mobile... can be slow?
        timeout: 7000
      }
    );
  };

  const handleToggleFullscreen = async () => {
    const element = map.getDiv();
    if (isFullscreen) {
      await document.exitFullscreen();
    } else {
      await element.requestFullscreen();
    }
  };

  return (
    <>
      <Space size={'small'} direction={'vertical'}>
        {ctrl.fullscreen && (
          <Button
            icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            onClick={handleToggleFullscreen}
          />
        )}
        {ctrl.locate && <Button icon={<AimOutlined />} onClick={handleGetLocation} />}
        {ctrl.streetView && <DraggableStreetViewButton onDropStreetView={onDropStreetView} />}
        {ctrl.zoom && <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} />}
        {ctrl.zoom && <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut} />}
      </Space>
    </>
  );
};

export default MapBasicControls;
