import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from 'components/lib/Card/Card';
import ConnectMap, { defaultCenter } from 'components/lib/ConnectMap/ConnectMap';
import { gql } from '@apollo/client';
import {
  CountryListItemFragmentDoc,
  FullLanguageFragmentDoc,
  MachineMapAndAddressFragmentDoc,
  UpdateMachineDetailsInput,
  useGetMachineMapQuery,
  useUpdateMachineDetailsMutation
} from 'generated/types';
import { defineMessages } from 'react-intl';
import { Button, Form, FormProps, Input, message, Space, Tooltip, Typography } from 'antd';
const { Text } = Typography;
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages';
import { NavigateOutlinedIcon } from 'components/icons/Icons';
import { useForm } from 'antd/es/form/Form';
import useQueryParam from 'hooks/useQueryParam';
import useFormatTools from 'i18n/useFormatTools';
import { EditablePosition, Position } from 'components/lib/ConnectMap/position';
import MapMarker from 'components/lib/ConnectMap/MapMarker';
import useAddressLookupHandler from 'components/lib/ConnectMap/useAddressLookupHandler';
import styled from 'styled-components';
import InputNumber from 'components/lib/InputNumber/InputNumber';
import Link from 'components/lib/Link/Link';
import Language from 'components/i18n/Language/Language.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';

interface Props {
  machineId?: number;
}

gql`
  fragment MachineMapAndAddress on Machine {
    id
    machineId
    location
    serialNo
    retailerReference
    geoLat
    geoLng
    address1
    address2
    postCity
    postCode
    retailer {
      id
      retailerId
      country {
        ...CountryListItem
      }
    }
    languages {
      ...FullLanguage
    }
    weather {
      temperature
      timestamp
    }
  }
  ${CountryListItemFragmentDoc}
  ${FullLanguageFragmentDoc}
`;

gql`
  query GetMachineMap($machineId: Int!) {
    machine(machineId: $machineId) {
      ...MachineMapAndAddress
      permissions {
        canEditDetails
      }
    }
  }
  ${MachineMapAndAddressFragmentDoc}
`;

gql`
  mutation UpdateMachineDetails($input: UpdateMachineDetailsInput!) {
    updateMachineDetails(input: $input) {
      machine {
        ...MachineMapAndAddress
      }
    }
  }
  ${MachineMapAndAddressFragmentDoc}
`;

const messages = defineMessages({
  copyRetailerRefTooltip: {
    defaultMessage: 'Copy retailer reference to clipboard',
    id: 'machine_map_card.copy_retailer_ref_tooltip'
  }
});

const LimitedSpace = styled(Space)`
  width: 100%;
  overflow: hidden;
`;

function MachinePositionForm<T extends object = UpdateMachineDetailsInput>({
  children,
  ...props
}: FormProps<T> & { children?: React.ReactNode }) {
  return <Form<T> {...props}>{children}</Form>;
}

const MachineMapCard: React.FC<Props> = (props) => {
  const { machineId } = props;
  const intl = useConnectIntl();
  const { formatCountry } = useFormatTools();
  const { formatMachineTitle } = useFormatTools();
  const { formatDate } = useDateFormatTools();

  const { data } = useGetMachineMapQuery({
    variables: machineId
      ? {
          machineId
        }
      : undefined,
    skip: !machineId,
    fetchPolicy: 'cache-and-network'
  });

  const [updateMachine, { loading: saving }] = useUpdateMachineDetailsMutation({
    notifyOnNetworkStatusChange: true
  });

  const editMachineParam = 'editMachine';
  const [form] = useForm<UpdateMachineDetailsInput>();

  const [isEditing, setIsEditing] = useQueryParam<boolean>(editMachineParam);
  const [storedPosition, setStoredPosition] = useState<Position | undefined>(undefined);
  const [editPosition, setEditPosition] = useState<EditablePosition | undefined>(undefined);

  const [center, setCenter] = useState<Position | undefined>(undefined);
  const navUrl = `https://www.google.com/maps/dir/?api=1&destination=${data?.machine.geoLat},${data?.machine.geoLng}`;
  const actions = [];
  const hasGeo = data?.machine.geoLat && data?.machine.geoLng;
  if (hasGeo) {
    actions.push(
      <a href={navUrl} target={'_blank'} rel={'noreferrer'}>
        <Space>
          <NavigateOutlinedIcon />
          <span>{intl.formatMsg(commonMessages.navigate)}</span>
        </Space>
      </a>
    );
  }

  if (data?.machine.permissions.canEditDetails) {
    actions.push(
      <Link key={'edit'} to={`?${editMachineParam}=true`}>
        <Space>
          <EditOutlined />
          <span>{intl.formatMsg(commonMessages.edit)}</span>
        </Space>
      </Link>
    );
  }

  const lookupAddress = useAddressLookupHandler();

  const handleLookupAddress = useCallback(async () => {
    const values = form.getFieldsValue();
    const { address1, postCode, postCity } = values;

    try {
      const result = await lookupAddress({
        address1: address1 as string | undefined,
        postCode: postCode as string | undefined,
        postCity: postCity as string | undefined
      });

      form.setFieldsValue({
        geoLat: result.position.lat,
        geoLng: result.position.lng
      });

      setEditPosition({
        lat: result.position.lat,
        lng: result.position.lng,
        dummy: false
      });
      setCenter({
        lat: result.position.lat,
        lng: result.position.lng
      });
    } catch (err) {
      message.error({
        content: 'Unable to lookup address for machine'
      });
    }
  }, [form, lookupAddress]);

  const handleSubmit = useCallback(async () => {
    try {
      message.loading({
        key: 'submit_machine_map_card',
        content: 'Updating machine...'
      });
      if (!machineId) throw new Error('missing machineId');
      const validatedValues = await form.validateFields();
      await updateMachine({
        variables: {
          input: {
            ...validatedValues,
            machineId: machineId,
            geoLat: validatedValues.geoLat ? validatedValues.geoLat : undefined,
            geoLng: validatedValues.geoLng ? validatedValues.geoLng : undefined
          }
        }
      });
      message.success({
        key: 'submit_machine_map_card',
        content: 'Machine updated successfully.'
      });
      setIsEditing(false);
    } catch (err: unknown) {
      message.error({
        key: 'submit_machine_map_card',
        content: 'Failed to update machine.'
      });
    }
  }, [form, updateMachine, machineId, setIsEditing]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setEditPosition(undefined);
  }, [setIsEditing]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setEditPosition(undefined);
    setStoredPosition(undefined);
  }, [machineId]);

  useEffect(() => {
    if (!data) return;
    if (isEditing) {
      setStoredPosition(undefined);
      form.setFieldsValue({
        geoLat: data.machine.geoLat,
        geoLng: data.machine.geoLng,
        postCity: data.machine.postCity,
        postCode: data.machine.postCode,
        location: data.machine.location,
        retailerReference: data.machine.retailerReference,
        address1: data.machine.address1,
        address2: data.machine.address2
      });

      if (data.machine.geoLat && data.machine.geoLng) {
        setEditPosition({
          lat: data.machine.geoLat,
          lng: data.machine.geoLng,
          dummy: false
        });
        setCenter({
          lat: data.machine.geoLat,
          lng: data.machine.geoLng
        });
      } else {
        // no position on machine, but we need to set position to something to show the editable marker
        if (data.machine.retailer.country.nameEnglish) {
          lookupAddress({
            country: data.machine.retailer.country.nameEnglish,
            hideMessages: true
          })
            .then((result) => {
              setCenter({
                lat: result.position.lat,
                lng: result.position.lng
              });
              setEditPosition({
                lat: result.position.lat,
                lng: result.position.lng,
                dummy: true
              });
            })
            .catch(() => {
              setCenter(defaultCenter);
              setEditPosition({ ...defaultCenter, dummy: true });
            });
        } else {
          setEditPosition({ ...defaultCenter, dummy: true });
          setCenter(defaultCenter);
        }
      }
    } else {
      // console.log('init view', data);
      setEditPosition(undefined);

      if (data.machine.geoLat && data.machine.geoLng) {
        setStoredPosition({
          lat: data.machine.geoLat,
          lng: data.machine.geoLng
        });
        setCenter({
          lat: data.machine.geoLat,
          lng: data.machine.geoLng
        });
      } else {
        // no position, but we can use country to lookup center
        if (data.machine.retailer.country.nameEnglish) {
          lookupAddress({
            country: data.machine.retailer.country.nameEnglish,
            hideMessages: true
          })
            .then((result) => {
              setCenter({
                lat: result.position.lat,
                lng: result.position.lng
              });
            })
            .catch(() => {
              setCenter(defaultCenter);
            });
        } else {
          setCenter(defaultCenter);
        }
      }
    }
  }, [data, data?.machine.geoLat, data?.machine.geoLng, isEditing, form, lookupAddress]);

  return (
    <Card
      size={'default'}
      loading={!data}
      actions={isEditing ? undefined : actions}
      cover={
        <ConnectMap
          basicControls={{
            locate: false,
            streetView: true,
            mapType: true,
            fullscreen: false
          }}
          style={{ height: 240 }}
          scrollwheel={false}
          center={center}
        >
          {storedPosition && !isEditing && data && (
            <MapMarker position={storedPosition} editable={false} dummy={false} />
          )}
          {editPosition && isEditing && data && (
            <MapMarker
              position={editPosition}
              editable={true}
              dummy={editPosition.dummy}
              onChange={(position) => {
                form.setFieldsValue({
                  geoLat: position.lat,
                  geoLng: position.lng
                });

                setEditPosition({
                  lat: position.lat,
                  lng: position.lng,
                  dummy: false
                });
              }}
            />
          )}
        </ConnectMap>
      }
    >
      {data && !isEditing && (
        <LimitedSpace direction={'vertical'} size={'large'}>
          <LimitedSpace direction={'vertical'} size={'small'}>
            <Typography.Text ellipsis={true}>
              <Tooltip
                title={formatMachineTitle(data.machine, {
                  includeSerialNo: true
                })}
              >
                {formatMachineTitle(data.machine)}
              </Tooltip>
            </Typography.Text>
            {data.machine.retailerReference && (
              <Text
                copyable={{
                  tooltips: [intl.formatMsg(messages.copyRetailerRefTooltip)]
                }}
                ellipsis={true}
              >
                {data.machine.retailerReference}
              </Text>
            )}
          </LimitedSpace>

          <LimitedSpace direction={'vertical'} size={'small'}>
            {data.machine.address1 && <Text ellipsis={true}>{data.machine.address1}</Text>}
            {data.machine.address2 && <Text ellipsis={true}>{data.machine.address2}</Text>}
            {(data.machine.postCode || data.machine.postCity) && (
              <LimitedSpace>
                <Text>{data.machine.postCode}</Text>
                <Text ellipsis={true}>{data.machine.postCity}</Text>
              </LimitedSpace>
            )}
            {data.machine.retailer.country.nameEnglish && (
              <Text ellipsis={true}>
                {formatCountry(data.machine.retailer.country.nameEnglish)}
              </Text>
            )}
          </LimitedSpace>

          {data.machine.weather && (
            <LimitedSpace>
              <Tooltip
                title={intl.formatMsg(
                  {
                    id: 'machine_map_card.temperature_tooltip',
                    defaultMessage: 'Last updated {timestamp}'
                  },
                  {
                    timestamp: formatDate(parseISO(data.machine.weather.timestamp), {
                      representation: 'complete'
                    })
                  }
                )}
              >
                <Text ellipsis={true}>
                  {intl.formatMsg(
                    {
                      id: 'machine_map_card.temperature',
                      defaultMessage: 'Temperature: {temperature}°C'
                    },
                    {
                      temperature: data.machine.weather.temperature
                    }
                  )}
                </Text>
              </Tooltip>
            </LimitedSpace>
          )}

          <Space direction={'vertical'} size={'small'}>
            <div>
              {intl.formatMsg({
                id: 'machine_map_card.languages',
                defaultMessage: 'Languages'
              })}
            </div>
            {data.machine.languages.map((language) => (
              <Language key={language.id} language={language} showTooltip={true} />
            ))}
          </Space>
        </LimitedSpace>
      )}
      {isEditing && (
        <Space direction={'vertical'}>
          <MachinePositionForm
            form={form}
            layout={'vertical'}
            size={'middle'}
            disabled={saving}
            autoComplete={'off'}
          >
            <Form.Item name={'location'} label={'Location'}>
              <Input />
            </Form.Item>
            <Form.Item name={'retailerReference'} label={'Retailer reference'}>
              <Input />
            </Form.Item>
            <Form.Item name={'address1'} label={'Address 1'}>
              <Input />
            </Form.Item>
            <Form.Item name={'address2'} label={'Address 2'}>
              <Input />
            </Form.Item>

            <Form.Item name={'postCode'} label={'Post code'}>
              <Input />
            </Form.Item>
            <Form.Item name={'postCity'} label={'Post city'}>
              <Input />
            </Form.Item>
            <Form.Item label={'Coordinates'}>
              <Space direction={'vertical'} size={'middle'}>
                <Form.Item noStyle={true}>
                  <Tooltip title={'Use address to lookup coordinates'}>
                    <Button
                      type={'default'}
                      onClick={handleLookupAddress}
                      style={{ width: '100%' }}
                      disabled={saving}
                    >
                      Lookup address
                    </Button>
                  </Tooltip>
                </Form.Item>
                <Form.Item name={'geoLat'} noStyle={true}>
                  <InputNumber<number>
                    placeholder={'Latitude'}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (value !== null && editPosition) {
                        setEditPosition({
                          ...editPosition,
                          lat: value
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name={'geoLng'} noStyle={true}>
                  <InputNumber<number>
                    placeholder={'Longitude'}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (value !== null && editPosition) {
                        setEditPosition({
                          ...editPosition,
                          lng: value
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
          </MachinePositionForm>
          <Space>
            <Button
              type={'primary'}
              icon={<SaveOutlined />}
              onClick={handleSubmit}
              disabled={saving}
            >
              {intl.formatMsg(commonMessages.save)}
            </Button>
            <Button
              type={'default'}
              icon={<CloseOutlined />}
              onClick={handleCancel}
              disabled={saving}
            >
              {intl.formatMsg(commonMessages.cancel)}
            </Button>
          </Space>
        </Space>
      )}
    </Card>
  );
};

export default MachineMapCard;
