import { DeletedRetailerListItemFragment, RetailerListItemFragment } from 'generated/types';
import React from 'react';
import Link from 'components/lib/Link/Link';
import { CompanyFilledIcon } from 'components/icons/Icons';
import styled, { css } from 'styled-components';
import useMatrixNav from 'layouts/matrix/useMatrixNav';

const StyledLink = styled(Link)<{ $noWrap: boolean; $warn?: boolean; $muted?: boolean }>`
  && {
    .anticon {
      margin-right: 4px;
    }
  }

  ${(props) =>
    props.$noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.$warn &&
    css`
      color: ${(props) => props.theme.ant.colorWarning};
    `}

  ${(props) =>
    props.$muted &&
    css`
      color: ${(props) => props.theme.ant.colorTextSecondary};
    `}
`;

const StyledSpan = styled.span<{ $nowrap: boolean }>`
  display: inline-flex;
  gap: 4px;
  align-items: center;

  ${(props) =>
    props.$nowrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

interface Props {
  retailer: RetailerListItemFragment | DeletedRetailerListItemFragment;
  allowWrap?: boolean;
  hideIcon?: boolean;
  urlFormatter?: (url: string) => string;
  scopeRetailerId?: number;
  muted?: boolean;
}

const RetailerLink: React.FC<Props> = (props) => {
  const { retailer, allowWrap, scopeRetailerId, hideIcon, urlFormatter, muted } = props;
  const { getUrlToRetailer } = useMatrixNav();
  const noWrap = !allowWrap;
  const urlToRetailerOriginal = getUrlToRetailer(retailer.retailerId);
  const urlToRetailer = urlFormatter ? urlFormatter(urlToRetailerOriginal) : urlToRetailerOriginal;
  const differentRetailerId = (scopeRetailerId && scopeRetailerId !== retailer.retailerId) || false;

  if (retailer.__typename === 'DeletedRetailer')
    return (
      <StyledSpan $nowrap={noWrap}>
        {!hideIcon && <CompanyFilledIcon />}
        <span>Deleted retailer</span>
      </StyledSpan>
    );

  if (retailer.__typename === 'Retailer') {
    return (
      <StyledLink to={urlToRetailer} $noWrap={noWrap} $warn={differentRetailerId} $muted={muted}>
        {!hideIcon && <CompanyFilledIcon />}
        <span>{retailer.name}</span>
      </StyledLink>
    );
  }

  return null;
};

export default RetailerLink;
