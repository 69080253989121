import React from 'react';
import styled from 'styled-components';
import { OperationalOutlinedIcon, TruckOutlineIcon } from 'components/icons/Icons';
import NavLinkMobile from 'layouts/MainLayout/FooterMobile/NavLinkMobile';
import { DollarCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { categoryMessages } from 'layouts/matrix/category.tsx';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  height: calc(
    ${(props) => props.theme.connect.layout.footerMobileHeight} + env(safe-area-inset-bottom)
  );
  padding-bottom: env(safe-area-inset-bottom);

  padding-left: 16px;
  padding-right: 16px;

  background-color: ${(props) =>
    props.theme.isDark
      ? props.theme.ant.colorPrimaryActive // a bit darker main color in dark mode
      : props.theme.ant.colorPrimary};

  border-top: 1px solid ${(props) => props.theme.ant.colorBorder};

  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: stretch;
`;

const FooterMobile: React.FC = () => {
  const { selectedCategory, getUrlToCategory } = useMatrixNav();
  const intl = useIntl();
  const match = useMatch('/:category/:tab?');

  return (
    <Container>
      <NavLinkMobile
        icon={<OperationalOutlinedIcon />}
        title={intl.formatMessage(categoryMessages.operational)}
        isSelected={selectedCategory === 'operational'}
        url={getUrlToCategory('operational')}
      />
      <NavLinkMobile
        icon={<DollarCircleOutlined />}
        title={intl.formatMessage(categoryMessages.commercial)}
        isSelected={selectedCategory === 'commercial'}
        url={getUrlToCategory('commercial')}
      />
      <NavLinkMobile
        icon={<TruckOutlineIcon />}
        title={intl.formatMessage(categoryMessages.logistics)}
        isSelected={selectedCategory === 'logistics'}
        url={getUrlToCategory('logistics')}
      />
      {/*<NavLinkMobile*/}
      {/*  icon={<BellOutlined />}*/}
      {/*  title={intl.formatMessage({*/}
      {/*    id: 'Notifications',*/}
      {/*    defaultMessage: 'Notifications',*/}
      {/*  })}*/}
      {/*  isSelected={match?.params.category === 'notifications'}*/}
      {/*  url={'/notifications'}*/}
      {/*/>*/}
      <NavLinkMobile
        icon={<UserOutlined />}
        title={intl.formatMessage({
          id: 'footer_mobile.menu_preferences',
          defaultMessage: 'Preferences',
        })}
        isSelected={match?.params.category === 'preferences'}
        url={'/preferences'}
      />
    </Container>
  );
};

export default FooterMobile;
