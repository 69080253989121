import React, { forwardRef } from 'react';
import { Card as AntCard, CardProps } from 'antd';
import styled from 'styled-components';

const StyledAntCard = styled(AntCard)`
  && {
    .ant-card-actions {
      border-bottom-left-radius: ${(props) => props.theme.ant.borderRadius}px;
      border-bottom-right-radius: ${(props) => props.theme.ant.borderRadius}px;
    }

    .ant-card-cover {
      border-top-left-radius: ${(props) => props.theme.ant.borderRadius}px;
      border-top-right-radius: ${(props) => props.theme.ant.borderRadius}px;
      overflow: hidden;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .ant-card-body {
      margin-left: -8px;
    }
  }
`;

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  return <StyledAntCard ref={ref} {...props} />;
});

Card.displayName = 'Card';

export default Card;
