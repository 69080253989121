import { css } from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';

export const pagePadding = css<{ $breakpoint: ScreenMap }>`
  padding-left: ${(props) =>
    props.$breakpoint.md === false
      ? props.theme.connect.layout.paddingMobile
      : props.theme.connect.layout.paddingDesktop};
  padding-right: ${(props) =>
    props.$breakpoint.md === false
      ? props.theme.connect.layout.paddingMobile
      : props.theme.connect.layout.paddingDesktop};
`;
