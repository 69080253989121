import React, { forwardRef } from 'react';
import { Card as AntCard, CardProps } from 'antd';
import styled, { css } from 'styled-components';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

// TODO: consolidate, responsive css + one div + one property padding. or mode= 'normal' | 'fullscreen'

interface Props {
  noPaddingDesktop?: boolean; // use this to remove padding, useful if you want the background to extend to the edge
  noPaddingMobile?: boolean; // use this to remove padding on mobile
}

const MobileCard = styled(AntCard)<{ $noPadding: boolean }>`
  margin-left: -16px;
  margin-right: -16px;
  border: none;
  border-radius: 0;

  ${(props) =>
    props.$noPadding &&
    css`
      && {
        .ant-card-body {
          padding: 0;
        }
      }
    `}
`;

const DesktopCard = styled(AntCard)<{ $noPadding: boolean }>`
  overflow: hidden;
  ${(props) =>
    props.$noPadding &&
    css`
      && {
        .ant-card-body {
          padding: 0;
        }
      }
    `}
`;

/***
 * ResponsiveListCard - helper component to get "Ralf style" lists
 * Render a Card component on desktop, simply pass children on mobile
 * @param props
 * @constructor
 */
const ResponsiveListCard = forwardRef<HTMLDivElement, CardProps & Props>((props, ref) => {
  const { children, noPaddingDesktop, noPaddingMobile, ...rest } = props;
  const { isMobile } = useScreenInfo();

  if (isMobile) {
    return (
      <MobileCard ref={ref} {...rest} $noPadding={noPaddingMobile || false}>
        {children}
      </MobileCard>
    );
  }

  return (
    <DesktopCard ref={ref} $noPadding={noPaddingDesktop || false} {...rest}>
      {children}
    </DesktopCard>
  );
});

ResponsiveListCard.displayName = 'ResponsiveListCard';

export default ResponsiveListCard;
