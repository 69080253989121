import React from 'react';
import { EventLevel } from 'generated/types.tsx';
import { ErrorIcon, InfoIcon, TraceIcon, WarningIcon } from 'components/icons/Icons.tsx';

function getEventLevelIcon(level: EventLevel) {
  switch (level) {
    case EventLevel.Info:
      return <InfoIcon />;
    case EventLevel.Warning:
      return <WarningIcon />;
    case EventLevel.Error:
      return <ErrorIcon />;
    case EventLevel.Fatal:
      return <ErrorIcon />;
    case EventLevel.Debug:
    case EventLevel.Trace:
      return <TraceIcon />;
    default:
      return null;
  }
}

export default getEventLevelIcon;
