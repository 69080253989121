import { useAppSelector } from 'redux/store.ts';
import { selectIsWsConnected } from 'redux/appSlice.ts';
import { useEffect, useState } from 'react';

/***
 * This hook can be used to run a function when the websocket connection is re-connected
 * This is typically when the server instance shuts down, connection is lost etc, and then it re-connects successfully
 * You can use this to refetch a query when this happens.
 * Note that we only have one websocket connection, but can have multiple subcriptions
 * */
const useOnWebsocketReconnected = (options: {
  callback: () => Promise<unknown> | void; // Accepts async function or regular function
  skip: boolean;
}) => {
  const { skip, callback } = options;
  const isWsConnected = useAppSelector(selectIsWsConnected);
  const [wasDisconnected, setWasDisconnected] = useState(false);

  // Track when the WebSocket disconnects after the data has been loaded
  useEffect(() => {
    if (!isWsConnected && !skip) {
      console.log('⚠️ WebSocket disconnected, marking wasDisconnected as true');
      setWasDisconnected(true);
    }
  }, [isWsConnected, skip]);

  useEffect(() => {
    const handleCallback = async () => {
      if (isWsConnected && wasDisconnected) {
        console.log('🚀 WebSocket reconnected!');
        try {
          await callback();
        } catch (error) {
          console.error('❌ Error:', error);
        }
        setWasDisconnected(false);
      }
    };

    handleCallback();
  }, [isWsConnected, wasDisconnected, callback]);

  return {
    isWsConnected
  };
};

export default useOnWebsocketReconnected;
