import React from 'react';
import styled from 'styled-components';

interface Props {
  data?: string;
}

const Text = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.ant.colorText};
`;

const TraceViewer: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  return <Text>{data}</Text>;
};

export default TraceViewer;
