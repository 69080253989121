import React from 'react';
import DatePicker from 'components/lib/DatePicker/DatePicker.tsx';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter.ts';
import { addMonths, parseISO } from 'date-fns';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const TicketDueBeforeFilterSelect: React.FC = () => {
  const { ticketFilter, setTicketFilter } = useTicketFilter();
  const intl = useConnectIntl();
  const { dueTo } = ticketFilter;

  const handleChange = (date: Date | null) => {
    setTicketFilter({
      dueTo: date?.toISOString()
    });
  };

  return (
    <DatePicker
      allowClear={true}
      value={dueTo ? parseISO(dueTo) : undefined}
      placeholder={intl.formatMsg({
        id: 'ticket_earliest_due_date_filter_select.placeholder',
        defaultMessage: 'Earliest due date'
      })}
      onChange={handleChange}
      style={{ width: '100%' }}
      presets={[
        {
          label: intl.formatMsg({
            id: 'ticket_earliest_due_date_filter_select.one_month',
            defaultMessage: 'One month'
          }),
          value: addMonths(new Date(), 1)
        },
        {
          label: intl.formatMsg({
            id: 'ticket_earliest_due_date_filter_select.two_months',
            defaultMessage: 'Two months'
          }),
          value: addMonths(new Date(), 2)
        },
        {
          label: intl.formatMsg({
            id: 'ticket_earliest_due_date_filter_select.six_months',
            defaultMessage: 'Six months'
          }),
          value: addMonths(new Date(), 6)
        }
      ]}
    />
  );
};

export default TicketDueBeforeFilterSelect;
