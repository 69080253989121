import React from 'react';
import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import { useMatrixLevel } from 'layouts/matrix/useMatrixLevel.ts';
import Tabs, { ConnectTabsProps } from 'components/lib/Tabs/Tabs.tsx';
import { pagePadding } from 'components/lib/page/pagePadding.ts';

const Container = styled.div<{
  $breakpoint: ScreenMap;
  $isMobile: boolean;
  $isRoot: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;

  // NOTE: On desktop, we need to prevent scroll here since we move the scrollable area inside Tabs->ScrollableTabsContent
  // Hmm.. unless on TranslationPage which controls its own scroll...
  @media (min-width: 480px) {
    max-height: 100%; // only if desktop, move scroll down
    overflow: hidden; // needed to prevent scroll here
  }

  && {
    .connect-tabs {
      flex: auto;
      @media (min-width: 480px) {
        max-height: 100%; // only if desktop, move scroll down
      }
      background-color: ${(props) => props.theme.ant.colorBgContainer};

      display: flex;
      flex-direction: column;
    }

    .connect-tabs-nav {
      background-color: ${(props) => props.theme.ant.colorBgContainer};
      ${pagePadding};
      margin-bottom: 0; // disable default margin 16 px bottom tabs.. tabs pane content need to handle it

      position: sticky;
      top: 0;

      @media (min-width: 480px) {
        position: relative;
        top: unset;
      }

      z-index: 5;
    }

    .connect-tabs-tabpane {
      background-color: ${(props) => props.theme.ant.colorBgLayout};
    }

    .connect-tabs-tabpane-active {
      flex: 1 1 auto; // content background will cover the whole area when little content inside
    }

    .connect-tabs-tabpane-active-scroll {
      @media (min-width: 480px) {
        // On desktop, this is the scrollable area when using tabs
        overflow-y: auto;
        height: 100%;
      }
    }
  }
`;

/***
 * PageTabs is a custom Tabs component with some additional styling (page padding, scroll etc.)
 * ***/
const PageTabs: React.FC<ConnectTabsProps> = (props) => {
  const { items, activeKey, ...rest } = props;
  const { screenMap: breakpoint } = useScreenInfo();
  const isMobile = useIsMobile();
  const isRoot = useMatrixLevel() === 'root';

  return (
    <Container $breakpoint={breakpoint} $isMobile={isMobile} $isRoot={isRoot}>
      <Tabs activeKey={activeKey} items={items} setScrollContext={true} {...rest} />
    </Container>
  );
};

export default PageTabs;
