import { Col, Row, Skeleton, Space, Tooltip } from 'antd';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { isAfter, isBefore, parseISO } from 'date-fns';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Tag from 'components/lib/Tag/Tag';
import { MachineWarrantyInfoFragmentDoc, useGetMachineWarrantyInfoQuery } from 'generated/types';
import useDateFormatTools from 'i18n/useDateFormatTools';
import Popover from 'components/lib/Popover/Popover';
import { gql } from '@apollo/client/core';
import DeactivatedMachineTag from 'components/machine/DeactivatedMachineTag/DeactivatedMachineTag.tsx';

interface Props {
  machineId?: number;
  direction?: 'horizontal' | 'vertical';
}

gql`
  fragment MachineWarrantyInfo on Machine {
    id
    machineId
    warrantyExpiryDate
    serviceAgreement
    deliveryDate
    operationDate
    active
  }
`;

gql`
  query GetMachineWarrantyInfo($id: Int!) {
    machine(machineId: $id) {
      ...MachineWarrantyInfo
    }
  }
  ${MachineWarrantyInfoFragmentDoc}
`;

const messages = defineMessages({
  inactive: {
    defaultMessage: 'Inactive',
    id: 'service_info_tags.inactive'
  },
  inactiveTooltip: {
    defaultMessage: 'This machine instance is currently inactive',
    id: 'service_info_tags.inactive_tooltip'
  },
  noServiceAgreement: {
    defaultMessage: 'No Vendanor Care',
    id: 'service_info_tags.no_service_agreement'
  },
  validServiceAgreement: {
    defaultMessage: 'Vendanor Care',
    id: 'service_info_tags.valid_service_agreement'
  },
  noWarranty: {
    defaultMessage: 'Warranty expired',
    id: 'service_info_tags.no_warranty'
  },
  validWarranty: {
    defaultMessage: 'Warranty: {date}',
    id: 'service_info_tags.valid_warranty'
  },
  labelWarranty: {
    defaultMessage: 'Warranty expiry date:',
    id: 'service_info_tags.label_warranty'
  },
  labelDelivery: {
    defaultMessage: 'Delivery date:',
    id: 'service_info_tags.label_delivery'
  },
  labelOperation: {
    defaultMessage: 'Operation date:',
    id: 'service_info_tags.label_operation'
  }
});

/***
 * Render tags with warranty and service agreement information
 * If machine instance is disabled, only render a big disabled tag?
 * @param props
 * @constructor
 */
const ServiceInfoTags: React.FC<Props> = (props) => {
  const { machineId, direction } = props;
  const today = new Date();
  const intl = useIntl();
  const { formatDate } = useDateFormatTools();

  const { data, loading } = useGetMachineWarrantyInfoQuery({
    variables: machineId
      ? {
          id: machineId
        }
      : undefined,
    skip: !machineId,
    notifyOnNetworkStatusChange: true
  });

  const hasServiceAgreement = data?.machine.serviceAgreement || false;
  const warrantyExpiryDateRaw = data?.machine.warrantyExpiryDate;
  const warrantyExpiryDate = warrantyExpiryDateRaw ? parseISO(warrantyExpiryDateRaw) : undefined;
  const active = data?.machine.active || false;

  if (machineId === undefined) {
    return null;
  }

  const warrantyTooltipContent = data ? (
    <>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col>{intl.formatMessage(messages.labelDelivery)}</Col>
        <Col>
          {data?.machine.deliveryDate ? formatDate(parseISO(data.machine.deliveryDate)) : ''}
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col>{intl.formatMessage(messages.labelOperation)}</Col>
        <Col>
          {data?.machine.operationDate ? formatDate(parseISO(data.machine.operationDate)) : ''}
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col>{intl.formatMessage(messages.labelWarranty)}</Col>
        <Col>{warrantyExpiryDate ? formatDate(warrantyExpiryDate) : ''}</Col>
      </Row>
    </>
  ) : null;

  return (
    <Space direction={direction || 'vertical'} size={'small'} align={'end'}>
      {loading && (
        <Skeleton
          title={false}
          active={true}
          paragraph={{
            rows: direction === 'vertical' ? 2 : 1,
            style: { width: 70 }
          }}
          round={true}
        />
      )}
      {!loading && !active && (
        <Tooltip title={intl.formatMessage(messages.inactiveTooltip)}>
          <DeactivatedMachineTag />
        </Tooltip>
      )}

      {!loading && active && hasServiceAgreement && (
        <Tag icon={<CheckOutlined />} color={'success'}>
          {intl.formatMessage(messages.validServiceAgreement)}
        </Tag>
      )}

      {!loading && active && !hasServiceAgreement && (
        <Tag color={'warning'} icon={<StopOutlined />}>
          {intl.formatMessage(messages.noServiceAgreement)}
        </Tag>
      )}

      {!loading && active && warrantyExpiryDate && isBefore(today, warrantyExpiryDate) && (
        <Popover content={warrantyTooltipContent} showArrow={false}>
          <div>
            <Tag color={'success'} icon={<CheckOutlined />}>
              {intl.formatMessage(messages.validWarranty, {
                date: formatDate(warrantyExpiryDate)
              })}
            </Tag>
          </div>
        </Popover>
      )}

      {!loading && active && warrantyExpiryDate && isAfter(today, warrantyExpiryDate) && (
        <Popover content={warrantyTooltipContent} showArrow={false}>
          <div>
            <Tag color={'warning'} icon={<StopOutlined />}>
              {intl.formatMessage(messages.noWarranty)}
            </Tag>
          </div>
        </Popover>
      )}
    </Space>
  );
};

export default ServiceInfoTags;
