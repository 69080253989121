import React, { useMemo } from 'react';
import { Empty, Flex, Image } from 'antd';
import { QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { PredictionItemFragment } from 'generated/types.tsx';
import useRefWidth from 'hooks/useRefWidth.ts';
import styled from 'styled-components';

interface Props {
  size: 'small' | 'normal';
  prediction: PredictionItemFragment;
}

const PredictionResult: React.FC<Props> = ({ prediction, size }) => {
  const naturalWidth = prediction.sample.width;
  const naturalHeight = prediction.sample.height;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { width: containerWidth } = useRefWidth(containerRef);

  const calculatedHeight = useMemo(() => {
    if (!containerWidth) {
      return undefined;
    }
    return (naturalHeight / naturalWidth) * containerWidth;
  }, [containerWidth, naturalHeight, naturalWidth]);

  return (
    <Container ref={containerRef} style={{ height: calculatedHeight }}>
      {prediction.result.__typename === 'EmptyChamberPredictionResult' && (
        <Flex justify={'center'} align={'center'}>
          <Empty description={''} />
        </Flex>
      )}
      {prediction.result.__typename === 'ProductFoundPredictionResult' && (
        <>
          {prediction.result.product.image?.url ? (
            <>
              <Image
                src={prediction.result.product.image.url}
                preview={false}
                height={calculatedHeight}
                width={'60%'}
              />
            </>
          ) : (
            <QuestionOutlined />
          )}
        </>
      )}
      {prediction.result.__typename === 'ProductUnknownPredictionResult' && (
        <span style={{ fontSize: size === 'normal' ? 96 : 36 }}>
          <QuestionCircleOutlined />
        </span>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
  }
`;

export default PredictionResult;
