import React from 'react';
import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import { RowProps } from 'antd';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import PageHeaderRow from 'components/lib/page/rows/PageHeaderRow.tsx';

const StyledRow = styled(PageHeaderRow)<{
  $breakpoint: ScreenMap;
  $offset: number;
}>`
  && {
    width: 100%;
    gap: 8px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${(props) => (props.$breakpoint.md === false ? '8px' : '16px')};
    padding-bottom: ${(props) => (props.$breakpoint.md === false ? '8px' : '16px')};
  }
`;

interface Props {
  offset?: number;
}

/***
 * PageTitleRow
 * @param props
 * @constructor
 */
const PageTitleRow: React.FC<RowProps & Props> = (props) => {
  const { children, offset, ...rest } = props;
  const { screenMap: breakpoint } = useScreenInfo();
  return (
    <StyledRow $breakpoint={breakpoint} $offset={offset || 0} {...rest}>
      {children}
    </StyledRow>
  );
};

export default PageTitleRow;
