import { useEffect, useRef } from 'react';
import useTabFocus from 'hooks/useTabFocus.ts';

const useOnTabRefocused = (callback: () => Promise<unknown> | void) => {
  const { isFocused, hasLostFocus } = useTabFocus();
  const prevIsFocusedRef = useRef(isFocused);

  useEffect(() => {
    if (!prevIsFocusedRef.current && isFocused && hasLostFocus) {
      console.log('re-focused! lets re-fetch!');
      callback();
    }
    prevIsFocusedRef.current = isFocused;
  }, [isFocused, hasLostFocus, callback]);

  return { isFocused, hasLostFocus };
};

export default useOnTabRefocused;
