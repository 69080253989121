import React, { useState } from 'react';
import { Button, Empty, Flex, message, Popconfirm, Space, Table, TableProps, Tooltip } from 'antd';
import {
  ArticleListItemFragmentDoc,
  DeletedUser,
  TicketOrderLineFormInput,
  TicketOrderLineFullFragment,
  TicketOrderLineFullFragmentDoc,
  useDeleteTicketOrderLineMutation,
  useGetTicketAccountingQuery,
  useGetUserAndSettingsQuery,
  UserListItemFragment,
  useUpdateTicketOrderLineMutation
} from 'generated/types';
import { ColumnsType } from 'antd/es/table';
import LinkButton from 'components/lib/Link/LinkButton.tsx';
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import CategoryTag from 'components/ticket/TicketAccounting/CategoryTag';
import ArticleItem from 'components/ticket/TicketAccounting/ArticleItem';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import { Key } from 'antd/es/table/interface';
import { parseISO, parseJSON } from 'date-fns';
import useDateFormatTools from 'i18n/useDateFormatTools';
import commonMessages from 'components/i18n/commonMessages';
import Tag from 'components/lib/Tag/Tag';
import useFormatTools from 'i18n/useFormatTools';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useIsMobile from 'layouts/responsive/useIsMobile';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import StorageItem from 'components/ticket/TicketAccounting/StorageItem.tsx';
import { ticketAccountingMessages } from '../ticketAccountingMessages';
import AddOrderLine from 'components/ticket/TicketAccounting/OrderLineList/AddOrderLine.tsx';
import OrderLineForm from 'components/ticket/TicketAccounting/OrderLineForm/OrderLineForm.tsx';
import { gql } from '@apollo/client/core';

gql`
  mutation deleteTicketOrderLine($input: DeleteTicketOrderLineInput!) {
    deleteTicketOrderLine(input: $input) {
      id
      ticketId
      orderLines {
        ...TicketOrderLineFull
      }
      hasOrderLines
    }
  }
  ${TicketOrderLineFullFragmentDoc}
`;

gql`
  mutation updateTicketOrderLine($input: UpdateTicketOrderLineInput!) {
    updateTicketOrderLine(input: $input) {
      id
      ticketId
      orderLines {
        ...TicketOrderLineFull
      }
    }
  }
  ${TicketOrderLineFullFragmentDoc}
`;

gql`
  query GetTicketOrderLineArticle($ticketArticleId: ID!) {
    ticketArticle(ticketArticleId: $ticketArticleId) {
      ...ArticleListItem
    }
  }
  ${ArticleListItemFragmentDoc}
`;

function TypedTable<TRecordType extends object = TicketOrderLineFullFragment>({
  children,
  ...rest
}: TableProps<TRecordType>) {
  return <Table<TRecordType> {...rest}>{children}</Table>;
}

interface Props {
  ticketId?: number;
}

const OrderLineList: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const intl = useConnectIntl();
  const { formatDate } = useDateFormatTools();
  const { screenMap: breakpoint } = useScreenInfo();
  const { formatUserName, formatNOK } = useFormatTools();

  const isMobile = useIsMobile();

  const { data, loading } = useGetTicketAccountingQuery({
    variables: ticketId ? { ticketId } : undefined,
    skip: !ticketId,
    fetchPolicy: 'cache-and-network'
  });

  const { data: dataUser } = useGetUserAndSettingsQuery();

  const [updateLine, { loading: isUpdatingLine }] = useUpdateTicketOrderLineMutation({
    notifyOnNetworkStatusChange: true
  });

  const [deleteLine] = useDeleteTicketOrderLineMutation({
    notifyOnNetworkStatusChange: true
  });

  const [expandedRows, setExpandedRows] = useState<Key[]>([]);
  const isEditingAny = expandedRows.length > 0;

  const handleEditOrderLine = (record: TicketOrderLineFullFragment) => {
    setExpandedRows([record.id]);
  };

  const handleSaveEdit = async (ticketOrderLineId: string, values: TicketOrderLineFormInput) => {
    try {
      await updateLine({
        variables: {
          input: {
            form: values,
            ticketOrderLineId
          }
        }
      });
      setExpandedRows([]);
    } catch (err: unknown) {
      message.error(intl.formatMessage(ticketAccountingMessages.save_line_error));
    }
  };

  const handleCancelEdit = () => {
    setExpandedRows([]);
  };

  const [addRows, setAddRows] = useState<string[]>([]);

  const handleAddRow = () => {
    const tempId = (Math.random() + 1).toString(36).substring(7);
    setAddRows([...addRows, tempId]);
  };

  const removeTemporaryAddRow = (tempId: string) => {
    const temp = [...addRows];
    temp.splice(temp.indexOf(tempId), 1);
    setAddRows(temp);
  };

  const handleCancelAddRow = (tempId: string) => removeTemporaryAddRow(tempId);
  const handleRowAdded = (tempId: string) => removeTemporaryAddRow(tempId);

  const handleDeleteRow = async (ticketOrderLineId: string) => {
    if (!ticketId) {
      return;
    }

    try {
      await deleteLine({
        variables: {
          input: {
            ticketId,
            ticketOrderLineId
          }
        }
      });
    } catch (err: unknown) {
      message.error(
        intl.formatMessage({
          id: 'ticket_order_line_list.delete_line_error',
          defaultMessage: 'Could not delete line'
        })
      );
    }
  };

  const columns: ColumnsType<TicketOrderLineFullFragment> = [
    {
      key: 'article',
      title: intl.formatMessage(ticketAccountingMessages.col_article),
      render: (value, record) => (
        <div style={{ minWidth: 240 }}>
          <ArticleItem key={record.id} article={record.article} showIcon={true} />
        </div>
      )
    },
    {
      key: 'storage',
      title: intl.formatMsg(ticketAccountingMessages.col_storage),
      render: (_, record) => (
        <div style={{ minWidth: 240 }}>
          <StorageItem storage={record.articleStorage} />
        </div>
      )
    },
    {
      key: 'description',
      title: intl.formatMessage(ticketAccountingMessages.col_description),
      render: (value, record) => (
        <Flex vertical={true} style={{ minWidth: 150 }}>
          {record.description?.split('\n').map((line) => <div key={line}>{line}</div>)}
        </Flex>
      )
    },
    {
      key: 'date',
      title: intl.formatMessage(ticketAccountingMessages.col_date),
      render: (value, record) => (
        <NoWrapContainer>{formatDate(parseISO(record.date))}</NoWrapContainer>
      ),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return parseISO(a.date).getTime() - parseISO(b.date).getTime();
      }
    },
    {
      key: 'categoryId',
      title: intl.formatMessage(ticketAccountingMessages.col_account),
      render: (value, record) => {
        return record.category.name ? (
          <CategoryTag name={record.category.name} showTooltip={true} />
        ) : null;
      }
    },
    {
      key: 'user',
      title: intl.formatMessage(ticketAccountingMessages.col_user),
      align: 'center',
      render: (_, record) => {
        return <UserAvatar user={record.user as UserListItemFragment | DeletedUser} />;
      }
    },
    {
      key: 'quantity',
      title: intl.formatMessage(ticketAccountingMessages.col_quantity),
      render: (value, record) => record.quantity.toFixed(2)
    },
    {
      key: 'price',
      title: intl.formatMessage(ticketAccountingMessages.col_unit_price),
      align: 'right',
      render: (value, record) => formatNOK(record.unitPriceOriginal)
    },
    {
      key: 'discount',
      title: intl.formatMessage(ticketAccountingMessages.col_discount),
      align: 'right',
      render: (_, record) => {
        const discount = record.discountPercentage;
        return discount
          ? intl.formatNumber(discount / 100.0, {
              style: 'percent'
            })
          : undefined;
      }
    },
    {
      key: 'sum',
      title: intl.formatMessage(ticketAccountingMessages.col_amount),
      align: 'right',
      render: (value, record) => formatNOK(record.totalPrice)
    },
    {
      key: 'actions',
      title: intl.formatMessage(ticketAccountingMessages.col_actions),
      render: (value, record) => {
        return record.accounted ? (
          <Tooltip
            title={intl.formatMessage(ticketAccountingMessages.cannot_edit_accounted, {
              timestamp: record.accountedTimestamp
                ? formatDate(parseJSON(record.accountedTimestamp), {
                    representation: 'complete'
                  })
                : undefined,
              user: formatUserName(record.accountedByUser)
            })}
          >
            <Tag color={'success'} icon={<CheckOutlined />}>
              {breakpoint.xl && <>{intl.formatMessage(ticketAccountingMessages.accounted)}</>}
            </Tag>
          </Tooltip>
        ) : (
          <Space>
            <LinkButton
              disabled={isEditingAny}
              icon={<EditOutlined />}
              onClick={() => {
                handleEditOrderLine(record);
              }}
            >
              {breakpoint.xl && <span>{intl.formatMsg(commonMessages.edit)}</span>}
            </LinkButton>
            <Popconfirm
              title={intl.formatMessage(commonMessages.confirm_delete_item)}
              onConfirm={() => handleDeleteRow(record.id)}
              okText={intl.formatMessage(commonMessages.yes)}
              cancelText={intl.formatMessage(commonMessages.no)}
            >
              <LinkButton icon={<DeleteOutlined />} disabled={isEditingAny}>
                {breakpoint.xl && <span>{intl.formatMsg(commonMessages.delete)}</span>}
              </LinkButton>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];

  return (
    <Container $isMobile={isMobile}>
      <TypedTable
        bordered={false}
        columns={columns}
        dataSource={data?.ticket.orderLines}
        size={'middle'}
        locale={{
          emptyText: addRows.length > 0 ? <div /> : <Empty />
        }}
        scroll={{ x: true }}
        rowKey={(record) => record.id}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
        summary={(data1) => {
          const totalSumExVat = data1.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.totalPrice;
          }, 0);

          return (
            <>
              {ticketId &&
                addRows.map((tempAddRowId) => (
                  <AddOrderLine
                    ticketId={ticketId}
                    userId={dataUser?.me.id}
                    key={tempAddRowId}
                    tempId={tempAddRowId}
                    onCancel={() => handleCancelAddRow(tempAddRowId)}
                    onAdded={() => handleRowAdded(tempAddRowId)}
                  />
                ))}

              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={columns.length}>
                  <Button
                    type={'dashed'}
                    icon={<PlusCircleOutlined />}
                    onClick={handleAddRow}
                    style={{ width: '100%' }}
                  >
                    <FormattedMessage
                      id={'ticket_order_line_list.add_row'}
                      defaultMessage={'Add order line'}
                      tagName={'span'}
                    />
                  </Button>
                </Table.Summary.Cell>
              </Table.Summary.Row>

              <SumRow>
                <Table.Summary.Cell index={0} colSpan={columns.length - 2}>
                  <FormattedMessage
                    id={'ticket_order_line_list.sum_total_ex_vat'}
                    defaultMessage={'Sum total ex. VAT'}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  {formatNOK(totalSumExVat)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
              </SumRow>
            </>
          );
        }}
        expandable={{
          expandedRowClassName: () => 'expand-edit-row',
          showExpandColumn: false,
          expandedRowKeys: expandedRows,
          expandedRowRender: (record) => {
            if (ticketId === undefined) {
              return <Spinner />;
            }

            return (
              <OrderLineForm
                key={record.id}
                ticketId={ticketId}
                isSaving={isUpdatingLine}
                initialValues={{
                  date: record.date,
                  description: record.description,
                  articleId:
                    record.article.__typename === 'TicketArticle' ? record.article.id : undefined,
                  categoryId: record.category.id,
                  priceOut: record.unitPriceOut,
                  unitPriceOriginal: record.unitPriceOriginal,
                  discount: record.discountPercentage || undefined,
                  sum: record.totalPrice,
                  quantity: record.quantity,
                  userId: record.user?.__typename === 'User' ? record.user.id : undefined,
                  articleStorageId: record.articleStorageId
                }}
                mode={'edit'}
                onSubmit={(values) => handleSaveEdit(record.id, values)}
                onCancel={handleCancelEdit}
              />
            );
          }
        }}
        pagination={false}
      />
    </Container>
  );
};

const NoWrapContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) =>
    props.$isMobile &&
    `
    margin-left: -16px;
    margin-right: -16px;
    border: none;
    border-radius: 0;
  `}
`;

// custom styled SumRow since we don't have a footer with rounded corners. Remove if we add a footer later
const SumRow = styled(Table.Summary.Row)`
  &&& {
    td:first-child {
      border-bottom-left-radius: ${(props) => props.theme.ant.borderRadius}px;
    }
    td:last-child {
      border-bottom-right-radius: ${(props) => props.theme.ant.borderRadius}px;
    }
  }
`;

export default OrderLineList;
