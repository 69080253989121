import { ThemeConfig } from 'antd/es/config-provider/context';
import { theme } from 'antd';
import { useConnectAppTheme } from 'themes/useConnectAppTheme';
import { Theme } from 'generated/types';

const lineHeightBase = 1.5715;

const antThemeCommon: ThemeConfig = {
  token: {
    borderRadius: 4,
    lineType: 'solid',
    lineWidth: 1,
    sizeStep: 4,
    fontSize: 14,
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    lineHeight: lineHeightBase,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    fontWeightStrong: 600,
    colorWhite: '#fefefe',
    screenXXL: 1920,
    screenXXLMin: 1920,
    linkDecoration: 'none'
  }
};

const antThemeGreen: ThemeConfig = {
  ...antThemeCommon,
  algorithm: theme.defaultAlgorithm,
  token: {
    ...antThemeCommon.token,
    colorPrimary: '#0A747E', // a bit darker than styleguide
    colorSuccess: '#119931', // doesnt match color scheme...
    colorWarning: '#FAA000',
    colorError: '#f04134',
    colorInfo: '#0A747E',
    colorTextBase: '#272932',
    colorBgBase: '#fefefe',
    colorLink: 'rgba(39, 41, 50, 0.88)',
    colorLinkHover: '#21848a',
    colorTextPlaceholder: '#27293298' // To avoid rgba on the Avatar background color.
  },
  components: {
    Breadcrumb: {
      colorTextDescription: '#272932' // darker breadcrumb text color
    },
    Avatar: {
      colorTextPlaceholder: '#C9C9CB' // To avoid rgba on the Avatar background color.
    }
  }
};

const antThemeDark: ThemeConfig = {
  ...antThemeCommon,
  algorithm: theme.darkAlgorithm,
  token: {
    ...antThemeCommon.token,
    colorPrimary: '#0096A0', // original, lighter primary color!
    colorSuccess: '#00A854', // brighter green in dark mode
    colorWarning: '#FAA000',
    colorError: '#f04134',
    colorInfo: '#0A747E',
    colorTextBase: '#FCFAF9',
    colorBgBase: '#272932',
    colorTextPlaceholder: '#bdbdbd',
    colorLinkHover: '#1b9a9e', // '#236B8E',
    colorLink: 'rgba(252, 250, 249, 0.85)' // hard to spot links?
  },
  components: {
    Breadcrumb: {
      colorTextDescription: '#C9C9CB'
    }
  }
};

export const getAntThemeFromConnectTheme = (theme: Theme | undefined): ThemeConfig => {
  switch (theme) {
    case Theme.Light:
      return antThemeGreen;
    case Theme.Dark:
      return antThemeDark;
    default:
      return antThemeGreen;
  }
};

function useAntThemeConfig(): ThemeConfig {
  const { connectAppTheme } = useConnectAppTheme();
  return getAntThemeFromConnectTheme(connectAppTheme);
}

export default useAntThemeConfig;
