import { EventLevel } from 'generated/types.tsx';
import { ExpandableListItemBackground } from 'components/lib/List/ExpandableListItem.tsx';

const getEventLevelBackground = (
  level: EventLevel,
  mute: boolean
): ExpandableListItemBackground | undefined => {
  if (mute) {
    return 'muted';
  }
  switch (level) {
    case EventLevel.Trace:
      return 'trace';
    case EventLevel.Debug:
      return 'debug';
    case EventLevel.Warning:
      return 'warning';
    case EventLevel.Info:
      return 'info';
    case EventLevel.Error:
    case EventLevel.Fatal:
      return 'error';
    default:
      return undefined;
  }
};

export default getEventLevelBackground;
