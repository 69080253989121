import { useEffect, useState } from 'react';

const useTabFocus = () => {
  const [isFocused, setFocused] = useState(!document.hidden);
  const [hasLostFocus, setHasLostFocus] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setFocused(!document.hidden);
      if (document.hidden) {
        setHasLostFocus(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { isFocused, hasLostFocus };
};

export default useTabFocus;
