import { EventLevel } from 'generated/types.tsx';
import Select from 'components/lib/Select/Select.tsx';
import useEventListFilter from 'components/events/EventList/EventListFilter/useEventListFilter.ts';
import React, { useMemo } from 'react';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions.ts';

const EventLevelFilterSelect: React.FC = () => {
  const { eventFilter, setEventFilter } = useEventListFilter();

  const handleChangeLevel = (level: EventLevel) => {
    setEventFilter({ eventLevel: level });
  };

  const options = useEnumSelectOptions<EventLevel>({
    enumObject: EventLevel
  });

  const sortedOptions = useMemo(() => {
    const sortOrder = [
      EventLevel.Fatal,
      EventLevel.Error,
      EventLevel.Warning,
      EventLevel.Info,
      EventLevel.Debug,
      EventLevel.Trace
    ];

    return options.sort((a, b) => {
      return sortOrder.indexOf(a.data) - sortOrder.indexOf(b.data);
    });
  }, [options]);

  return (
    <Select<EventLevel>
      value={eventFilter.eventLevel}
      onChange={handleChangeLevel}
      style={{ width: '100%' }}
      options={sortedOptions}
    />
  );
};

export default EventLevelFilterSelect;
