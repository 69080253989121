import React from 'react';
import { defineMessages } from 'react-intl';
import Alert, { AlertProps } from 'components/lib/Alert/Alert.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const messages = defineMessages({
  differentSerialNo: {
    id: 'ticket_alert.different_serial_no',
    defaultMessage: 'The ticket is from a different physical machine, {ticketSerialNo}',
  },
  deactivatedMachine: {
    id: 'ticket_alert.create_deactivated_machine',
    defaultMessage: 'This will create a ticket for a deactivated machine',
  },
});

export const TicketAlertDifferentSerialNo: React.FC<
  {
    ticketSerialNo: string;
    machineSerialNo: string;
  } & AlertProps
> = ({ ticketSerialNo, machineSerialNo, ...rest }) => {
  const intl = useConnectIntl();
  return (
    <Alert
      showIcon={true}
      type={'warning'}
      message={intl.formatMsg(messages.differentSerialNo, {
        ticketSerialNo,
        machineSerialNo,
      })}
      {...rest}
    />
  );
};

export const TicketAlertCreateDeactivatedMachine: React.FC<AlertProps> = (props) => {
  const intl = useConnectIntl();
  return (
    <Alert
      showIcon={true}
      type={'warning'}
      message={intl.formatMsg(messages.deactivatedMachine)}
      {...props}
    />
  );
};
