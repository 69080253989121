import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import {
  DeletedUser,
  DeletedUserListItemFragmentDoc,
  FullPageInfoFragmentDoc,
  MachineListItemFragmentDoc,
  RetailerListItemFragmentDoc,
  SortDirection,
  TicketListItemFragmentDoc,
  TicketOrderLineFullAdminFragment,
  TicketOrderLineFullAdminFragmentDoc,
  TicketOrderLineFullFragmentDoc,
  TicketOrderLineSortField,
  TicketsFilterInput,
  useGetTicketOrderLinesQuery,
  UserListItemFragment,
  UserListItemFragmentDoc,
  useSetAccountedMutation
} from 'generated/types';
import {
  Button,
  Col,
  Flex,
  message,
  Radio,
  Result,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  TableProps,
  Tooltip
} from 'antd';
import ArticleItem from 'components/ticket/TicketAccounting/ArticleItem';
import { parseJSON } from 'date-fns';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import { FormattedMessage } from 'react-intl';
import useDateFormatTools from 'i18n/useDateFormatTools';
import { CheckCircleOutlined, CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import Tag from 'components/lib/Tag/Tag';
import TicketStatusIcon from 'components/ticket/TicketStatusIcon/TicketStatusIcon.tsx';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import RetailerLink from 'components/retailer/RetailerLink/RetailerLink';
import MachineLink from 'components/machine/MachineLink/MachineLink';
import useQueryParam from 'hooks/useQueryParam';
import commonMessages from 'components/i18n/commonMessages';
import { isArray } from 'lodash-es';
import { ticketAccountingMessages } from 'components/ticket/TicketAccounting/ticketAccountingMessages';
import TicketListDisplayModeSegmented from 'components/ticket/TicketList/TicketListDisplayModeSegmented';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/es/table';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import StorageItem from 'components/ticket/TicketAccounting/StorageItem.tsx';
import StorageFilterSelect from 'components/ticket/TicketAccounting/StorageFilterSelect.tsx';
import CategoryFilterSelect from 'components/ticket/TicketAccounting/CategoryFilterSelect.tsx';
import useTicketAccountingToolFilter from 'components/ticket/TicketAccounting/OrderLineTool/useTicketAccountingToolFilter.ts';
import TicketTitle from 'components/ticket/TicketTitle/TicketTitle.tsx';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';
import ErrorIllustration from 'components/illustrations/ErrorIllustration.tsx';
import LinkButton from 'components/lib/Link/LinkButton.tsx';
import useFormatTools from 'i18n/useFormatTools.ts';

const NoWrapSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionCol = styled(Col)<{ $breakpoint: ScreenMap }>`
  &&& {
    display: flex;
    justify-content: ${(props) =>
      props.$breakpoint.sm === undefined || props.$breakpoint.sm ? 'flex-end' : 'flex-start'};
    align-items: flex-start;
  }
`;

gql`
  fragment TicketOrderLineFullAdmin on TicketOrderLine {
    ...TicketOrderLineFull
    ticket {
      ...TicketListItem
      retailer {
        ...RetailerListItem
      }
      machine {
        ...MachineListItem
      }
    }
  }
  ${TicketOrderLineFullFragmentDoc}
  ${TicketListItemFragmentDoc}
  ${RetailerListItemFragmentDoc}
  ${MachineListItemFragmentDoc}
`;

gql`
  query GetTicketOrderLines(
    $cursor: String
    $filter: AllTicketOrderLinesFilterInput!
    $sortField: TicketOrderLineSortField!
    $sortDirection: SortDirection!
    $pageSize: Int = 50
  ) {
    allTicketOrderLines(
      allTicketOrderLinesFilterInput: $filter
      first: $pageSize
      after: $cursor
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalCount
      pageInfo {
        ...FullPageInfo
      }
      edges {
        node {
          ...TicketOrderLineFullAdmin
        }
      }
    }
  }
  ${FullPageInfoFragmentDoc}
  ${TicketOrderLineFullAdminFragmentDoc}
`;

gql`
  mutation SetAccounted($ticketOrderLineId: ID!) {
    setTicketOrderLineAccounted(accountedInput: { ticketOrderLineId: $ticketOrderLineId }) {
      id
      accounted
      accountedByUser {
        ...DeletedUserListItem
        ...UserListItem
      }
      accountedTimestamp
      accountedUserId
    }
  }
  ${DeletedUserListItemFragmentDoc}
  ${UserListItemFragmentDoc}
`;

interface TicketAccountingSort {
  field: TicketOrderLineSortField;
  direction: SortDirection;
}

const defaultSort: TicketAccountingSort = {
  field: TicketOrderLineSortField.Date,
  direction: SortDirection.Descending
};

function useTicketAccountingSort() {
  const [sortParam, setSortParam] = useQueryParam<TicketAccountingSort>('sort');
  const sort = sortParam || defaultSort;
  const setSort = (value: Partial<TicketAccountingSort>) => {
    setSortParam({
      ...defaultSort,
      ...sortParam,
      ...value
    });
  };
  return { sort, setSort };
}

type OrderLineListContextFilter = Pick<TicketsFilterInput, 'retailerId' | 'serialNo' | 'machineId'>;

interface Props {
  contextFilter?: OrderLineListContextFilter;
}

type ScrollProps = Pick<TableProps<TicketOrderLineFullAdminFragment>, 'scroll'>;

const OrderLineTool: React.FC<Props & ScrollProps> = (props) => {
  const { contextFilter } = props;
  const intl = useConnectIntl();
  const { formatDate } = useDateFormatTools();
  const { formatNOK } = useFormatTools();
  const { resolving, machineId, retailerId } = useMatrixNav();
  const { filter, setFilter } = useTicketAccountingToolFilter();
  const { sort, setSort } = useTicketAccountingSort();

  const { data, loading, error, fetchMore, refetch } = useGetTicketOrderLinesQuery({
    variables: {
      cursor: undefined,
      filter: {
        accounted: filter.accounted === 'all' ? undefined : filter.accounted,
        ticketOrderLineCategoryIds: filter.categoryIds,
        ticketOrderLineStorageIds: filter.storageIds,
        ticketId: undefined,
        ...contextFilter
      },
      sortField: sort.field,
      sortDirection: sort.direction
    },
    skip: resolving,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });

  const friendlyErrorMessages = error ? getFriendlyApolloErrorMessages(error) : undefined;
  const hasNextPage = data?.allTicketOrderLines?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allTicketOrderLines?.pageInfo.endCursor;

  const [setAccounted] = useSetAccountedMutation();
  const { setTicketDisplayMode } = useTicketDisplayMode();

  const [savingIds, setSavingIds] = useState<string[]>([]);

  const markSaving = useCallback(
    (id: string) => {
      const temp = [...new Set([...savingIds, id])];
      setSavingIds(temp);
    },
    [setSavingIds, savingIds]
  );

  const { screenMap, isMobile } = useScreenInfo();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<TicketOrderLineFullAdminFragment>
      | SorterResult<TicketOrderLineFullAdminFragment>[]
  ) => {
    if (!isArray(sorter)) {
      let field: TicketOrderLineSortField | undefined;

      switch (sorter.columnKey) {
        case 'ticket':
          field = TicketOrderLineSortField.TicketId;
          break;
        case 'retailer':
          field = TicketOrderLineSortField.RetailerName;
          break;
        case 'machine':
          field = TicketOrderLineSortField.MachineSerialNumber;
          break;
        case 'date':
          field = TicketOrderLineSortField.Date;
          break;
        default:
          field = TicketOrderLineSortField.Date; // TODO: we must change table sort col state to controlled..
      }

      if (sorter.order === undefined || field === undefined) {
        setSort(defaultSort);
        return;
      }

      setSort({
        field,
        direction: sorter.order === 'ascend' ? SortDirection.Ascending : SortDirection.Descending
      });
    } else {
      console.warn('multi sort not implemented');
    }
  };

  const handleReload = async () => {
    const key = 'reload_accounting_stuff';

    try {
      await refetch({
        cursor: undefined,
        filter: {
          accounted: filter.accounted === 'all' ? undefined : filter.accounted,
          ...contextFilter
        },
        sortField: sort.field,
        sortDirection: sort.direction
      });
    } catch (err) {
      message.error({
        key,
        content: 'Failed to reload'
      });
    }
  };

  const markDone = useCallback(
    (id: string) => {
      const index = savingIds.indexOf(id);
      const temp = [...savingIds];
      if (index > -1) {
        temp.splice(index, 1);
      }
      setSavingIds(temp);
    },
    [setSavingIds, savingIds]
  );

  const handleSetAccounted = useCallback(
    async (record: TicketOrderLineFullAdminFragment) => {
      const key = `set_accounted_${record.id}`;

      try {
        markSaving(record.id);
        message.loading({
          key,
          content: 'Mark order line as accounted...'
        });
        await setAccounted({
          variables: {
            ticketOrderLineId: record.id
          }
        });
        message.success({
          key,
          content: 'Order line marked as accounted!'
        });
      } catch (err) {
        message.error({
          key,
          content: 'Failed to mark order line as accounted'
        });
      } finally {
        markDone(record.id);
      }
    },
    [setAccounted, markDone, markSaving]
  );

  const columns: ColumnsType<TicketOrderLineFullAdminFragment> = [
    {
      key: 'ticket',
      title: intl.formatMsg(ticketAccountingMessages.col_ticket),
      render: (_, record) => {
        return (
          <Flex gap={'small'} align={'center'}>
            <TicketStatusIcon status={record.ticket.status} showText={false} />
            <div style={{ minWidth: 275 }}>
              <TicketTitle ticket={record.ticket} />
            </div>
          </Flex>
        );
      },
      sorter: true,
      width: 200,
      fixed: isMobile ? undefined : 'left'
    }
  ];

  if (!resolving) {
    columns.push({
      key: 'retailer',
      title: intl.formatMsg(ticketAccountingMessages.col_retailer),
      render: (_, record) => {
        return record.ticket.retailer ? (
          <div style={{ minWidth: 175 }}>
            <RetailerLink
              retailer={record.ticket.retailer}
              allowWrap={true}
              hideIcon={false}
              scopeRetailerId={retailerId}
            />
          </div>
        ) : (
          <div>Not found</div>
        );
      },
      sorter: true,
      width: 175
    });
  }

  if (!resolving) {
    columns.push({
      key: 'machine',
      title: intl.formatMsg(ticketAccountingMessages.col_machine),
      render: (_, record) => (
        <div style={{ minWidth: 175 }}>
          <MachineLink
            machine={record.ticket.machine}
            allowWrap={true}
            appendSerialNumber={true}
            itemDiffSerialNoCheck={{
              entityName: 'ticket',
              entitySerialNo: record.ticket.serialNo || undefined,
              scopeMachineId: machineId
            }}
          />
        </div>
      ),
      width: 175,
      sorter: true
    });
  }

  const commonColumns: ColumnsType<TicketOrderLineFullAdminFragment> = [
    {
      key: 'article',
      title: intl.formatMsg(ticketAccountingMessages.col_article),
      render: (_, record) => (
        <div
          style={{
            minWidth: 200
          }}
        >
          <ArticleItem key={record.id} article={record.article} showIcon={false} />
        </div>
      ),
      width: 200
    },
    {
      key: 'storage',
      title: intl.formatMsg(ticketAccountingMessages.col_storage),
      render: (_, record) => (
        <div
          style={{
            minWidth: 220
          }}
        >
          <StorageItem storage={record.articleStorage} />
        </div>
      )
    },
    {
      key: 'description',
      title: intl.formatMsg(ticketAccountingMessages.col_description),
      render: (_, record) => (
        <Flex vertical={true} style={{ minWidth: 150 }}>
          {record.description?.split('\n').map((line) => <div key={line}>{line}</div>)}
        </Flex>
      ),
      width: 150
    },
    {
      key: 'date',
      title: intl.formatMsg(ticketAccountingMessages.col_date),
      render: (_, record) => <NoWrapSpan>{formatDate(parseJSON(record.date))}</NoWrapSpan>,
      width: 100,
      sorter: true
    },

    {
      key: 'user',
      title: intl.formatMsg(ticketAccountingMessages.col_user),
      align: 'center',
      render: (_, record) => {
        return <UserAvatar user={record.user as UserListItemFragment | DeletedUser} />;
      },
      width: 50
    },
    {
      key: 'quantity',
      title: intl.formatMsg(ticketAccountingMessages.col_quantity),
      render: (_, record) => record.quantity.toFixed(2),
      width: 80
    },
    {
      key: 'price',
      title: intl.formatMsg(ticketAccountingMessages.col_unit_price),
      align: 'right',
      render: (_, record) => formatNOK(record.unitPriceOriginal),
      width: 80
    },
    {
      key: 'discount',
      width: 80,
      title: intl.formatMsg(ticketAccountingMessages.col_discount),
      align: 'right',
      render: (_, record) => {
        const discount = record.discountPercentage;

        return discount
          ? intl.formatNumber(discount / 100.0, {
              style: 'percent'
            })
          : undefined;
      }
    },
    {
      key: 'sum',
      title: intl.formatMsg(ticketAccountingMessages.col_amount),
      align: 'right',
      render: (_, record) => formatNOK(record.totalPrice),
      width: 90,
      fixed: isMobile ? undefined : 'right'
    },
    {
      key: 'actions',
      width: 80,
      fixed: isMobile ? undefined : 'right',
      title: intl.formatMsg(ticketAccountingMessages.col_actions),
      render: (_, record) => {
        return record.accounted ? (
          <Tag color={'success'} icon={<CheckOutlined />} />
        ) : (
          <Button
            type={'primary'}
            icon={<CheckCircleOutlined />}
            onClick={() => handleSetAccounted(record)}
            size={'middle'}
            disabled={savingIds.indexOf(record.id) > -1}
          />
        );
      }
    }
  ];

  columns.push(...commonColumns);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={12}>
          <TicketListDisplayModeSegmented
            value={'order-lines'}
            onChange={(value) => setTicketDisplayMode(value)}
          />
        </Col>
        {filter.accounted === false && (
          <ActionCol xs={24} sm={12} $breakpoint={screenMap}>
            <Tooltip
              title={
                'Press reload to re-fetch list of unaccounted order lines. This will remove accounted order lines from the list.'
              }
            >
              <Button type={'default'} icon={<ReloadOutlined />} onClick={handleReload}>
                Reload
              </Button>
            </Tooltip>
          </ActionCol>
        )}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: 16 }} wrap={true}>
        <Col span={24}>
          <Flex gap={16} justify={'space-between'}>
            <Radio.Group
              optionType={'button'}
              value={filter.accounted}
              onChange={(event) => {
                setFilter({ accounted: event.target.value });
              }}
            >
              <Radio.Button value={false} key={'unaccounted'}>
                Unaccounted
              </Radio.Button>
              <Radio.Button value={true} key={'accounted'}>
                Accounted
              </Radio.Button>
              <Radio.Button value={'all'} key={'all'}>
                All
              </Radio.Button>
            </Radio.Group>
            <Flex gap={16}>
              <StorageFilterSelect />
              <CategoryFilterSelect />
            </Flex>
          </Flex>
        </Col>
      </Row>

      <TableContainer $isMobile={isMobile}>
        {error && (
          <Flex justify={'center'} align={'center'} style={{ width: '100%' }}>
            <Result
              icon={<ErrorIllustration width={300} />}
              extra={
                <Space direction={'vertical'} align={'center'}>
                  <LinkButton onClick={() => window.location.reload()}>
                    {intl.formatMsg(commonMessages.reload)}
                  </LinkButton>
                </Space>
              }
              title={intl.formatMsg({
                id: 'order_line_tool.error_title',
                defaultMessage: 'Failed to load order lines'
              })}
              subTitle={
                <Space direction={'vertical'}>
                  {friendlyErrorMessages?.map((line) => <div key={line}>{line}</div>)}
                </Space>
              }
            />
          </Flex>
        )}
        {!error && (
          <Table<TicketOrderLineFullAdminFragment>
            bordered={false}
            columns={columns}
            size={'middle'}
            sortDirections={['ascend', 'descend', 'ascend']}
            rowKey={(record) => record.id}
            loading={resolving || loading}
            dataSource={data?.allTicketOrderLines?.edges?.map((c) => c.node)}
            pagination={false}
            scroll={{ x: true }}
            tableLayout={'fixed'}
            onChange={handleTableChange}
            style={{ marginBottom: hasNextPage ? 16 : 64 }}
            footer={() => {
              const count = data?.allTicketOrderLines?.totalCount;
              if (count === undefined) return <div />;
              const current = data?.allTicketOrderLines?.edges?.length;
              return (
                <div>
                  <FormattedMessage
                    id={'order_line_tool.footer_order_line_count'}
                    defaultMessage={'Showing {current} of total {count} order lines'}
                    values={{ count, current }}
                  />
                </div>
              );
            }}
          />
        )}
      </TableContainer>

      {hasNextPage && (
        <Button
          loading={loading}
          disabled={loading}
          style={{ marginBottom: 64 }}
          onClick={async () => {
            await fetchMore({
              variables: {
                cursor: nextCursor,
                filter: {
                  accounted: filter.accounted === 'all' ? undefined : filter.accounted,
                  ticketOrderLineCategoryIds: filter.categoryIds,
                  ticketOrderLineStorageIds: filter.storageIds,
                  ticketId: undefined,
                  ...contextFilter
                }
              }
            });
          }}
        >
          {intl.formatMsg(commonMessages.load_more)}
        </Button>
      )}
    </>
  );
};

const TableContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  ${(props) =>
    props.$isMobile &&
    `
    margin-left: -16px;
    margin-right: -16px;
    border: none;
    border-radius: 0;
  `}
`;

export default OrderLineTool;
