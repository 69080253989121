import React from 'react';
import EventListItemDetails from 'components/events/EventList/EventListItemDetails.tsx';
import styled, { css } from 'styled-components';
import ExpandableListItem from 'components/lib/List/ExpandableListItem.tsx';
import { EventListItemData } from 'components/events/EventList/eventListItemData.ts';
import Link from 'components/lib/Link/Link.tsx';
import { CompanyFilledIcon } from 'components/icons/Icons.tsx';
import ListItemTitle from 'components/lib/List/ListItemTitle.tsx';
import EventLevelTag from 'components/events/EventLevelTag.tsx';

const Item = styled.div<{ $linkColCount: number }>`
  flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (min-width: 480px) {
    display: grid;
    gap: 8px;
    grid-template-columns: ${(props) => {
      const baseColumns = `[col-title] 2fr`;
      const linkColumns = Array.from({ length: props.$linkColCount })
        .map(() => '1fr')
        .join(' ');
      return `${baseColumns} ${linkColumns} [col-level] 100px [col-timestamp] 150px`;
    }};
  }
`;

const Col = styled.div<{ $muted: boolean; $order?: number }>`
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.$muted &&
    css`
      color: ${(props) => props.theme.ant.colorTextSecondary};
      &&& {
        .anticon {
          color: ${props.theme.ant.colorTextSecondary};
        }
      }
    `};

  order: ${(props) => props.$order};
  @media (min-width: 480px) {
    order: unset;
  }
`;

const TitleCol = styled(Col)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  order: 1;
  @media (min-width: 480px) {
    grid-column: col-title;
    order: unset;
  }
  ${(props) =>
    props.$muted &&
    css`
      text-decoration: line-through;
    `}
`;

const LevelCol = styled(Col)`
  //order: 0;

  @media (min-width: 480px) {
    grid-column: col-level;
  }
`;

const TimestampCol = styled(Col)`
  order: 1;
  @media (min-width: 480px) {
    grid-column: col-timestamp;
    justify-self: end;
    order: unset;
  }
`;

const CustomLink = styled(Link)<{ $muted: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  && {
    .anticon {
      margin-right: 6px;
    }
  }

  ${(props) =>
    props.$muted &&
    css`
      color: ${(props) => props.theme.ant.colorTextSecondary};
    `}
`;

interface Props {
  index: number;
  item: EventListItemData;
  expanded: boolean;
  onToggleExpanded: () => void;
}

const EventListItem: React.FC<Props> = ({ expanded, item, onToggleExpanded }) => {
  const linkColCount = (item.retailerLink ? 1 : 0) + (item.machineLink ? 1 : 0);

  return (
    <ExpandableListItem
      key={item.entityId}
      expanded={expanded}
      onToggleExpanded={onToggleExpanded}
      background={item.background}
      title={
        <Item $linkColCount={linkColCount}>
          <TitleCol $muted={item.muted}>
            <ListItemTitle icon={item.icon} title={item.title} />
          </TitleCol>
          {item.retailerLink && (
            <Col $muted={item.muted} $order={2}>
              <CustomLink to={item.retailerLink.href} $muted={item.muted}>
                <CompanyFilledIcon />
                {item.retailerLink.text}
              </CustomLink>
            </Col>
          )}
          {item.machineLink && (
            <Col $muted={item.muted} $order={3}>
              <CustomLink to={item.machineLink.href} $muted={item.muted}>
                {item.machineLink.icon}
                {item.machineLink.text}
              </CustomLink>
            </Col>
          )}
          <LevelCol $muted={item.muted}>
            <EventLevelTag level={item.level} />
          </LevelCol>
          <TimestampCol $muted={item.muted}>{item.formattedDate}</TimestampCol>
        </Item>
      }
    >
      <EventListItemDetails eventId={item.entityId} />
    </ExpandableListItem>
  );
};

// NOTE: This will do a shallow comparison of props, worth it or not?
export default React.memo(EventListItem);
