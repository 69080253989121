import React, { CSSProperties, useMemo } from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import { motion } from 'framer-motion';
import useRefWidth from 'hooks/useRefWidth.ts';
//
interface DetectedObjectData {
  naturalX: number;
  naturalY: number;
  naturalWidth: number;
  naturalHeight: number;
  color: string;
}

interface Props {
  detectedObjects: DetectedObjectData[];
  image: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
  style?: CSSProperties;
  enableAnimation?: boolean;
  showLink?: boolean;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  img {
    flex: 1 1 auto;
    z-index: 1;
  }
`;

const StyledMotionSvg = styled(motion.svg)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  circle,
  rect,
  line {
    stroke-width: 16px;
    stroke-linecap: round;
    fill: transparent;
  }
`;

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = 0.2 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

const instant = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: () => {
    const delay = 0;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 0, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

const PredictionSampleImage: React.FC<Props> = ({
  detectedObjects,
  image,
  style,
  enableAnimation,
  showLink
}) => {
  const naturalWidth = image.width;
  const naturalHeight = image.height;
  const containerRef = React.useRef<HTMLDivElement>(null);

  // We keep track of image loaded state to delay rendering boxes until the image is loaded.
  const [loadedImageId, setLoadedImageId] = React.useState<string | undefined>(undefined);
  const isImageLoaded = loadedImageId === image.src;

  const { width: containerWidth, setWidth: setContainerWidth } = useRefWidth(containerRef);

  // Before the image is loaded, we know the natural dimensions (naturalWidth, naturalHeight)
  // and we know the container width.
  // We can calculate the container height based on that.
  const calculatedHeight = useMemo(() => {
    if (!containerWidth) {
      return undefined;
    }
    return (naturalHeight / naturalWidth) * containerWidth;
  }, [containerWidth, naturalHeight, naturalWidth]);

  const content = (
    <Container
      ref={containerRef}
      style={{
        ...style,
        minHeight: calculatedHeight
      }}
    >
      <Image
        key={image.src}
        src={image.src}
        alt={image.alt}
        width={'100%'}
        height={calculatedHeight}
        preview={false}
        onLoad={() => {
          if (containerRef.current) {
            // NOTE: This is not needed now since we set the calculated img height explicitly
            setContainerWidth(containerRef.current.offsetWidth);
          }
          setLoadedImageId(image.src);
        }}
      />

      {isImageLoaded && (
        <StyledMotionSvg
          width={containerWidth}
          height={calculatedHeight}
          viewBox={`0 0 ${naturalWidth} ${naturalHeight}`}
          xmlns='http://www.w3.org/2000/svg'
          initial={'hidden'}
          animate={'visible'}
        >
          {detectedObjects.map((box, index) => (
            <motion.rect
              key={index}
              x={box.naturalX}
              y={box.naturalY}
              width={box.naturalWidth}
              height={box.naturalHeight}
              stroke={box.color}
              variants={enableAnimation ? draw : instant}
              custom={index + 1}
              rx={20}
            />
          ))}
        </StyledMotionSvg>
      )}
    </Container>
  );

  const show = showLink !== false;

  return show ? (
    <a href={image.src} target={'_blank'} rel={'noreferrer'}>
      {content}
    </a>
  ) : (
    content
  );
};

export default PredictionSampleImage;
