import React from 'react';
import MachineNav from 'layouts/components/MachineNav/MachineNav';
import { Drawer, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import { closeMobileSearchDrawer, selectIsMobileSearchVisible } from 'redux/machineNavSlice.ts';
import styled from 'styled-components';
import { VendanorButterflySquare } from 'layouts/components/icons/VendanorButterfly.tsx';
import { appVersion } from 'common/appVersion.ts';

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
`;

const ScopeDrawerMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobileSearchVisible = useAppSelector(selectIsMobileSearchVisible);

  return (
    <Drawer
      title={'Scope'}
      placement={'right'}
      onClose={() => dispatch(closeMobileSearchDrawer())}
      open={isMobileSearchVisible}
      width={'85%'}
      styles={{
        body: {
          padding: 0
        }
      }}
      footer={
        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
          Connect v{appVersion.version}
        </Typography.Text>
      }
      extra={
        <IconBox>
          <VendanorButterflySquare />
        </IconBox>
      }
    >
      <MachineNav />
    </Drawer>
  );
};

export default ScopeDrawerMobile;
