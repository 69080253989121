import { InMemoryCache } from '@apollo/client';
import { TypedTypePolicies } from 'generated/apollo-helpers';
import { relayStylePagination } from '@apollo/client/utilities';

/***
 * Apollo cache setup
 * - Setup relay style pagination for all queries that need it here
 * - Configure keyArgs to decide which query inputs should result in a new cache on "list queries"
 *   If we change an input parameter on the backend, we need to update the keyArgs here
 *   to make sure that the cache is invalidated.
 * - Configure merge functions to decide how to merge incoming data with existing cache data
 * - Configure keyFields to decide which fields should be used as cache keys
 * - Configure cache redirects to make sure the cache from "list queries" is reused in single item queries
 *
 * It is also possible to use local schema and redux-like selectors here for
 * global state management. We tested this with the machineNavFieldPolicy, but
 * I think the code will be cleaner and easier to understand if we use redux
 * or context for global state management instead.
 */
const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      allLanguages: relayStylePagination(),
      allTimezones: relayStylePagination(),
      allMachines: relayStylePagination(['allMachineFilter']),
      allRetailers: relayStylePagination(['retailerFilter']), // NOTE: filter generates new cache
      allTickets: relayStylePagination(['filter']),
      allTicketOrderLines: relayStylePagination(['allTicketOrderLinesFilterInput']),
      allDataTransferMessages: relayStylePagination(['filter']),
      allTranslations: relayStylePagination(['filter', 'languages']),
      allEvents: relayStylePagination(['filter']),
      // cache redirects
      retailer: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Retailer',
            retailerId: args?.retailerId, // args.retailerId is the argument to the retailer query. The first retailerId must match the keyArgs in the Retailer typePolicy.
          });
        },
      },
      machine: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Machine',
            machineId: args?.machineId,
          });
        },
      },
      ticket: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Ticket',
            ticketId: args?.ticketId,
          });
        },
      },
      event: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Event',
            entityId: args?.id,
          });
        },
      },
    },
  },
  User: {
    fields: {
      mostUsedArticle: {
        merge: true,
      },
      mostUsedArticleStorage: {
        merge: true,
      },
      connectAppSettings: {
        // Custom redux merge function, tell apollo that settings are owned by user object.
        merge: true,
      },
      permissions: {
        merge: false,
      },
    },
  },
  DataTransferMessage: {
    fields: {
      stages: {
        merge: false,
      },
    },
  },
  Retailer: {
    keyFields: ['retailerId'], // We must use the integer retailerId as key for the cache to be able to match the retailer query and reuse cache
    fields: {
      machineTags: {
        merge: false, // custom redux merge, no paging, let new stuff overwrite existing stuff
      },
      palette: {
        merge: true, // custom redux merge, tell apollo that palette is owned by retailer object.
      },
      products: relayStylePagination(['filter', 'retailerId']),
      permissions: {
        merge: false,
      },
    },
  },
  Machine: {
    keyFields: ['machineId'],
    fields: {
      tags: {
        merge: false, // same as (existing, incoming) => incoming. So incoming tags will always overwrite existing tags in cache
      },
      properties: {
        keyArgs: ['filter'], // in theory, this should split cache per filter + maintain all items correctly by id
      },
      permissions: {
        merge: false,
      },
    },
  },
  Ticket: {
    keyFields: ['ticketId'], // NOTE: When querying for a ticket we must always include the ticketId in the query
    fields: {
      // Custom redux merge function, we don't have any paging enabled here,
      // tells apollo that incoming data always replaces existing data
      orderLines: {
        merge: false, // same as (existing, incoming) => incoming
      },
      comments: {
        merge: false,
      },
      permissions: {
        merge: false,
      },
    },
  },
  TicketComment: {
    fields: {
      assets: {
        merge: false, // custom redux merge, no paging, let new stuff overwrite existing stuff
      },
    },
  },
  Event: {
    keyFields: ['entityId'],
  },
  MachineProperty: {
    fields: {
      valueHistory: {
        merge: false,
      },
    },
  },
};

const apolloCache = new InMemoryCache({
  typePolicies: typePolicies,
});

export default apolloCache;
