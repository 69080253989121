import React, { useCallback, useEffect, useState } from 'react';
import uploadMessages from 'components/lib/upload/uploadMessages';
import styled from 'styled-components';
import { Empty, Typography, Upload, Image, Spin, Button, Popconfirm } from 'antd';
import { AssetListItemFragment } from 'generated/types';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
const { Text } = Typography;
import { mapAsset } from 'components/lib/upload/mapAsset';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useMessageApi from 'components/global/useMessageApi.ts';

interface Props {
  accept?: string;
  maxFileSize?: number;
  asset?: AssetListItemFragment | null;
  handleUpload: (options: RcCustomRequestOptions) => void;
  loading?: boolean;
  empty?: React.ReactNode;
  deleteText?: string;
  uploadText?: React.ReactNode;
  onDelete?: () => void;
}

const UploadContainer = styled.div`
  border: 1px solid ${(props) => props.theme.ant.colorBorder};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  transition: all 0.3s;

  width: 300px;
  height: 300px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  overflow: hidden;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &&& {
    .ant-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-image-img {
      max-width: 80%;
      max-height: 80%;
      aspect-ratio: auto;
    }
    .ant-empty-image {
      height: 75px;
    }
    .ant-empty-description {
      color: ${(props) => props.theme.ant.colorTextSecondary};
    }
  }
`;

const InnerImageContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-top: 1px dashed ${(props) => props.theme.ant.colorBorder};
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  &&& {
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: ${(props) => props.theme.ant.colorBorder};

    height: 48px;

    .ant-upload {
      padding: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-right: none;
      border-bottom: none;
      border-left: none;

      &:hover {
        border-color: ${(props) => props.theme.ant.colorBorder};
      }
    }
  }
`;

/***
 * ImageUpload is a component that allows you to upload an image.
 * @param asset
 * @param handleUpload
 * @param loading
 * @param empty
 * @param uploadText
 * @param onDelete
 * @param deleteText
 * @param accept
 * @constructor
 */
const UploadImage: React.FC<Props> = ({
  asset,
  handleUpload,
  loading,
  empty,
  uploadText,
  onDelete,
  deleteText,
  accept,
  maxFileSize
}) => {
  const intl = useConnectIntl();
  const [files, setFiles] = useState<Array<UploadFile<VnFile>> | undefined>(undefined);

  useEffect(() => {
    if (asset) {
      setFiles([mapAsset(asset)]);
    } else {
      setFiles([]);
    }
  }, [asset]);

  const handleChange = (info: UploadChangeParam) => {
    setFiles(info.fileList);
  };

  const imgUrl = asset?.url;
  const hasImage = !!imgUrl;

  const handleClickDelete = () => {
    onDelete?.();
  };

  const message = useMessageApi();

  const handleBeforeUpload = useCallback(
    (file: File) => {
      if (maxFileSize !== undefined && file.size > maxFileSize) {
        const formatSize = (size: number) => {
          return size >= 1024 * 1024
            ? `${(size / (1024 * 1024)).toFixed(2)} MB`
            : `${(size / 1024).toFixed(2)} KB`;
        };

        const formattedMaxSize = formatSize(maxFileSize);
        const formattedFileSize = formatSize(file.size);

        message.error(
          `Upload failed: ${file.name} is too large. Maximum allowed size is ${formattedMaxSize}, but the file is ${formattedFileSize}.`
        );

        return Upload.LIST_IGNORE; // Prevents file upload
      }
      return true; // Allows file upload
    },
    [message, maxFileSize]
  );

  return (
    <UploadContainer>
      <ImageContainer>
        {loading && <Spin />}
        {!loading && !imgUrl && (empty || <Empty />)}
        {!loading && imgUrl && (
          <InnerImageContainer>
            <a href={imgUrl} target={'_blank'} rel={'noreferrer'}>
              <Image src={imgUrl} preview={false} />
            </a>
          </InnerImageContainer>
        )}
      </ImageContainer>

      {!hasImage && (
        <StyledUploadDragger
          accept={accept || 'image/*'}
          listType={'picture-card'}
          fileList={files}
          locale={{
            downloadFile: intl.formatMessage(uploadMessages.downloadFile),
            uploading: intl.formatMessage(uploadMessages.uploading),
            removeFile: intl.formatMessage(uploadMessages.removeFile),
            uploadError: intl.formatMessage(uploadMessages.uploadError),
            previewFile: intl.formatMessage(uploadMessages.previewFile)
          }}
          maxCount={1}
          multiple={false}
          openFileDialogOnClick={true}
          showUploadList={false} // hide default inline file list
          onChange={handleChange}
          customRequest={handleUpload}
          beforeUpload={handleBeforeUpload}
        >
          <Text type={'secondary'}>
            {uploadText ||
              intl.formatMessage(uploadMessages.uploadImageHint, {
                icon: <UploadOutlined />
              })}
          </Text>
        </StyledUploadDragger>
      )}

      {hasImage && (
        <DeleteBox>
          <Popconfirm
            title={intl.formatMessage(commonMessages.confirm_delete_item)}
            onConfirm={handleClickDelete}
            okText={intl.formatMessage(commonMessages.yes)}
            cancelText={intl.formatMessage(commonMessages.no)}
          >
            <Button type={'text'} icon={<DeleteOutlined />} danger={true}>
              {deleteText || intl.formatMessage(commonMessages.delete)}
            </Button>
          </Popconfirm>
        </DeleteBox>
      )}
    </UploadContainer>
  );
};

export default UploadImage;
