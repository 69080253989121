import React, { CSSProperties } from 'react';
import { motion } from 'framer-motion';
import { Flex, Typography } from 'antd';
import { ErrorIcon, WarningIcon } from 'components/icons/Icons.tsx';

const AnimatedMessage: React.FC<{
  type: 'warn' | 'error' | 'loading';
  message: string;
  code?: string;
  style?: CSSProperties;
}> = ({ message, type, code, style }) => (
  <motion.div
    initial={{ opacity: 0, y: 5 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -5 }}
  >
    <Flex gap={16} align={'center'} style={{ maxWidth: 'min(80vw, 400px)', ...style }}>
      <Typography.Text>
        {type === 'error' && <ErrorIcon />}
        {type === 'warn' && <WarningIcon />}
      </Typography.Text>
      <Typography.Text>{message}</Typography.Text>
      {code && (
        <Typography.Text code={true} style={{ wordBreak: 'keep-all' }}>
          {code}
        </Typography.Text>
      )}
    </Flex>
  </motion.div>
);

export default AnimatedMessage;
