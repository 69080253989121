import React, { createContext, ReactNode, useCallback, useContext } from 'react';

interface ScrollRef {
  key: string;
  node: HTMLDivElement | null;
}

interface ScrollContextType {
  setScrollRef: (node: HTMLDivElement | null, key: string) => void;
  scrollRef?: ScrollRef;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScrollContext must be used within a ScrollProvider');
  }
  return context;
};

export const ScrollProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [currentScrollRef, setCurrentScrollRef] = React.useState<ScrollRef | undefined>(undefined);

  const setScrollRef = useCallback((node: HTMLDivElement | null, key: string) => {
    // console.log(`setScrollRef: ${key}`);
    setCurrentScrollRef({
      key,
      node,
    });
  }, []);

  return (
    <ScrollContext.Provider value={{ setScrollRef, scrollRef: currentScrollRef }}>
      {children}
    </ScrollContext.Provider>
  );
};
