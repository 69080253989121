/***
 * Global layout for Connect App.
 */
export interface Layout {
  paddingMobile: string;
  paddingDesktop: string;
  mainNavWidth: string;
  machineNavWidth: string;

  breadcrumbHeight: string;
  tabsHeight: string;
  headerMobileHeight: string;
  footerMobileHeight: string;
}

const connectAppLayout: Layout = {
  paddingMobile: '16px',
  paddingDesktop: '8%',
  mainNavWidth: '64px',
  machineNavWidth: '256px',

  breadcrumbHeight: '46px', // 12px padding top, 12 px padding bottom, 22px line-height = 46px
  tabsHeight: '46px',
  headerMobileHeight: '46px',
  footerMobileHeight: '46px',
};

export default connectAppLayout;
