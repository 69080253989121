import { useCallback } from 'react';
import {
  DeletedUserListItemFragment,
  MachineListItemFragment,
  UserListItemFragment
} from 'generated/types';
import useConnectIntl from 'i18n/useConnectIntl.ts';

/***
 * Reusable formatting tools that requires i18n
 */
function useFormatTools() {
  const intl = useConnectIntl();

  const formatUserName = useCallback(
    (
      user: UserListItemFragment | DeletedUserListItemFragment | null | undefined,
      options?: {
        capitalize?: boolean;
        includeEmail?: boolean;
      }
    ) => {
      if (!user) {
        return undefined;
      }
      const name =
        user.__typename === 'DeletedUser'
          ? intl.formatMessage({
              id: 'use_format_tools.deleted_user',
              defaultMessage: 'deleted user',
              description: 'The rendered text when a referenced user is deleted'
            })
          : user.__typename === 'User'
            ? user.name
            : undefined;

      if (name && options?.capitalize) {
        return name?.charAt(0).toUpperCase() + name.slice(1);
      }

      if (name && options?.includeEmail && user.__typename === 'User' && user.emailAddress) {
        return `${name} (${user.emailAddress})`;
      }

      return name;
    },
    [intl]
  );

  const formatCountry = useCallback(
    (country: string | null | undefined) => {
      if (!country) {
        return undefined;
      }
      return intl.formatMessage({
        id: `country.${country.toLowerCase()}`,
        defaultMessage: country
      });
    },
    [intl]
  );

  const formatMachineTitle = useCallback(
    (
      machine?: Pick<MachineListItemFragment, 'serialNo' | 'location'>,
      options?: {
        includeSerialNo?: boolean;
      }
    ) => {
      if (options?.includeSerialNo && machine?.location && machine.location.length > 0) {
        return `${machine.location} (${machine.serialNo})`;
      }
      return machine?.location || machine?.serialNo || undefined;
    },
    []
  );

  const formatNOK = useCallback(
    (value: number) => {
      return `${intl.formatNumber(value, { style: 'decimal', minimumFractionDigits: 2 })}\u00A0NOK`;
    },
    [intl]
  );

  return {
    formatUserName, // return user.name or i18n deleted user
    formatCountry, // return internationalized country name
    formatMachineTitle, // return machine location or serialNo or undefined
    formatNOK // format a number with two decimals (according to locale) and suffix with NOK
  };
}

export default useFormatTools;
