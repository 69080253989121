import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Divider, Flex } from 'antd';
import { TicketMarkerItem } from 'components/ticket/TicketMap/TicketMarker';
import { gql } from '@apollo/client';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import { useGetTicketMarkerInfoAlternativeQuery } from 'generated/types';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import MapInfoWindow from 'components/lib/ConnectMap/MapInfoWindow.tsx';
import { AnimatePresence } from 'framer-motion';
import MachineLink from 'components/machine/MachineLink/MachineLink.tsx';
import RetailerLink from 'components/retailer/RetailerLink/RetailerLink.tsx';
import TicketListItemMini from 'components/ticket/TicketList/TicketListItemMini.tsx';
import Link from 'components/lib/Link/Link.tsx';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import DeactivatedMachineTag from 'components/machine/DeactivatedMachineTag/DeactivatedMachineTag.tsx';

interface Props {
  marker: TicketMarkerItem;
  onClose?: () => void;
}

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

gql`
  query GetTicketMarkerInfoAlternative($machineId: Int!, $filter: TicketsFilterInput!) {
    allTickets(first: 3, sortField: SEVERITY, sortDirection: ASCENDING, filter: $filter) {
      totalCount
      edges {
        node {
          ...TicketListItem
        }
      }
    }
    machine(machineId: $machineId) {
      ...MachineListItem
      retailer {
        ...RetailerListItem
      }
    }
  }
`;

const TicketMarkerInfoWindow: React.FC<Props> = (props) => {
  const { marker, onClose } = props;
  const { ticketFilter } = useTicketFilter();
  const { machineId, retailerId } = useMatrixNav();

  const { data } = useGetTicketMarkerInfoAlternativeQuery({
    variables: {
      machineId: marker.machine.machineId,
      filter: {
        ticketSeverities: ticketFilter.severities,
        ticketStatuses: ticketFilter.statuses,
        ticketTypeIds: ticketFilter.ticketTypeIds,
        closedFrom: ticketFilter.closedFrom,
        closedTo: ticketFilter.closedTo,
        createdFrom: ticketFilter.createdFrom,
        createdTo: ticketFilter.createdTo,
        assignedToUserIds: ticketFilter.assignedToUserIds,
        includeUnassignedUsers: ticketFilter.includeUnassignedUsers,
        search: ticketFilter.search,
        ticketTags: ticketFilter.tags,
        dueTo: ticketFilter.dueTo,
        includeNoDueDate: true,
        machineId: marker.ticket?.machineIdRaw,
        // NOTE: This will only get tickets for the current machine instance!
        // (also for different serialNo's!)
        // We can't use serialNo here,
        // because that would result in a logic OR on the backend
        // Consider adding possibility to filter for machineId AND serialNo to the backend later?
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const intl = useConnectIntl();
  const { getUrlToMachine } = useMatrixNav();

  // When fetching tickets for the machine that is in scope, we don't need to filter the tickets
  // since these tickets are also included in the machine's ticket list.
  // But when fetching tickets for a different machine, we need to filter the tickets to only include
  // tickets where both machineId and serialNo match.
  const isMachineInScope = marker.ticket?.machineIdRaw === machineId;
  const filteredTickets = useMemo(() => {
    const tickets = data?.allTickets?.edges?.map((c) => c.node);
    if (isMachineInScope) {
      return tickets;
    } else {
      return tickets?.filter((c) => c.serialNo === marker.machine.serialNo);
    }
  }, [data?.allTickets?.edges, isMachineInScope, marker.machine.serialNo]);

  if (!marker.position) {
    return null;
  }

  const ticketCount = data?.allTickets?.totalCount;
  const moreTicketsCount = ticketCount !== undefined ? Math.max(ticketCount - 3, 0) : 0;

  const openOrClosedTicketsMessage =
    moreTicketsCount > 0
      ? intl.formatMsg(
          {
            id: 'ticket_marker_info_window.ticket_message',
            defaultMessage: 'And {count, plural, one {# more ticket} other {# more tickets}}',
          },
          {
            count: moreTicketsCount,
          }
        )
      : undefined;

  const machineUrl = getUrlToMachine(marker.machine.machineId).replace(
    'tm=%2522map%2522',
    'tm=%2522list%2522'
  );
  const divider = <Divider style={{ marginTop: 4, marginBottom: 4 }} />;

  return (
    <MapInfoWindow
      position={marker.position}
      title={
        <Flex vertical={true} gap={4} style={{ fontSize: '1em' }}>
          <Flex gap={'small'} align={'center'}>
            <MachineLink
              machine={marker.machine}
              allowWrap={true}
              appendSerialNumber={true}
              urlFormatter={(url) => url.replace('tm=%2522map%2522', 'tm=%2522list%2522')}
              itemDiffSerialNoCheck={{
                entityName: 'ticket',
                scopeMachineId: marker.machine.machineId,
              }}
            />
            {!marker.machine.active ? <DeactivatedMachineTag /> : null}
          </Flex>
          {data && (
            <RetailerLink
              retailer={data.machine.retailer}
              allowWrap={true}
              urlFormatter={(url) => url.replace('tm=%2522map%2522', 'tm=%2522list%2522')}
              scopeRetailerId={retailerId}
            />
          )}
        </Flex>
      }
      onClose={() => onClose?.()}
    >
      {/* machines that are IN SCOPE, no filtering. */}
      <Container>
        {divider}
        <AnimatePresence mode={'sync'}>
          {filteredTickets &&
            filteredTickets.map((ticket) => (
              <TicketListItemMini
                ticket={ticket}
                key={ticket.ticketId}
                serialNoInScope={data?.machine.serialNo}
              />
            ))}
        </AnimatePresence>
        {openOrClosedTicketsMessage && (
          <>
            {divider}
            <Link to={machineUrl}>{openOrClosedTicketsMessage}</Link>
          </>
        )}
      </Container>
    </MapInfoWindow>
  );
};

export default TicketMarkerInfoWindow;
