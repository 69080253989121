import React, { memo, ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Arrow from 'components/lib/Menu/Arrow.tsx';

export type ExpandableListItemBackground =
  | 'trace'
  | 'debug'
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'muted';

interface Props {
  title: ReactNode;
  children?: ReactNode; // content visible when expanded
  expanded: boolean;
  onToggleExpanded?: () => void;
  background?: ExpandableListItemBackground;
}

const Container = styled.div<{ $background?: ExpandableListItemBackground }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;

  ${(props) =>
    props.$background === 'trace' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemTrace};
    `}
  ${(props) =>
    props.$background === 'debug' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemTrace};
    `}
  
  ${(props) =>
    props.$background === 'warning' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemWarning};
    `}
  ${(props) =>
    props.$background === 'error' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemError};
    `}
  ${(props) =>
    props.$background === 'success' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemSuccess};
    `}
  ${(props) =>
    props.$background === 'info' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemInfo};
    `}
  ${(props) =>
    props.$background === 'muted' &&
    css`
      background: ${(props) => props.theme.connect.colors.colorBgItemMuted};
    `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 4px;
  @media (min-width: 480px) {
    grid-gap: 8px;
  }
`;

const HeaderGrid = styled(Grid)`
  cursor: pointer;
  overflow: hidden;
  padding: 12px 24px;
`;

const ArrowContainer = styled.div`
  width: 16px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.ant.colorText};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: ${(props) => props.theme.ant.colorText};
  overflow: hidden;
`;

const ContentGrid = styled(Grid)`
  background: ${(props) => props.theme.ant.colorBgContainer};
`;

const ExpandedContentCol = styled.div`
  grid-column: 1 / span 2;
  @media (min-width: 480px) {
    grid-column: 2;
  }
  overflow-x: hidden;
  padding: 0 24px;
`;

/***
 * ExpandableListItem, a list item that can be expanded to show more content
 * @param title List item title (h4) link or text
 * @param children content visible when expanded
 * @param expanded is the item expanded
 * @param onToggleExpanded callback when the item is expanded
 * @param background a background to highlight the row
 */
const ExpandableListItem: React.FC<Props> = ({
  title,
  expanded,
  onToggleExpanded,
  children,
  background
}) => {
  const renderedContent = useMemo(() => {
    if (!expanded) return null;
    return (
      <ContentGrid>
        <ExpandedContentCol>{children}</ExpandedContentCol>
      </ContentGrid>
    );
  }, [expanded, children]);

  return (
    <Container $background={background}>
      <HeaderGrid onClick={() => onToggleExpanded?.()}>
        <ArrowContainer>
          <Arrow $open={expanded} className={'connect-expand-arrow'} />
        </ArrowContainer>
        <Title>{title}</Title>
      </HeaderGrid>
      {renderedContent}
    </Container>
  );
};

export default memo(ExpandableListItem);
