import React from 'react';
import { FallbackRender } from '@sentry/react';
import { Alert } from 'antd';

// NOTE: There is an antd/es/alert/ErrorBoundary component we could use, but we DIY to integrate with Sentry
const fallbackRender: FallbackRender = ({ error, componentStack }) => {
  // check if error is an instance of Error
  let msg = 'error';
  if (error instanceof Error) {
    msg = error.message;
  }

  return (
    <Alert
      type={'error'}
      message={msg}
      description={<pre style={{ fontSize: '0.9em', overflowX: 'auto' }}>{componentStack}</pre>}
    />
  );
};

export default fallbackRender;
