import React, { useCallback } from 'react';
import { useAppSelector } from 'redux/store.ts';
import { selectCurrentLocale } from 'i18n/i18nSlice.ts';
import { BaseRangePickerProps } from 'rc-picker/lib/PickerInput/RangePicker';
import { NoUndefinedRangeValueType } from 'rc-picker/es/PickerInput/RangePicker';
const FnsDateRangePickerDa = React.lazy(() => import('./FnsDateRangePickerDa'));
const FnsDateRangePickerDe = React.lazy(() => import('./FnsDateRangePickerDe'));
const FnsDateRangePickerEn = React.lazy(() => import('./FnsDateRangePickerEn'));
const FnsDateRangePickerEt = React.lazy(() => import('./FnsDateRangePickerEt'));
const FnsDateRangePickerFi = React.lazy(() => import('./FnsDateRangePickerFi'));
const FnsDateRangePickerFr = React.lazy(() => import('./FnsDateRangePickerFr'));
const FnsDateRangePickerNl = React.lazy(() => import('./FnsDateRangePickerNl'));
const FnsDateRangePickerNo = React.lazy(() => import('./FnsDateRangePickerNo'));
const FnsDateRangePickerSv = React.lazy(() => import('./FnsDateRangePickerSv'));

type PropsExceptControlled = Omit<
  BaseRangePickerProps<Date>,
  'value' | 'defaultValue' | 'onChange' | 'onOk' | 'locale' | 'generateConfig'
>;

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface CustomRangePickerProps {
  value?: DateRange;
  defaultValue?: DateRange;
  onChange?: (range: DateRange) => void;
  onOk?: (range: DateRange) => void;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight' | undefined; // type conflict, adding here to fix
}

const DateRangePicker: React.FC<PropsExceptControlled & CustomRangePickerProps> = ({
  value,
  defaultValue,
  onChange,
  onOk,
  placement,
  ...rest
}) => {
  const localeKey = useAppSelector(selectCurrentLocale);
  let picker: React.ReactNode = null;

  const handleChange = useCallback(
    (dates: NoUndefinedRangeValueType<Date> | null) => {
      const [start = null, end = null] = dates ?? [];
      onChange?.({ start, end });
    },
    [onChange]
  );

  const handleOk = useCallback(
    (dates: NoUndefinedRangeValueType<Date>) => {
      const [start = null, end = null] = dates;
      onOk?.({ start, end });
    },
    [onOk]
  );

  switch (localeKey) {
    case 'da':
      picker = (
        <FnsDateRangePickerDa
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'de':
      picker = (
        <FnsDateRangePickerDe
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'en':
      picker = (
        <FnsDateRangePickerEn
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'et':
      picker = (
        <FnsDateRangePickerEt
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'fi':
      picker = (
        <FnsDateRangePickerFi
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'fr':
      picker = (
        <FnsDateRangePickerFr
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'nl':
      picker = (
        <FnsDateRangePickerNl
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'no':
      picker = (
        <FnsDateRangePickerNo
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'sv':
      picker = (
        <FnsDateRangePickerSv
          placement={placement}
          value={[value?.start, value?.end]}
          defaultValue={[defaultValue?.start, defaultValue?.end]}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    default:
      console.warn('Unsupported locale', localeKey);
      picker = null;
  }

  return picker;
};

export default DateRangePicker;
