import React, { isValidElement, useState } from 'react';
import useAntLocale from 'i18n/useAntLocale.ts';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import {
  connect,
  selectIsAppDataRestored,
  selectIsOnline,
  selectIsServerAvailable,
  selectIsServerPinging,
  selectServerError
} from 'redux/appSlice.ts';
import styled from 'styled-components';
import VendanorLogo from 'layouts/components/icons/VendanorLogo.tsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, ConfigProvider } from 'antd';
import useReduxOnlineStatus from 'redux/useReduxOnlineStatus.ts';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { getAntThemeFromConnectTheme } from 'themes/useAntThemeConfig.ts';
import { useLocalTheme } from 'pages/util/AppProvider/LocalThemeProvider.tsx';
import AnimatedMessage from 'pages/util/AppProvider/AnimatedMessage.tsx';

const InitializingPage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: hidden;
  gap: 20px;
`;

const Center = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface Props {
  children?: React.ReactNode;
}

/***
 * This component
 * - renders Vendanor logo while the app is initializing
 * - show a retry button if connection fails
 * - syncs online state with redux
 * */
const AppProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  useReduxOnlineStatus(); // sync online status with redux
  const { loading: loadingAntLocale } = useAntLocale();
  const isDataRestored = useAppSelector(selectIsAppDataRestored);
  const isServerAvailable = useAppSelector(selectIsServerAvailable);
  const isPinging = useAppSelector(selectIsServerPinging);
  const serverError = useAppSelector(selectServerError);
  const isOnline = useAppSelector(selectIsOnline);
  const breakpoint = useBreakpoint();
  const localTheme = useLocalTheme();
  const antTheme = getAntThemeFromConnectTheme(localTheme);
  const isResponsiveReady = breakpoint.lg !== undefined; // To avoid rendering loading state in ResponsiveGate

  const handleRetry = () => {
    dispatch(connect());
    setRetryCount((prev) => prev + 1);
  };

  const initialized =
    isDataRestored && isServerAvailable && !isPinging && !loadingAntLocale && isResponsiveReady;
  const showOfflineWarning = !isOnline && !isPinging;

  const [retryCount, setRetryCount] = useState(0);

  return (
    <ConfigProvider theme={antTheme}>
      <AnimatePresence mode={'wait'}>
        {!initialized && (
          <InitializingPage
            key={'init'}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.15 } }}
          >
            <Center layout={true}>
              <motion.div layout={true}>
                <VendanorLogo width={300} />
              </motion.div>
              <AnimatePresence mode={'wait'}>
                {serverError?.message && (
                  <AnimatedMessage
                    message={serverError.message}
                    key={`serverError_${retryCount}`}
                    type={'error'}
                    code={serverError?.status?.toString()}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence mode={'wait'}>
                {showOfflineWarning && (
                  <AnimatedMessage
                    message={'No internet connection'}
                    key={`offlineWarning_${retryCount}`}
                    type={'warn'}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {serverError && (
                  <motion.div
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -5 }}
                  >
                    <Button
                      type={'primary'}
                      disabled={isPinging}
                      onClick={handleRetry}
                      style={{ marginTop: '16px' }}
                    >
                      Retry
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </Center>
          </InitializingPage>
        )}
        {initialized && (
          <>{isValidElement(children) ? React.cloneElement(children, { key: 'app' }) : children}</>
        )}
      </AnimatePresence>
    </ConfigProvider>
  );
};

export default AppProvider;
