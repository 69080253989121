import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
  ensureMessagesLoaded,
  restoreAppTranslationMessages,
  restoreLoginTranslationMessages,
  restoreUserLocale
} from 'i18n/i18nSlice';
import { loadUserInfo, logout, restoreAuth, selectIsAuthenticated } from 'auth/authSlice';
import api from 'api/api.ts';
import { isAxiosError } from 'axios';
import { isApolloError } from '@apollo/client';
import checkAccessToken from 'auth/checkAccessToken.ts';
import refreshToken from 'auth/refreshToken.ts';

export const setWsConnected = createAction<boolean>('app/wsConnected');

export const initApp = createAsyncThunk('app/init', async (arg, thunkAPI) => {
  console.log('🚀 init app');
  await thunkAPI.dispatch(restoreAppData());
  thunkAPI.dispatch(connect());
});

export const restoreAppData = createAsyncThunk('app/restore', async (arg, thunkAPI) => {
  const authPromise = thunkAPI.dispatch(restoreAuth());
  const localePromise = thunkAPI.dispatch(restoreUserLocale());
  const loginTranslationsPromise = thunkAPI.dispatch(restoreLoginTranslationMessages());
  const appTranslationsPromise = thunkAPI.dispatch(restoreAppTranslationMessages());
  await Promise.all([authPromise, localePromise, loginTranslationsPromise, appTranslationsPromise]);
});

interface CheckConnectionError {
  code?: 'ERR_BAD_REQUEST' | 'ERR_NETWORK' | string;
  status?: number;
  statusText?: string;
  message?: string;
}

export const connect = createAsyncThunk<boolean, void, { rejectValue: CheckConnectionError }>(
  'app/connect',
  async (arg, thunkAPI) => {
    try {
      await api.ping(); // NOTE: If we add a retry / refresh middleware to our REST API, we must not refresh the token here
      const check = checkAccessToken();
      if (check.hasRefreshFlagCookie && !check.hasValidAccessToken) {
        try {
          await refreshToken('app-connect');
        } catch (refreshError: unknown) {
          if (isAxiosError(refreshError)) {
            if (refreshError.response?.status === 401) {
              // NOTE: When opening app in safari, authenticating,
              // and pinning to home screen, the anti-forgery token is not preserved and we get a 401
              console.warn(
                'Invalid refresh token! resetting locally stored auth, logging out user!'
              );
              await thunkAPI.dispatch(logout());
              return true; // <== returning true will eventually redirect to login page
            } else {
              console.log('🚫 refresh failed', refreshError);
              const niceRefreshError: CheckConnectionError = {
                code: 'ERR_REFRESH',
                message: `Unable to update your session: ${refreshError.message}`,
                status: refreshError.response?.status,
                statusText: refreshError.response?.statusText
              };
              return thunkAPI.rejectWithValue(niceRefreshError);
            }
          } else {
            let msg = 'Cannot update your session';
            if (refreshError instanceof Error) {
              msg = `${refreshError.name}: ${refreshError.message}`;
            }
            return thunkAPI.rejectWithValue({
              code: 'ERR_REFRESH_UNKNOWN',
              message: msg
            });
          }
        }
      }

      const isAuthenticated = selectIsAuthenticated(thunkAPI.getState() as RootState);

      if (isAuthenticated) {
        await thunkAPI.dispatch(loadUserInfo());
        const result = await thunkAPI.dispatch(ensureMessagesLoaded());
        if (!result) throw new Error('Could not load translations');
      }
      return true;
    } catch (error) {
      console.log('🚫 connection failed', error);

      if (isAxiosError(error)) {
        const pingError: CheckConnectionError = {
          code: error.code,
          message: error.response?.data || error.message,
          status: error.response?.status,
          statusText: error.response?.statusText
        };
        return thunkAPI.rejectWithValue(pingError);
      }

      if (error instanceof Error && isApolloError(error)) {
        const apolloError: CheckConnectionError = {
          code: 'ERR_LOAD_USER',
          message: error.message
        };
        return thunkAPI.rejectWithValue(apolloError);
      }

      const defaultConnectionError: CheckConnectionError = {
        code: 'ERR_UNEXPECTED',
        message: 'Unexpected error'
      };
      return thunkAPI.rejectWithValue(defaultConnectionError);
    }
  }
);

export interface AppState {
  isAppDataRestored: boolean; // true if app data is restored from local storage

  // these are used when loading the app to check if the server is available
  // (it will only ping once when loading the app, we're not monitoring the server here)
  serverAvailable: boolean;
  serverPinging: boolean;
  serverError?: CheckConnectionError;

  online?: boolean; // window.navigator.onLine
  wsLinkConnected: boolean; // true if the websocket link is connected
}

const defaultState: AppState = {
  isAppDataRestored: false,
  serverAvailable: false,
  serverPinging: false,
  wsLinkConnected: false
};

const appSlice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    setOnlineStatus: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreAppData.pending, (state) => {
        state.isAppDataRestored = false;
      })
      .addCase(restoreAppData.fulfilled, (state) => {
        state.isAppDataRestored = true;
      })
      .addCase(restoreAppData.rejected, (state) => {
        state.isAppDataRestored = true;
      })
      .addCase(connect.pending, (state) => {
        state.serverPinging = true;
      })
      .addCase(connect.fulfilled, (state) => {
        state.serverAvailable = true;
        state.serverError = undefined;
        state.serverPinging = false;
      })
      .addCase(connect.rejected, (state, action) => {
        state.serverAvailable = false;
        state.serverError = action.payload;
        state.serverPinging = false;
      })
      .addCase(setWsConnected, (state, action) => {
        state.wsLinkConnected = action.payload;
      });
  }
});

export const appSliceReducer = appSlice.reducer;
export const selectIsAppDataRestored = (state: RootState) => state.app.isAppDataRestored;
export const selectIsServerAvailable = (state: RootState) => state.app.serverAvailable;
export const selectIsServerPinging = (state: RootState) => state.app.serverPinging;
export const selectServerError = (state: RootState) => state.app.serverError;
export const selectIsOnline = (state: RootState) => state.app.online;
export const selectIsWsConnected = (state: RootState) => state.app.wsLinkConnected;

export const { setOnlineStatus } = appSlice.actions;
