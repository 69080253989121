import React from 'react';
import styled from 'styled-components';
import Link from 'components/lib/Link/Link';

interface Props {
  icon: React.ReactNode;
  title: string;
  isSelected?: boolean;
  url: string;
}

const ButtonContainer = styled(Link)`
  min-width: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  cursor: pointer;
`;

const Content = styled.div<{ $isSelected: boolean }>`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.ant.colorWhite};

  && {
    .anticon {
      font-size: 17px;
    }
  }
`;

const Bar = styled.div<{ $isSelected: boolean }>`
  background-color: ${(props) => (props.$isSelected ? props.theme.ant.colorWhite : 'transparent')};
  flex: 3px 0 0;
  position: relative;
  top: -1px;
`;

const Title = styled.div`
  font-size: 10px;
  margin-top: 2px;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;
`;

const NavLinkMobile: React.FC<Props> = (props) => {
  const { isSelected, title, icon, url } = props;

  return (
    <ButtonContainer to={url}>
      <Bar $isSelected={isSelected || false} />
      <Content $isSelected={isSelected || false}>
        {icon}
        <Title>{title}</Title>
      </Content>
    </ButtonContainer>
  );
};

export default NavLinkMobile;
