import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import SearchBar from 'layouts/components/MachineNav/SearchBar';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import RetailerSearchResult from 'layouts/components/MachineNav/RetailerSearchResult';
import useIsMobile from 'layouts/responsive/useIsMobile';
import { useAppDispatch } from 'redux/store.ts';
import { expandRetailer } from 'redux/machineNavSlice.ts';

const Container = styled.aside<{ $isMobile: boolean }>`
  ${(props) => {
    switch (props.$isMobile) {
      case true:
        return css`
          position: relative;
          width: 100%;
        `;
      default:
        return css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 64px;
          width: 256px;
        `;
    }
  }}

  background-color: ${(props) => props.theme.ant.colorBgContainer};

  overflow: hidden; // prevent scroll

  border-inline-end: ${(props) => props.theme.ant.lineWidth}px solid
    ${(props) => props.theme.ant.colorSplit};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media only print {
    display: none;
  }
`;

const SearchContainer = styled.div`
  flex: 0 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
`;

const SearchResultContainer = styled.div`
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
`;

const MachineNav: React.FC = () => {
  const { retailerId } = useMatrixNav();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // This will expand the selected retailer (or machine's retailer) on page load
    if (retailerId) {
      dispatch(expandRetailer({ retailerId }));
    }
  }, [dispatch, retailerId]);

  return (
    <Container $isMobile={isMobile}>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <SearchResultContainer>
        <RetailerSearchResult />
      </SearchResultContainer>
    </Container>
  );
};

export default MachineNav;
