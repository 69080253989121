import React, { CSSProperties, useCallback, useState } from 'react';
import { Col, Form, Input, Row, Switch } from 'antd';
import EventLevelFilterSelect from 'components/events/EventList/EventListFilter/EventLevelFilterSelect.tsx';
import DatePicker from 'components/lib/DatePicker/DatePicker.tsx';
import useEventListFilter from 'components/events/EventList/EventListFilter/useEventListFilter.ts';
import { parseISO } from 'date-fns';
import { DisconnectOutlined, SearchOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import DateRangePicker from 'components/lib/DateRangePicker/DateRangePicker.tsx';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import styled from 'styled-components';
import { useAppSelector } from 'redux/store.ts';
import { selectIsWsConnected } from 'redux/appSlice.ts';

const StyledForm = styled(Form)`
  &&& {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  live: boolean;
  onChangeLive: (live: boolean) => void;
  style?: CSSProperties;
}

const EventListFilter: React.FC<Props> = ({ style, live, onChangeLive }) => {
  const { eventFilter, setEventFilter } = useEventListFilter();
  const [searchTermLocal, setSearchTermLocal] = useState(eventFilter.search || '');

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    setEventFilter({ search: value });
  }, 400);

  const handleSearch = useCallback(
    (term: string) => {
      setSearchTermLocal(term);
      handleSearchDebounced(term);
    },
    [handleSearchDebounced]
  );

  const isMobile = useIsMobile();
  const isWsConnected = useAppSelector(selectIsWsConnected);

  // mute=true => only muted events
  // mute=false => only non muted events
  // mute=undefined => all events
  const includeMuted = eventFilter.muted === undefined;

  return (
    <StyledForm layout={'vertical'} labelCol={{ xs: 4, lg: 24 }} wrapperCol={{ xs: 20, lg: 24 }}>
      <Row gutter={[16, 8]} wrap={true} style={style}>
        <Col xs={24} lg={4}>
          <Form.Item label={'Level'} tooltip={'Minimum event level'}>
            <EventLevelFilterSelect />
          </Form.Item>
        </Col>

        {!isMobile && (
          <Col xs={24} lg={7}>
            <Form.Item label={'Timestamp'}>
              <DateRangePicker
                value={{
                  start: eventFilter.createdFrom ? parseISO(eventFilter.createdFrom) : null,
                  end: eventFilter.createdTo ? parseISO(eventFilter.createdTo) : null
                }}
                onChange={(range) =>
                  setEventFilter({
                    createdFrom: range.start ? range.start.toISOString() : undefined,
                    createdTo: range.end ? range.end.toISOString() : undefined
                  })
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        )}

        {isMobile && (
          <>
            <Col xs={24}>
              <Form.Item label={'From'}>
                <DatePicker
                  value={eventFilter.createdFrom ? parseISO(eventFilter.createdFrom) : undefined}
                  onChange={(date) =>
                    setEventFilter({ createdFrom: date ? date.toISOString() : undefined })
                  }
                  placeholder={'From'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={'To'}>
                <DatePicker
                  value={eventFilter.createdTo ? parseISO(eventFilter.createdTo) : undefined}
                  onChange={(date) =>
                    setEventFilter({ createdTo: date ? date.toISOString() : undefined })
                  }
                  placeholder={'To'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col xs={24} lg={8}>
          <Form.Item label={'Filter'} tooltip={'Filter by sessionId, event type id or event text'}>
            <Input
              placeholder={'Filter...'}
              size={'middle'}
              prefix={<SearchOutlined />}
              value={searchTermLocal}
              allowClear={true}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col xs={12} lg={3}>
          <Form.Item label={'Muted'} tooltip={'Include muted events in the list'}>
            <Switch
              value={includeMuted}
              onChange={(checked) => {
                if (checked) {
                  setEventFilter({ muted: undefined });
                } else {
                  setEventFilter({ muted: false });
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={12} lg={2}>
          <Form.Item label={'Live'}>
            <Switch
              value={live}
              onChange={onChangeLive}
              checkedChildren={isWsConnected ? <ThunderboltOutlined /> : <DisconnectOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default EventListFilter;
