import { MachineListItemFragment } from 'generated/types';
import React, { CSSProperties } from 'react';
import getMachineIcon from 'components/icons/getMachineIcon';
import Link from 'components/lib/Link/Link';
import styled, { css } from 'styled-components';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { defineMessages } from 'react-intl';

const WarningExclamationCircleFilled = styled(ExclamationCircleFilled)`
  color: ${(props) => props.theme.ant.colorWarning};
`;

const messages = defineMessages({
  differentSerialNo: {
    id: 'machine_link.different_serial_no',
    defaultMessage: 'The {entityName} is from a different physical machine, {entitySerialNo}'
  },
  differentMachineInstance: {
    id: 'machine_link.different_machine_instance',
    defaultMessage:
      'This {entityName} is from a different machine entity, but the same physical machine ({serialNo})'
  }
});

const AlertDifferentSerialNoTooltip: React.FC<{
  entitySerialNo: string;
  machineSerialNo: string;
  entityName: string;
  style?: CSSProperties;
}> = ({ entitySerialNo, machineSerialNo, entityName, style }) => {
  const intl = useConnectIntl();
  return (
    <Tooltip
      title={intl.formatMsg(messages.differentSerialNo, {
        entitySerialNo: entitySerialNo,
        machineSerialNo,
        entityName: entityName
      })}
    >
      <WarningExclamationCircleFilled style={style} />
    </Tooltip>
  );
};

export const AlertDifferentMachineInstanceTooltip: React.FC<{
  serialNo: string;
  entityName: string;
}> = ({ serialNo, entityName }) => {
  const intl = useConnectIntl();
  return (
    <Tooltip title={intl.formatMsg(messages.differentMachineInstance, { serialNo, entityName })}>
      <WarningExclamationCircleFilled />
    </Tooltip>
  );
};

const Part = styled.span<{ $warn?: boolean }>`
  ${(props) =>
    props.$warn &&
    css`
      color: ${(props) => props.theme.ant.colorWarning};
    `}
`;

const StyledLink = styled(Link)<{ $noWrap: boolean; $warn?: boolean; $muted?: boolean }>`
  > ${Part}:not(:last-child) {
    padding-right: 6px;
  }

  ${(props) =>
    props.$noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${(props) =>
    props.$warn &&
    css`
      color: ${(props) => props.theme.ant.colorWarning};
    `}
  
  ${(props) =>
    props.$muted &&
    css`
      color: ${(props) => props.theme.ant.colorTextSecondary};
    `}
`;

interface Props {
  machine: Pick<MachineListItemFragment, 'machineType' | 'machineId' | 'serialNo' | 'location'>;
  allowWrap?: boolean;
  appendSerialNumber?: boolean;
  urlFormatter?: (url: string) => string;
  muted?: boolean;

  // If specified, will show a warning if the serialNo of the linked machine is different from the entitySerialNo
  // The warning is rendered as a yellow color on the machine icon + some tooltip stuff
  itemDiffSerialNoCheck?: {
    entityName: string;
    entitySerialNo?: string;
    scopeSerialNo?: string;
    scopeMachineId?: number;
  };
}

const MachineLink: React.FC<Props> = (props) => {
  const { machine, itemDiffSerialNoCheck, allowWrap, appendSerialNumber, urlFormatter, muted } =
    props;
  const icon = getMachineIcon(machine.machineType);
  const { getUrlToMachine } = useMatrixNav();
  const urlToMachineOriginal = getUrlToMachine(machine.machineId);
  const urlToMachine = urlFormatter ? urlFormatter(urlToMachineOriginal) : urlToMachineOriginal;

  const differentSerialNo =
    (itemDiffSerialNoCheck &&
      itemDiffSerialNoCheck.entitySerialNo &&
      itemDiffSerialNoCheck.scopeSerialNo &&
      itemDiffSerialNoCheck.entitySerialNo !== itemDiffSerialNoCheck.scopeSerialNo) ||
    false;

  const differentMachineId =
    (itemDiffSerialNoCheck &&
      itemDiffSerialNoCheck.scopeMachineId &&
      itemDiffSerialNoCheck.scopeMachineId !== machine.machineId) ||
    false;

  return (
    <StyledLink to={urlToMachine} $noWrap={!allowWrap} $muted={muted}>
      <Part $warn={differentSerialNo}>{icon}</Part>

      {machine.location && <Part $warn={differentMachineId}>{machine.location}</Part>}

      {!machine.location && (
        <Part $warn={differentMachineId || differentSerialNo}>
          {itemDiffSerialNoCheck?.entitySerialNo || machine.serialNo}
        </Part>
      )}

      {appendSerialNumber && machine.location && (
        <>
          <Part $warn={differentSerialNo}>
            ({itemDiffSerialNoCheck?.entitySerialNo || machine.serialNo})
          </Part>
        </>
      )}

      {differentSerialNo && itemDiffSerialNoCheck?.entitySerialNo && (
        <>
          {' '}
          <AlertDifferentSerialNoTooltip
            entitySerialNo={itemDiffSerialNoCheck?.entitySerialNo}
            machineSerialNo={machine.serialNo}
            entityName={itemDiffSerialNoCheck.entityName}
          />
        </>
      )}

      {differentMachineId && itemDiffSerialNoCheck && (
        <>
          {' '}
          <AlertDifferentMachineInstanceTooltip
            serialNo={machine.serialNo}
            entityName={itemDiffSerialNoCheck.entityName}
          />
        </>
      )}
    </StyledLink>
  );
};

export default MachineLink;
