import React, { useMemo } from 'react';
import { GlobalToken } from 'antd/es/theme/interface';
import connectAppLayout, { Layout } from 'themes/layout.ts';
import { ThemeProvider } from 'styled-components';
import { theme } from 'antd';
import { useConnectAppTheme } from 'themes/useConnectAppTheme';
import { Theme } from 'generated/types';
import { Colors } from 'themes/colors.ts';

const { useToken } = theme;

export interface StyledComponentsTheme {
  ant: GlobalToken;
  connect: {
    layout: Layout;
    colors: Colors;
  };
  isDark: boolean;
}

const StyledComponentsThemeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { token } = useToken(); // This is the complete token after theme algorithm is run
  const { connectAppTheme } = useConnectAppTheme();
  const { colorError, colorBorder } = token;

  const connectAppColors: Colors = useMemo(
    () => ({
      colorBgSeverityA: '#6E0E0A',
      colorBgSeverityB: colorError,
      colorBgSeverityC: token.colorWarning,
      colorBgSeverityD: '#F1D302',
      tableFooterBackground: colorBorder,
      colorBgItemTrace: token.colorBgContainer,
      colorBgItemWarning: '#fff3d6',
      colorBgItemError: '#f8d6d4',
      colorBgItemSuccess: token.colorSuccessBg,
      colorBgItemInfo: token.colorBgContainer, // NOTE: If Ralf wants info color, maybe '#f7fafc'
      colorBgItemMuted: token.colorBgContainerDisabled
    }),
    [
      colorError,
      token.colorWarning,
      token.colorBgContainer,
      token.colorSuccessBg,
      token.colorBgContainerDisabled,
      colorBorder
    ]
  );

  const connectAppColorsDark: Colors = useMemo(
    () => ({
      ...connectAppColors,
      colorBgItemTrace: token.colorBgContainer,
      colorBgItemWarning: '#5c4719',
      colorBgItemError: '#5a1e19',
      colorBgItemSuccess: token.colorSuccessBg,
      colorBgItemInfo: token.colorBgContainer,
      // colorBgItemInfo: '#124b54',
      colorBgItemMuted: token.colorBgContainerDisabled
    }),
    [connectAppColors, token.colorBgContainer, token.colorBgContainerDisabled, token.colorSuccessBg]
  );

  const scTheme: StyledComponentsTheme = useMemo(
    () => ({
      ant: token,
      isDark: connectAppTheme === Theme.Dark,
      connect: {
        layout: connectAppLayout,
        colors: connectAppTheme === Theme.Dark ? connectAppColorsDark : connectAppColors
      }
    }),
    [token, connectAppTheme, connectAppColorsDark, connectAppColors]
  );

  return <ThemeProvider theme={scTheme}>{children}</ThemeProvider>;
};

export default StyledComponentsThemeProvider;
