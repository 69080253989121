import classNames from 'classnames';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Tab } from 'rc-tabs/es/interface';
import TabContext from 'rc-tabs/es/TabContext';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import { v4 } from 'uuid';
import { TabsProps as RcTabsProps } from 'rc-tabs';
import TabNavList from 'rc-tabs/es/TabNavList';
import useStyle from 'antd/es/tabs/style';
import useCSSVarCls from 'antd/es/config-provider/hooks/useCSSVarCls';
import { ConfigContext } from 'antd/es/config-provider';
import useSize from 'antd/es/config-provider/hooks/useSize';
import { TabsType } from 'antd/es/tabs';
import styled from 'styled-components';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import { useScrollContext } from 'layouts/MainLayout/ScrollContext.tsx';

export interface CustomTab extends Tab {
  disabled?: boolean;
}

type RcTabsExposedProps = Pick<
  RcTabsProps,
  | 'className'
  | 'activeKey'
  | 'defaultActiveKey'
  | 'destroyInactiveTabPane'
  | 'onChange'
  | 'onTabClick'
  | 'onTabScroll'
>;

interface CustomTabsProps {
  items: CustomTab[];
  style?: CSSProperties;

  // If set to true, this will set the scroll context to the content div AND add scrollable css styles?
  setScrollContext?: boolean;
}

export interface TabsRef {
  outerRef: HTMLDivElement | null;
  contentRefs: Record<string, HTMLDivElement | null>;
}

export type ConnectTabsProps = RcTabsExposedProps & CustomTabsProps;

const TabPane = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  flex-direction: column;
`;

/****
 * Custom Tabs component
 * Based on these two components:
 * RcTabs: https://github.com/react-component/tabs/blob/master/src/Tabs.tsx
 * AntTabs: https://github.com/ant-design/ant-design/blob/master/components/tabs/index.tsx
 *
 * Added support for disabled tabs, currently hidden
 * Added ref to content div to support infinite scroll!
 * ****/
const Tabs = React.forwardRef<TabsRef, ConnectTabsProps>((props, forwardedRef) => {
  const isMobile = useIsMobile();
  const { setScrollRef } = useScrollContext();

  const {
    className,
    items,
    activeKey,
    defaultActiveKey,
    destroyInactiveTabPane,
    onChange,
    onTabClick,
    onTabScroll,
    style,
    setScrollContext,
    ...restProps
  } = props;

  // Get Ant Design style stuff
  const { tabs: tabsConfig, getPrefixCls, getPopupContainer } = React.useContext(ConfigContext);
  const customizePrefixCls = 'connect-tabs';
  const prefixCls = getPrefixCls('tabs', customizePrefixCls);
  const rootCls = useCSSVarCls(prefixCls);
  const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);

  const tabPosition = 'top';
  const id = useMemo(() => v4(), []);

  // We track "dirty" to only animate the selected tab bar ink when navigating, not on first render
  const [dirty, setDirty] = useState(false);

  const outerRef = useRef<HTMLDivElement>(null);
  const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useImperativeHandle(forwardedRef, () => ({
    outerRef: outerRef.current,
    contentRefs: contentRefs.current,
  }));

  const tabs = React.useMemo<CustomTab[]>(
    () =>
      (items || [])
        .filter((item) => item && typeof item === 'object' && 'key' in item)
        .filter((item) => item.disabled !== true),
    [items],
  );
  const tabsHash = useMemo(() => tabs.map((tab) => tab.key).join('_'), [tabs]);

  // Interesting way to implement the "controllable" pattern!
  const [mergedActiveKey, setMergedActiveKey] = useMergedState<string>(() => tabs[0]?.key, {
    value: activeKey,
    defaultValue: defaultActiveKey,
  });

  const [activeIndex, setActiveIndex] = useState(() =>
    tabs.findIndex((tab) => tab.key === mergedActiveKey),
  );

  // Reset active key if it doesn't exist anymore
  useEffect(() => {
    let newActiveIndex = tabs.findIndex((tab) => tab.key === mergedActiveKey);
    if (newActiveIndex === -1) {
      newActiveIndex = Math.max(0, Math.min(activeIndex, tabs.length - 1));
      const forcedNextTabKey = defaultActiveKey || tabs[newActiveIndex]?.key;
      console.log(`tab ${mergedActiveKey} is not valid anymore, switching to ${forcedNextTabKey}`);
      onChange?.(forcedNextTabKey);
    }
    setActiveIndex(newActiveIndex);
  }, [
    tabsHash,
    mergedActiveKey,
    activeIndex,
    tabs,
    setMergedActiveKey,
    defaultActiveKey,
    onChange,
  ]);

  // useEffect(() => {
  //   console.log('Tabs key', {
  //     activeKey: activeKey,
  //     defaultActiveKey: defaultActiveKey,
  //     mergedActiveKey: mergedActiveKey,
  //     activeIndex: activeIndex,
  //   });
  // }, [activeIndex, activeKey, defaultActiveKey, mergedActiveKey]);

  const visitedTabKeysRef = useRef<Set<string>>(new Set([mergedActiveKey]));

  const addVisitedTabKey = useCallback((key: string) => {
    visitedTabKeysRef.current.add(key);
  }, []);

  const handleTabClick = useCallback(
    (key: string, e: React.MouseEvent | React.KeyboardEvent) => {
      addVisitedTabKey(key);
      setDirty(true);
      setMergedActiveKey(key);
      onTabClick?.(key, e);
      const isActiveChanged = key !== mergedActiveKey;
      if (isActiveChanged) {
        onChange?.(key);
      }
    },
    [addVisitedTabKey, mergedActiveKey, onChange, onTabClick, setMergedActiveKey],
  );

  // Whenever the active tab changes, we set the scroll ref. (also on initial set)
  useEffect(() => {
    if (setScrollContext && !isMobile) {
      setScrollRef(contentRefs.current[mergedActiveKey], mergedActiveKey);
    }
  }, [isMobile, mergedActiveKey, setScrollContext, setScrollRef]);

  const mergedStyle: React.CSSProperties = { ...tabsConfig?.style, ...style };
  const size = useSize();
  const centered = false;
  const type: TabsType = 'line'; // hard-coded for now

  const classNameAntStyleTabs = classNames(
    {
      [`${prefixCls}-${size}`]: size,
      [`${prefixCls}-card`]: ['card', 'editable-card'].includes(type!),
      [`${prefixCls}-editable-card`]: false, // type === 'editable-card',
      [`${prefixCls}-centered`]: centered,
    },
    tabsConfig?.className,
    className,
    hashId,
    cssVarCls,
    rootCls,
  );

  return (
    <TabContext.Provider value={{ tabs, prefixCls }}>
      {wrapCSSVar(
        <div
          ref={outerRef}
          className={classNames(prefixCls, `${prefixCls}-${tabPosition}`, classNameAntStyleTabs)}
          style={mergedStyle}
          {...restProps}
        >
          <TabNavList
            id={id}
            activeKey={mergedActiveKey}
            tabPosition={tabPosition}
            rtl={false}
            mobile={isMobile}
            onTabClick={handleTabClick}
            onTabScroll={onTabScroll}
            getPopupContainer={getPopupContainer}
            animated={{ inkBar: dirty, tabPane: false }}
          />
          {tabs.map((tab) => {
            const isTabActive = activeKey === tab.key;

            // NOTE: We only include the tab children if it's active, or it has been visited
            // Fast initial load, and fast when navigating back to a previously selected tab

            return (
              <TabPane
                ref={(el) => (contentRefs.current[tab.key] = el)}
                $visible={isTabActive}
                key={tab.key}
                id={`${id}-panel-${tab.key}`}
                role={'tabpanel'}
                tabIndex={isTabActive ? 0 : -1}
                aria-labelledby={id && `${id}-tab-${tab.key}`}
                aria-hidden={!isTabActive}
                className={classNames(`${prefixCls}-tabpane`, {
                  [`${prefixCls}-tabpane-active`]: isTabActive,
                  [`${prefixCls}-tabpane-active-scroll`]: isTabActive && setScrollContext,
                })}
                style={tab.style}
              >
                {(isTabActive || visitedTabKeysRef.current.has(tab.key)) && tab.children}
              </TabPane>
            );
          })}
        </div>,
      )}
    </TabContext.Provider>
  );
});

Tabs.displayName = 'Tabs';

export default Tabs;
