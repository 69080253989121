import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import React from 'react';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import { pagePadding } from 'components/lib/page/pagePadding.ts';

const PageTopDiv = styled.div<{ $breakpoint: ScreenMap }>`
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  ${pagePadding};

  padding-top: ${(props) => (props.$breakpoint.md === false ? '8px' : '16px')};
  padding-bottom: ${(props) => (props.$breakpoint.md === false ? '8px' : '16px')};
`;

export interface PageHeaderRowProps {
  className?: string;
  children?: React.ReactNode;
}

const PageHeaderRow: React.FC<PageHeaderRowProps> = (props) => {
  const { screenMap: breakpoint } = useScreenInfo();

  return (
    <PageTopDiv $breakpoint={breakpoint} className={props.className}>
      {props.children}
    </PageTopDiv>
  );
};

export default PageHeaderRow;
