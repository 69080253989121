import React, { useEffect } from 'react';
import styled from 'styled-components';
import { VendanorButterflySquare } from 'layouts/components/icons/VendanorButterfly.tsx';
import { Typography } from 'antd';
import SearchButtonMobile from 'layouts/MainLayout/SearchButtonMobile/SearchButtonMobile.tsx';
import { useTitleContext } from 'layouts/MainLayout/TitleContext.tsx';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { useScrollContext } from 'layouts/MainLayout/ScrollContext.tsx';

const Container = styled.header`
  height: ${(props) => props.theme.connect.layout.headerMobileHeight};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: grid;
  grid-template-columns: 64px 1fr 64px;
  align-items: center;
  background: ${(props) => props.theme.ant.colorBgContainer};
  overflow: hidden;
  overscroll-behavior: contain;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
`;

const TitleBox = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  && {
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 0;
      overflow: hidden;
    }
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderMobile: React.FC = () => {
  const { title } = useTitleContext();
  const { pageTitle } = title;
  const { scrollRef } = useScrollContext();
  const scrollY = useMotionValue(0);
  const el = scrollRef?.node || undefined;

  useEffect(() => {
    if (el) {
      const handleScroll = () => {
        scrollY.set(el.scrollTop);
      };

      el.addEventListener('scroll', handleScroll);
      return () => el.removeEventListener('scroll', handleScroll);
    }
  }, [el, scrollY]);

  const opacity = useSpring(useTransform(scrollY, [75, 110], [0, 1]), {
    stiffness: 300,
    damping: 30
  });

  return (
    <Container>
      <IconBox>
        <VendanorButterflySquare />
      </IconBox>
      <TitleBox initial={{ opacity: 0 }} style={{ opacity }}>
        <Typography.Title level={4} ellipsis={true}>
          {pageTitle}
        </Typography.Title>
      </TitleBox>
      <ButtonBox>
        <SearchButtonMobile />
      </ButtonBox>
    </Container>
  );
};

export default HeaderMobile;
