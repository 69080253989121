import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import React, { CSSProperties } from 'react';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import { pagePadding } from 'components/lib/page/pagePadding.ts';

const PageContentDiv = styled.div<{ $breakpoint: ScreenMap }>`
  ${pagePadding};
  flex: 1 1 auto; // min 100% height
`;

interface Props {
  style?: CSSProperties;
  children?: React.ReactNode;
}

const PageContentRow: React.FC<Props> = (props) => {
  const { children, style } = props;
  const { screenMap: breakpoint } = useScreenInfo();

  return (
    <PageContentDiv $breakpoint={breakpoint} style={style}>
      {children}
    </PageContentDiv>
  );
};

export default PageContentRow;
