import React from 'react';
import styled from 'styled-components';

interface Props {
  icon?: React.ReactNode;
  title: string;
}

const ItemTitle = styled.h4`
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: center;

  && {
    .anticon {
      margin-right: 6px;
    }
  }
`;

const ListItemTitle: React.FC<Props> = ({ icon, title }) => {
  return (
    <ItemTitle>
      {icon}
      {title}
    </ItemTitle>
  );
};

export default ListItemTitle;
