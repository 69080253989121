import { EventFilterInput, EventLevel } from 'generated/types.tsx';
import useQueryParam from 'hooks/useQueryParam.ts';

type EventListQueryFilter = Omit<EventFilterInput, 'retailerId' | 'machine'>;

const defaultFilter: EventListQueryFilter = {
  eventLevel: EventLevel.Info,
  muted: false
};

function useEventListFilter() {
  const [filterParams, setFilterParams] = useQueryParam<EventListQueryFilter>('evf');
  const eventFilter = filterParams || defaultFilter;
  const setEventFilter = (value: Partial<EventListQueryFilter>) => {
    setFilterParams({
      ...defaultFilter,
      ...filterParams,
      ...value
    });
  };

  return {
    eventFilter,
    setEventFilter
  };
}

export default useEventListFilter;
