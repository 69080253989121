import React, { useMemo } from 'react';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { Alert, Col, Divider, Flex, Row, Space, Statistic, Typography } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Tab } from 'rc-tabs/es/interface';
import { gql } from '@apollo/client/core';
import {
  ModelListItemFragment,
  MultiClassListItemFragment,
  PredictionItemFullFragmentDoc,
  useGetPredictionPageQuery
} from 'generated/types.tsx';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import PredictionSampleImage from 'components/prediction/PredictionSampleImage.tsx';
import getObjectModelColor from 'components/prediction/getObjectModelColor.ts';
import Tag from 'components/lib/Tag/Tag.tsx';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';
import { BlockOutlined, TagsOutlined } from '@ant-design/icons';
import ClassificationPercentage from 'components/prediction/ClassificationPercentage.tsx';
import predictionMessages from 'components/prediction/predictionMessages.ts';
import PredictionResult from 'components/prediction/PredictionResult.tsx';
import usePredictionResultMessage from 'components/prediction/usePredictionResultMessage.ts';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import PageTab from 'components/lib/page/PageTabs/PageTab.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

gql`
  query GetPredictionPage($predictionId: UUID!) {
    prediction(id: $predictionId) {
      ...PredictionItemFull
    }
  }
  ${PredictionItemFullFragmentDoc}
`;

const PredictionPage: React.FC = () => {
  const intl = useConnectIntl();
  const { formatDate } = useDateFormatTools();
  const { predictionId: predictionIdRaw } = useParams();
  const { resolving } = useMatrixNav();

  const { data, loading, error } = useGetPredictionPageQuery({
    skip: resolving || !predictionIdRaw,
    variables: predictionIdRaw
      ? {
          predictionId: predictionIdRaw
        }
      : undefined,
    fetchPolicy: 'cache-first'
  });

  const groupedMultiClassList = useMemo(() => {
    const result: Record<
      string,
      { model: ModelListItemFragment; list: MultiClassListItemFragment[] }
    > = {};
    data?.prediction.multiClassList.forEach((c) => {
      const key = c.model.modelTypeId;
      if (!result[key]) {
        result[key] = {
          model: c.model,
          list: []
        };
      }
      if (c.score > 0.01) {
        result[key].list.push(c);
      }
    });
    return result;
  }, [data]);

  const resultMessageFull = usePredictionResultMessage(data?.prediction, 'full');
  const resultMessageId = usePredictionResultMessage(data?.prediction, 'id');

  const hasZeroClassifications =
    data &&
    data.prediction.objectClassList.length === 0 &&
    data.prediction.multiClassList.length === 0;

  const machineTitle =
    data?.prediction.machine.location || data?.prediction.machine.serialNo || undefined;
  const pageTitle = resultMessageFull || intl.formatMsg(predictionMessages.pageTitle);

  const tabs: Tab[] = [
    {
      key: 'prediction',
      active: true,
      label: intl.formatMsg(predictionMessages.tabPrediction),
      children: (
        <PageTab style={{ paddingBottom: 64 }}>
          {loading && !data && (
            <Row justify={'center'}>
              <Col span={4}>
                <Spinner />
              </Col>
            </Row>
          )}
          {error && <Alert message={error.message} type={'error'} />}

          {data && (
            <Row gutter={[16, 16]} align={'stretch'}>
              <Col xs={24}>
                <ResponsiveListCard>
                  <Row gutter={[16, 16]} wrap={true}>
                    <Col xs={12} xl={6}>
                      <SampleImageContainer>
                        <PredictionSampleImage
                          image={{
                            src: data.prediction.sample.dataUrl,
                            alt: `Sample image ${data.prediction.sample.id}`,
                            width: data.prediction.sample.width,
                            height: data.prediction.sample.height
                          }}
                          detectedObjects={data.prediction.objectClassList.map((c) => ({
                            naturalX: c.x,
                            naturalY: c.y,
                            naturalWidth: c.width,
                            naturalHeight: c.height,
                            color: getObjectModelColor(c.label)
                          }))}
                          enableAnimation={true}
                        />
                      </SampleImageContainer>
                    </Col>
                    <Col xs={12} xl={6} style={{ transition: 'height 0.3s ease-in-out' }}>
                      <PredictionResultContainer>
                        <PredictionResult prediction={data.prediction} size={'normal'} />
                      </PredictionResultContainer>
                    </Col>
                    <InfoCol xs={24} xl={12}>
                      <InfoContainer>
                        <Flex gap={'large'}>
                          <Statistic
                            style={{ flex: '0 0 50%' }}
                            title={intl.formatMsg(predictionMessages.elapsedTimeTitle)}
                            value={intl.formatMsg(predictionMessages.elapsedTimeValue, {
                              ms: data.prediction.elapsedMilliseconds
                            })}
                          />
                          <Statistic
                            title={intl.formatMsg(predictionMessages.predictorVersionTitle)}
                            value={data.prediction.predictorVersion}
                          />
                        </Flex>
                        <Flex gap={'large'}>
                          <Statistic
                            title={intl.formatMsg(predictionMessages.timestampTitle)}
                            style={{ flex: '0 0 50%' }}
                            value={formatDate(parseISO(data.prediction.timestamp), {
                              representation: 'complete'
                            })}
                          />
                          <Statistic
                            title={'Product ID'}
                            value={resultMessageId}
                            formatter={(value) => value}
                          />
                        </Flex>

                        <Divider style={{ margin: 8 }} />

                        <Typography.Text copyable={{ text: data.prediction.sample.sampleId }}>
                          {intl.formatMsg(predictionMessages.sampleId, {
                            id: data.prediction.sample.sampleId
                          })}
                        </Typography.Text>

                        {data.prediction.sample.sessionId && (
                          <Typography.Text
                            copyable={{ text: data.prediction.sample.sessionId.toString() }}
                          >
                            {intl.formatMsg(predictionMessages.session, {
                              session: data.prediction.sample.sessionId.toString()
                            })}
                          </Typography.Text>
                        )}

                        <Typography.Text>
                          {intl.formatMsg(predictionMessages.chamber, {
                            carousel: data.prediction.sample.carousel,
                            row: data.prediction.sample.row,
                            sector: data.prediction.sample.sector
                          })}
                        </Typography.Text>
                        <Typography.Text>
                          {intl.formatMsg(predictionMessages.run, {
                            run: data.prediction.sample.run
                          })}
                        </Typography.Text>

                        {hasZeroClassifications && (
                          <>
                            <Divider style={{ margin: 8 }} />
                            <Flex>
                              <Alert
                                message={intl.formatMsg(
                                  predictionMessages.noClassificationsWarning
                                )}
                                type={'warning'}
                              />
                            </Flex>
                          </>
                        )}
                      </InfoContainer>
                    </InfoCol>
                  </Row>
                </ResponsiveListCard>
              </Col>

              {data.prediction.objectClassList.length > 0 && (
                <Col xs={24} xl={12} xxl={data.prediction.objectClassList.length > 2 ? 8 : 12}>
                  <ResponsiveListCard
                    style={{ height: '100%' }}
                    title={intl.formatMsg(predictionMessages.objectDetectionTitle)}
                  >
                    <Flex vertical={true} gap={'small'}>
                      {data.prediction.objectClassList.map((c) => {
                        const color = getObjectModelColor(c.label);

                        return (
                          <Space key={c.id} size={'small'} wrap={true}>
                            <ClassificationPercentage
                              score={c.score}
                              minScore={c.minScore}
                              showMinScore={true}
                            />
                            <Tag
                              icon={<BlockOutlined />}
                              color={color}
                            >{`${c.model.modelTypeName}: ${c.label}`}</Tag>
                          </Space>
                        );
                      })}
                    </Flex>
                  </ResponsiveListCard>
                </Col>
              )}

              {Object.keys(groupedMultiClassList)
                .sort()
                .reverse()
                .map((key) => {
                  const model = groupedMultiClassList[key].model;
                  return (
                    <Col
                      xs={24}
                      xl={12}
                      xxl={data.prediction.objectClassList.length > 2 ? 8 : 12}
                      key={key}
                    >
                      <ResponsiveListCard
                        style={{ height: '100%' }}
                        title={model.modelTypeName}
                        extra={
                          <Typography.Text type={'secondary'}>{model.description}</Typography.Text>
                        }
                      >
                        <Flex vertical={true} gap={'small'}>
                          {groupedMultiClassList[key].list
                            .sort((a, b) => a.score - b.score)
                            .reverse()
                            .map((c) => (
                              <Space key={c.id} size={'small'} wrap={true}>
                                <ClassificationPercentage
                                  score={c.score}
                                  minScore={c.minScore}
                                  showMinScore={true}
                                />
                                <Tag icon={<TagsOutlined />}>{c.label}</Tag>
                              </Space>
                            ))}
                        </Flex>
                      </ResponsiveListCard>
                    </Col>
                  );
                })}
            </Row>
          )}
        </PageTab>
      )
    }
  ];

  return (
    <Page
      title={{
        pageTitle: pageTitle,
        headTitleParts: [pageTitle, machineTitle]
      }}
      showBreadcrumbs={true}
      header={pageTitle}
      tabs={{
        activeKey: 'prediction',
        items: tabs,
        defaultActiveKey: 'prediction'
      }}
    />
  );
};

const PredictionResultContainer = styled.div`
  background: ${(props) => props.theme.ant.colorSuccessBg};
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  border-radius: 4px;
  overflow: hidden;
`;

const InfoCol = styled(Col)`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

const InfoContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

const SampleImageContainer = styled.div`
  background: ${(props) => props.theme.ant.colorSuccessBg};
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  border-radius: 4px;
  overflow: hidden;
`;

export default PredictionPage;
